<template>
    <div>
        <div class="row no-gutters mt-5 mb-3">
            <file-management
                    :id="id"
                    :group-id="groupId"
                    :hasUpdateRights="isMeetingModerator"
            ></file-management>
        </div>
        <hr>
        <div class="meeting-tabs row no-gutters mb-3">
            <div v-for="tab in tabs" @click="activeTab = tab.name" class="col-4" :class="{ active: activeTab === tab.name }">
                <span>
                    <icon :icon="tab.icon" class="mr-2"></icon>
                    {{ tab.name }}
                </span>
            </div>
        </div>
        <meeting-members
                :meeting-id="id"
                :status="attendingStatus"
                :title="activeTab"
        ></meeting-members>
    </div>
</template>

<script>
    import { getGroupMeeting } from "./api";

    export default {
        name: 'MeetingDetails',
        data() {
            return {
                tabs: [
                    {
                        name: 'Aanwezigen',
                        value: 'attending',
                        icon: 'user-check',
                    },
                    {
                        name: 'In afwachting',
                        value: 'pending',
                        icon: 'clock',
                    },
                    {
                        name: 'Afwezigen',
                        value: 'missing',
                        icon: 'user-slash',
                    },
                ],
                activeTab: 'Aanwezigen',
                meetingData: {
                    creatorId: -1,
                },
            }
        },
        props: {
            id: {
                type: Number,
                required: true,
            },
            groupId: {
                type: Number,
                required: true,
            },
            isGroupModerator: {
                type: Boolean,
                required: true,
            },
            userId: {
                type: Number,
                required: true,
            }
        },
        computed: {
            isMeetingModerator() {
                return this.isGroupModerator || this.userId == this.meetingData.creatorId;
            },
            attendingStatus() {
                const active = this.tabs.find((item) => {
                    return item.name === this.activeTab;
                });

                return active.value;
            },
        },
        created() {
            this.fetch();
        },
        methods: {
            fetch() {
                getGroupMeeting(this.id).then(({ data }) => {
                    this.meetingData.creatorId = data.creatorId;
                });
            },
            dataChange(data) {
                this.meetingData = data;
            },
        }
    }
</script>
