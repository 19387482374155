var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flash__message", class: _vm.flashStatus }, [
    _vm._v("\n        " + _vm._s(_vm.message) + "\n    "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }