var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12 form-wrapper mb-4" },
    [
      _vm.feedback
        ? _c("flashmessage", {
            attrs: { type: _vm.flashType, message: _vm.feedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-12 col-md-4" }, [
          !_vm.isSearching
            ? _c(
                "p",
                { staticClass: "mt-3 mb-0 meeting-files-breadcrumbs" },
                [
                  _vm._v(
                    "\n                    U bevindt zich hier:\n                    "
                  ),
                  _vm._l(_vm.breadcrumbs, function (breadcrumbItem, index) {
                    return _c(
                      "span",
                      {
                        staticClass: "d-inline meeting-files-breadcrumbs__item",
                      },
                      [
                        index === 0
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeFolder(breadcrumbItem.id)
                                  },
                                },
                              },
                              [_c("icon", { attrs: { icon: "home" } })],
                              1
                            )
                          : _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeFolder(breadcrumbItem.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(breadcrumbItem.name))]
                            ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            attrs: {
              type: "text",
              placeholder: "Bestand zoeken binnen deze groep",
            },
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.canCreateAssets
          ? _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-4 text-md-right justify-content-end",
              },
              [
                _vm.counter > 1
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.counter) + " bestanden aan het opladen"
                      ),
                    ])
                  : _vm.counter == 1
                  ? _c("span", [_vm._v("1 bestand aan het opladen")])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button button--xs",
                    attrs: { type: "button" },
                    on: { click: _vm.selectFile },
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "file-upload" },
                    }),
                    _vm._v(
                      "\n                    Nieuw bestand uploaden\n                "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  ref: "select",
                  staticClass: "d-none",
                  attrs: { type: "file", multiple: "" },
                  on: { change: _vm.startUpload },
                }),
                _vm._v(" "),
                _vm.canCreateFolders
                  ? _c(
                      "button",
                      {
                        staticClass: "button button--xs",
                        attrs: { type: "button" },
                        on: { click: _vm.createFolder },
                      },
                      [
                        _c("icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "folder" },
                        }),
                        _vm._v(
                          "\n                    Map aanmaken\n                "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table table-striped meeting-files" }, [
        _c("thead", [
          _c("tr", { staticClass: "w-100 meeting-files__header" }, [
            _c(
              "th",
              {
                staticClass: "col-3",
                attrs: { scope: "col" },
                on: {
                  click: function ($event) {
                    return _vm.sortFiles("name")
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Bestandsnaam\n                        "
                ),
                _c("icon", {
                  staticClass: "ml-2",
                  attrs: { icon: _vm.showIcon("name") },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticClass: "col-2",
                attrs: { scope: "col" },
                on: {
                  click: function ($event) {
                    return _vm.sortFiles("creationDate")
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Aanmaakdatum\n                        "
                ),
                _c("icon", {
                  staticClass: "ml-2",
                  attrs: { icon: _vm.showIcon("creationDate") },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticClass: "col-2",
                attrs: { scope: "col" },
                on: {
                  click: function ($event) {
                    return _vm.sortFiles("modificationDate")
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Gewijzigd op\n                        "
                ),
                _c("icon", {
                  staticClass: "ml-2",
                  attrs: { icon: _vm.showIcon("modificationDate") },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticClass: "col-2",
                attrs: { scope: "col" },
                on: {
                  click: function ($event) {
                    return _vm.sortFiles("owner")
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Eigenaar\n                        "
                ),
                _c("icon", {
                  staticClass: "ml-2",
                  attrs: { icon: _vm.showIcon("owner") },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "th",
              { staticClass: "col-3 text-center", attrs: { scope: "col" } },
              [_vm._v("Acties")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm.creatingFolder
              ? _c(
                  "tr",
                  {
                    staticClass: "meeting-files__row",
                    on: { focusout: _vm.saveNewFolder },
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "meeting-files__name--folder" },
                      [
                        _c("icon", {
                          staticClass: "folder-icon",
                          attrs: { icon: "folder" },
                        }),
                        _vm._v(" "),
                        _c("icon", {
                          staticClass: "folder-icon-open",
                          attrs: { icon: "folder" },
                        }),
                        _vm._v(" "),
                        _c(
                          "ValidationObserver",
                          {
                            ref: "createFolderValidator",
                            attrs: { tag: "div" },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: { regex: _vm.fileNameRegex },
                                tag: "div",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.newFolderName,
                                              expression: "newFolderName",
                                            },
                                          ],
                                          ref: "createFolderInput",
                                          staticClass: "rename-file w-100",
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.newFolderName,
                                          },
                                          on: {
                                            keydown: _vm.createFolderEvents,
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.newFolderName =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "form-errors" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1076868616
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.currentUser.name))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "button button--xs",
                          attrs: { type: "button" },
                          on: { click: _vm.saveNewFolder },
                        },
                        [
                          _vm._v(
                            "\n                            Opslaan\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.files.length <= 0 && _vm.search === ""
              ? _c("tr", [
                  _c("td", { attrs: { colspan: "5" } }, [
                    _vm._v("Er zijn nog geen bestanden"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.files.length <= 0 && _vm.search !== ""
              ? _c("tr", [
                  _c("td", { attrs: { colspan: "5" } }, [
                    _vm._v("Er werden geen bestanden gevonden"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.files, function (file, key) {
              return _c(
                "tr",
                {
                  key: key,
                  staticClass: "meeting-files__row",
                  on: {
                    focusout: function ($event) {
                      return _vm.rename(file)
                    },
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.rightMenuClick(file)
                    },
                  },
                },
                [
                  _c(
                    "td",
                    {
                      class: _vm.classNames(file).column_name,
                      attrs: { scope: "row" },
                      on: {
                        click: function ($event) {
                          return _vm.clickRow(file)
                        },
                      },
                    },
                    [
                      _c("icon", {
                        class: _vm.classNames(file).icon,
                        attrs: { icon: _vm.getIcon(file) },
                      }),
                      _vm._v(" "),
                      file.type === "folder"
                        ? _c("icon", {
                            staticClass: "folder-icon-open",
                            attrs: { icon: "folder-open" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      file.id === _vm.renamingId
                        ? _c(
                            "ValidationObserver",
                            {
                              ref: "renameValidator" + file.id,
                              refInFor: true,
                              attrs: { tag: "div" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  rules: { regex: _vm.fileNameRegex },
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: file.newName,
                                                expression: "file.newName",
                                              },
                                            ],
                                            staticClass: "rename-file w-100",
                                            attrs: { type: "text" },
                                            domProps: { value: file.newName },
                                            on: {
                                              keydown: function ($event) {
                                                return _vm.renameAssetEvents(
                                                  $event,
                                                  file
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  file,
                                                  "newName",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "form-errors" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _c(
                              "div",
                              [
                                _c("span", {
                                  staticClass: "search__filename pr-4",
                                  domProps: { innerHTML: _vm._s(file.name) },
                                }),
                                _vm._v(" "),
                                _vm._l(file.tags, function (tag) {
                                  return _c(
                                    "span",
                                    { staticClass: "badge badge-dark ml-2" },
                                    [_vm._v(_vm._s(tag.name))]
                                  )
                                }),
                                _vm._v(" "),
                                file.content
                                  ? _c("div", {
                                      staticClass: "search__file_content",
                                      domProps: {
                                        innerHTML: _vm._s(file.content),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isSearching
                                  ? _c(
                                      "div",
                                      { staticClass: "search__file_path" },
                                      [
                                        _vm._v("Locatie: "),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(file.path)),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(file.creationDate))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(file.modificationDate))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(file.owner))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass:
                        "meetings-files__action-cell position-relative d-flex justify-content-end",
                    },
                    [
                      file.id === _vm.renamingId
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "button button--xs",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rename(file)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Opslaan\n                            "
                                ),
                              ]
                            ),
                          ])
                        : _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "btn btn-xs mb-1",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: "Kopiëer link",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyLink(file)
                                  },
                                },
                              },
                              [_c("icon", { attrs: { icon: "paperclip" } })],
                              1
                            ),
                          ]),
                      _vm._v(" "),
                      file.id !== _vm.renamingId && file.canEdit
                        ? _c("div", [
                            file.type !== "folder"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-xs mb-1",
                                    attrs: {
                                      "data-toggle": "tooltip",
                                      "data-placement": "top",
                                      title: "Wijzig tags",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.editTags(file)
                                      },
                                    },
                                  },
                                  [_c("icon", { attrs: { icon: "tags" } })],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-xs mb-1",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: "Wijzig bestandsnaam",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.startRenaming($event, file)
                                  },
                                },
                              },
                              [_c("icon", { attrs: { icon: "edit" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-xs btn-danger mb-1",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: "Verwijder bestand",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.deletingFile = file
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  staticClass: "text-white",
                                  attrs: { icon: "trash-alt" },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.hasFiles
          ? _c(
              "span",
              {
                staticClass: "button button--neutral button--xs float-right",
                on: { click: _vm.downloadBulk },
              },
              [_vm._v("Download alle bestanden (.zip)")]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("pagination", {
        class: _vm.pagination.css,
        attrs: {
          current_page_number: _vm.pagination.current_page_number,
          total_items: _vm.pagination.total_items,
          items_per_page: _vm.pagination.items_per_page,
        },
        on: { changedPage: _vm.changedPage },
      }),
      _vm._v(" "),
      _vm.deletingFile
        ? _c("delete-asset-confirmation", {
            attrs: { groupId: _vm.groupId, file: _vm.deletingFile },
            on: {
              close: function ($event) {
                _vm.deletingFile = null
              },
              deleted: function ($event) {
                return _vm.showDeletedMessage(_vm.deletingFile)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showTags
        ? _c(
            "modal",
            [
              _c("file-tags", {
                attrs: {
                  "group-id": parseInt(_vm.groupId),
                  "file-id": parseInt(_vm.selectedFile),
                },
                on: {
                  close: function ($event) {
                    _vm.showTags = false
                  },
                  update: _vm.updateTags,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("context-menu", { ref: "ctxMenu", attrs: { id: "contextMenu" } }, [
        _c("li", [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.copyLink()
                },
              },
            },
            [
              _vm._v(
                "\n                    Kopieer link\n                    "
              ),
              _c("icon", { attrs: { icon: "paperclip" } }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-2" }, [
      _c("h2", { staticClass: "col-12 col-md-3 mb-2" }, [_vm._v("Bestanden")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }