var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mb-3" }, [
      _c("label", [_vm._v("Alle tags")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "taglist" },
        _vm._l(_vm.allGroupTags, function (tag, key) {
          return _c("group-tag", {
            key: key,
            attrs: { tag: tag, "is-editable": false, "is-removable": false },
            on: { click: _vm.addTag },
          })
        }),
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-3" }, [
      _vm.fileGroupTags.length > 0
        ? _c("label", [_vm._v("Bestandstags")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "taglist" },
        _vm._l(_vm.fileGroupTags, function (tag, key) {
          return _c("group-tag", {
            key: key,
            attrs: { tag: tag, "is-editable": false },
            on: { click: _vm.addTag, deleteTag: _vm.deleteTag },
          })
        }),
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          staticClass: "btn btn-xs btn-default mr-1",
          on: { click: _vm.confirm },
        },
        [_vm._v("Opslaan")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-xs button--neutral",
          on: { click: _vm.cancel },
        },
        [_vm._v("Annuleren")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pb-3" }, [
      _c("small", [
        _vm._v("Klik op een tag om hem aan dit bestand toe te voegen"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }