var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row no-gutters mb-3" },
      [
        _c("file-management", {
          attrs: {
            id: _vm.id,
            "group-id": _vm.id,
            "has-update-rights": _vm.hasUpdateRights,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }