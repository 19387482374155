<template>
    <modal>
        <p>
            Bent u zeker dat u "{{ file.name }}" wil verwijderen?
            Indien ja, vul hieronder de bestandsnaam in als bevestiging.
        </p>

        <ValidationObserver v-slot="{ invalid }">
            <ValidationProvider :rules="{is: file.name, required: {}}" v-slot="{ errors }">
                <input type="text" v-model="confirmationName">
                <span class="mb-3 d-block form-errors">{{ errors[0] }}</span>
            </ValidationProvider>

            <div>
                <button class="btn btn-xs btn-default mr-2" @click="confirm" :disabled="invalid">Bevestigen</button>
                <button class="btn btn-xs button--neutral" @click="cancel">Annuleren</button>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
    import { deleteAsset } from "./api";
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import { is, required } from 'vee-validate/dist/rules';

    extend('is', {
        ...is,
        message: 'De bestandsnaam komt niet overeen.'
    });

    extend('required', {
        ...required,
        message: 'De bestandsnaam moet ingevuld worden.'
    });

    export default {
        name: 'delete-asset-confirmation',
        data() {
            return {
                confirmationName: '',
            }
        },
        props: {
            file: {
                type: Object,
                required: true,
            },
            groupId: {
                type: Number,
                required: true,
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        methods: {
            confirm () {
                deleteAsset(this.groupId, this.file.id).then(() => {
                    this.$emit('deleted');
                    this.$emit('close');
                });
            },
            cancel() {
                this.$emit('close');
            },
        }
    }
</script>
