var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui container group-member-list" },
    [
      _vm.feedback
        ? _c("flashmessage", {
            attrs: { type: _vm.flashType, message: _vm.feedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-5 my-4" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6" }, [
          _c(
            "a",
            {
              staticClass: "button button--xs float-right",
              attrs: { href: _vm.membershipRequestUrl },
            },
            [_vm._v("Aangevraagde lidmaatschappen")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6" },
          [_c("user-management-filter")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-md-6 d-flex justify-content-end mb-3" },
          [
            _c(
              "label",
              {
                ref: "fileSelect",
                staticClass: "button button--neutral button--xs mr-2",
                attrs: {
                  for: "fileSelect",
                  "data-toggle": "tooltip",
                  title:
                    "Let op: de bulk import functie voor gebruikers ondersteunt enkel .xlsx bestanden. Sla uw bestand op in de juiste indeling! Structureer de gegevens respectievelijk in kolommen: Naam, Voornaam en E-mailadres (zonder kolomtitelrij).",
                },
              },
              [
                _vm.showSpinner
                  ? _c("span", {
                      staticClass: "loader",
                      attrs: { id: "loader" },
                    })
                  : _c("span", [_vm._v("Meerdere uitnodigen")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "button button--xs",
                on: {
                  click: function ($event) {
                    _vm.inviteModal = true
                  },
                },
              },
              [_vm._v("Iemand uitnodigen")]
            ),
            _vm._v(" "),
            _c("input", {
              ref: "file",
              staticClass: "d-none",
              attrs: {
                id: "fileSelect",
                type: "file",
                accept:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
              },
              on: { change: _vm.uploadBulk },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("vuetable", {
        ref: "vuetable",
        attrs: {
          "api-url": _vm.groupMemberListUrl,
          fields: _vm.fields,
          "query-params": _vm.makeQueryParams,
          "append-params": _vm.moreParams,
          "show-sort-icons": true,
          css: _vm.css.table,
          "pagination-path": "",
          "per-page": 10,
        },
        on: {
          "vuetable:pagination-data": _vm.onPaginationData,
          "vuetable:cell-clicked": _vm.onCellClick,
        },
        scopedSlots: _vm._u([
          {
            key: "moderator",
            fn: function ({ rowData }) {
              return [
                rowData.isModerator
                  ? _c("icon", { attrs: { icon: "check" } })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "action",
            fn: function ({ rowData }) {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-xs btn-danger mr-1 mb-1 mb-xl-0",
                    on: {
                      click: function ($event) {
                        return _vm.startTrashModal(rowData)
                      },
                    },
                  },
                  [_c("icon", { attrs: { icon: "trash-alt" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-xs btn-primary mr-1 mb-1 mb-xl-0",
                    on: {
                      click: function ($event) {
                        return _vm.startManagingModal(rowData)
                      },
                    },
                  },
                  [_c("icon", { attrs: { icon: "crown" } })],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.managingModal
        ? _c("manage-user", {
            on: {
              confirm: _vm.makeUserModerator,
              close: function ($event) {
                _vm.managingModal = false
              },
            },
            model: {
              value: _vm.activeUser,
              callback: function ($$v) {
                _vm.activeUser = $$v
              },
              expression: "activeUser",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteModal
        ? _c("delete-user", {
            on: {
              confirm: _vm.remove,
              close: function ($event) {
                _vm.deleteModal = false
              },
            },
            model: {
              value: _vm.activeUser,
              callback: function ($$v) {
                _vm.activeUser = $$v
              },
              expression: "activeUser",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inviteModal
        ? _c("invite-user", {
            attrs: { "group-id": _vm.groupId },
            on: {
              close: function ($event) {
                _vm.inviteModal = false
              },
              invited: _vm.invited,
              error: _vm.showFeedback,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("vuetable-pagination", {
        ref: "pagination",
        attrs: { css: _vm.css.pagination, "on-each-side": 2 },
        on: { "vuetable-pagination:change-page": _vm.onChangePage },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("h2", { staticClass: "mb-0" }, [_vm._v("Ledenbeheer")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }