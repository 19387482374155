<template>
    <div class="climate-project-overview__filters">
        <!-- Search bar -->
        <div class="form-wrapper">
            <div class="search">
                <input v-model="value.searchTerm" @input="change" type="text" placeholder="Typ op te zoeken">
                <span><icon icon="search"></icon></span>
            </div>
        </div>

        <div class="climate-project-overview__filters-button mt-3 mb-3 d-md-none" @click="showFilters = !showFilters">
            <span v-if="showFilters">Verberg filters</span>
            <span v-if="!showFilters">Toon filters</span>
            <icon icon="sliders-h"></icon>
        </div>

        <div class="d-md-block" :class="showFilters ? 'd-block' : 'd-none'">
            <hr>

            <!-- Initiator picker -->
            <div class="form-wrapper mt-3 d-md-block">
                <label>Initiatiefnemer</label>
                <v-select @input="selectChanged($event, 'initiatorValue')"
                          :options="value.initiators"
                          :placeholder="'Kies een initiatiefnemer'"
                          label="text"
                >
                    <template #option="{ value, text }">
                        <span style="white-space: normal; line-height: 1;">{{ text }}</span>
                    </template>
                </v-select>
            </div>

            <hr>

            <!-- Status picker -->
            <div class="form-wrapper mt-3">
                <label>Status</label>
                <v-select @input="selectChanged($event, 'statusValue')"
                          :options="value.statuses"
                          :placeholder="'Kies een status'"
                          label="text"
                ></v-select>
            </div>

            <hr>

            <!-- Theme selector -->
            <div class="form-wrapper mt-3">
                <div class="inline-checkbox">
                    <label>Type project</label>
                    <div v-for="theme in value.themes"
                         :ref="theme.value"
                         class="chekbox">
                        <input type="checkbox" :checked="theme.checked" @input="checkboxChanged(theme)" :id="'theme_' + theme.value">
                        <label :for="'theme_' + theme.value">{{ theme.label }}</label>
                    </div>
                </div>
            </div>

            <hr>

            <!-- City size selector -->
            <div class="form-wrapper mt-3">
                <div class="inline-checkbox">
                    <label>Grootte van de gemeente</label>
                    <div v-for="citySize in value.citySizes"
                         :ref="citySize.value"
                         class="chekbox">
                        <input type="checkbox" :checked="citySize.checked" @input="checkboxChanged(citySize)" :id="'citySize_' + citySize.value">
                        <label :for="'citySize_' + citySize.value">{{ citySize.label }}</label>
                    </div>
                </div>
            </div>

            <hr>

            <!-- Budget selector -->
            <div class="form-wrapper mt-3">
                <div class="inline-checkbox">
                    <label>Budget</label>
                    <div v-for="budget in value.budgets"
                         :ref="budget.value"
                         class="chekbox">
                        <input type="checkbox" :checked="budget.checked" @input="checkboxChanged(budget)" :id="'budget_' + budget.value">
                        <label :for="'budget_' + budget.value">{{ budget.label }}</label>
                    </div>
                </div>
            </div>

            <hr class="d-md-none">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            timer: false,
            showFilters: false,
        };
    },

    methods: {
        change() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = false;
            }

            this.timer = setTimeout(() => {
                this.$emit('input', this.value);
            }, 400);
        },

        checkboxChanged(item) {
            item.checked = !item.checked
            this.$emit('input', this.value);
        },

        selectChanged(value, property) {
            // When the selectbox was cleared value is null
            value = value === null ? '' : value.value

            this.value[property] = value;
            this.$emit('input', this.value);
        },
    }
}
</script>
