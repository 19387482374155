<template>
    <li>
        <div class="checkbox">
            <div>
                <input type="checkbox"
                       :id="id"
                       :checked="checked"
                       @change="$emit('change', $event.target.checked)"
                       class="search-checkbox">
                        <i></i>
            </div>
            <label class="search-category" :for="id">
                <slot></slot>
            </label>
        </div>
    </li>
</template>

<script>
import uuid from '../mixins/uuid';

export default {
    model: {
        prop: 'checked',
        event: 'change',
    },
    props: {
        checked: Boolean
    },
    data: () => ({
        id: null,
    }),
    mounted () {
        this.id = `filter-${this.uuid}`;
    },
    mixins: [uuid],
};
</script>
