import axios from 'axios';

export function searchFiles(payload) {
    return axios.get(Routing.generate('ajax_group_files_search', payload));
}

export function getFiles(payload) {
    return axios.get(Routing.generate('ajax_group_files', payload));
}

export function destroyFile(payload) {
    return axios.delete(Routing.generate('ajax_group_meeting_file_destroy', payload));
}

export function downloadGroupFile(groupId, id) {
    return Routing.generate('group_download_asset', { groupId, id });
}

export function downloadBulkGroupFiles(groupId, folderId) {
    return Routing.generate('ajax_download_group_asset_bulk', { groupId, folderId });
}

export function createFolder(entityId, folder, name) {
    return axios.post(Routing.generate('ajax_create_group_folder', { entityId }), { folder, name });
}

export function renameAsset(entityId, assetId, name) {
    return axios.post(Routing.generate('ajax_rename_group_asset', { entityId, assetId }), { name });
}

export function deleteAsset(entityId, assetId) {
    return axios.delete(Routing.generate('ajax_delete_group_asset', { entityId, assetId }));
}

export function getFileInfo(entityId, folder, name) {
    return axios.post(Routing.generate('ajax_asset_group_info', { entityId }), { folder, name });
}

export function getFileTags(groupId, assetId) {
    return axios.get(Routing.generate('ajax_group_asset_tag', { groupId, assetId }));
}

export function addFileTags(groupId, assetId, tags) {
    return axios.post(Routing.generate('ajax_group_asset_add_tag', { groupId, assetId }), { tags });
}
