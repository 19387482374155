<template>
    <a href="" class="btn btn-xs btn-danger mb-1 mb-sm-0 pull-right" @click.prevent="deleting = true">
        <slot>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="text-white svg-inline--fa fa-trash-alt fa-w-14">
                <path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z" class=""></path>
            </svg>
        </slot>
        <modal v-if="deleting">
            <p>Bent u zeker dat u dit wil verwijderen?</p>

            <div>
                <button class="btn btn-xs btn-default" @click.stop.prevent="confirm">Bevestigen</button>
                <button class="btn btn-xs button--neutral" @click.stop.prevent="cancel">Annuleren</button>
            </div>

        </modal>
    </a>
</template>

<script>
    export default {
        props: {
            url: {
                required: true,
                type: String,
            },
        },
        data: () => ({
            deleting: false,
        }),

        methods: {
            confirm() {
                window.location = this.url;
                this.deleting = false;
            },

            cancel() {
                this.deleting = false;
            }
        }
    }
</script>
