var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "float-none float-md-right mb-3" }, [
            _c(
              "a",
              {
                staticClass: "button button--xs mr-2",
                attrs: { href: _vm.link },
              },
              [
                _c("icon", { staticClass: "mr-2", attrs: { icon: "edit" } }),
                _vm._v(
                  "\n                        Aanpassen\n                    "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "btn btn-xs btn-danger",
                on: { click: _vm.remove },
              },
              [
                _c("icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "trash-alt" },
                }),
                _vm._v(
                  "\n                        Verwijderen\n                    "
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.visible
        ? _c("modal", [
            _c("div", { staticClass: "form-group" }, [
              _c("p", { staticClass: "control-label" }, [
                _vm._v(
                  "Bent u zeker dat u deze vergadering wilt verwijderen? Door op 'Bevestigen' te klikken, worden ook alle bestanden van deze vergadering verwijderd."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-xs btn-default mr-2",
                  on: { click: _vm.confirm },
                },
                [_vm._v("Bevestigen")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-xs button--neutral",
                  on: { click: _vm.cancel },
                },
                [_vm._v("Annuleren")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }