<template>
    <div :class="classes">

        <form @submit.prevent="onSubmit">
            <input name="query"
                   autocomplete="off"
                   type="text"
                   @input="suggest"
                   v-model="term"
                   @keydown.down="onArrowDown"
                   @keydown.up="onArrowUp"
                   @focus="open"
                   :placeholder="placeholder"
                   :disabled="disabled"
            >

            <button v-if="showSubmit" type="submit" @submit="onSubmit" @mouseenter="arrowCounter = -1">Zoeken</button>

            <div class="autocomplete-wrapper">
                <ul
                        id="autocomplete-results"
                        v-show="isOpen"
                        class="autocomplete-results"
                >
                    <li
                            class="autocomplete-result"
                            v-if="isLoading"
                    >
                        ⟳ Suggesties aan het zoeken...
                    </li>
                    <li
                            v-else
                            v-for="(item, i) in results"
                            :key="i"
                            @click="onSubmit"
                            @mouseenter="arrowCounter = i"
                            class="autocomplete-result"
                            :class="{ 'is-active': i === arrowCounter }"
                    >
                        <slot v-bind:item="item">
                            <span v-html="item.title"></span>
                        </slot>
                    </li>
                </ul>
            </div>
        </form>
    </div>
</template>

<script>

    import { debounce } from 'lodash';
    export default {
        name: 'autocomplete',

        props: {
            request: {
                required: true,
                type: Function,
            },
            showSubmit: {
                required: false,
                type: Boolean,
                default: true
            },
            placeholder: {
                required: false,
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean
            }
        },

        data() {
            return {
                isOpen: false,
                results: [],
                term: '',
                isLoading: false,
                arrowCounter: -1,
            };
        },

        computed: {
            classes() {
                return {
                    autocomplete: true,
                    'autocomplete-open': this.isOpen && (this.results.length || this.isLoading ),
                }
            }
        },

        methods: {
            suggest: debounce(function(){
                if(this.term.length){
                    this.isLoading = true;
                    this.request(this.term).then(({data}) => {
                        this.results = data;
                        this.isLoading = false;
                        this.isOpen = true;
                        this.arrowCounter = -1;
                    });
                } else {
                    this.results = [];
                    this.close();
                }
            }, 500),

            onArrowDown() {
                if (this.arrowCounter < this.results.length - 1) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
                else if(this.arrowCounter === this.results.length - 1){
                    this.arrowCounter = 0;
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter -1;
                }
                else if(this.arrowCounter === 0){
                    this.arrowCounter = this.results.length - 1;
                }
            },
            onSubmit() {
                this.$emit('submit', {
                    term: this.term,
                    item: this.results[this.arrowCounter]
                });
                this.term = '';
                this.results = [];
                this.close();
            },
            handleClickOutside(evt) {
                if (!this.$el.contains(evt.target)) {
                    this.close();
                }
            },
            open(){
                if(this.results.length){
                    this.isOpen = true;
                }
            },
            close(){
                this.isLoading = false;
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside)
        },
        destroyed() {
            document.removeEventListener('click', this.handleClickOutside)
        },
    };
</script>
