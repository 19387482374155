var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-detail" }, [
    _vm.loading
      ? _c("div", { staticClass: "no-messages" }, [
          _vm._v("⟳ Bericht aan het laden"),
        ])
      : _vm.sent
      ? _c("div", { staticClass: "no-messages" }, [
          _c("p", [_vm._v("Bericht verzonden.")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button--xs button",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clearState()
                },
              },
            },
            [
              _c("icon", { attrs: { icon: "plus" } }),
              _vm._v(" Nog een bericht versturen\n            "),
            ],
            1
          ),
        ])
      : _vm.error
      ? _c("div", { staticClass: "no-messages alert alert-danger" }, [
          _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
        ])
      : _c("div", { staticClass: "form-wrapper" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("user-select", {
                attrs: {
                  disabled: _vm.receivers.length === _vm.recipientLimit,
                },
                model: {
                  value: _vm.receivers,
                  callback: function ($$v) {
                    _vm.receivers = $$v
                  },
                  expression: "receivers",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-element" }, [
                _c("label", [_vm._v("Onderwerp *:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.subject,
                      expression: "subject",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.subject },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.subject = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-element" }, [
                _c("label", [_vm._v("Bericht *:")]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message,
                      expression: "message",
                    },
                  ],
                  attrs: { name: "", id: "", cols: "30", rows: "10" },
                  domProps: { value: _vm.message },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.message = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("file-uploader", {
                on: {
                  uploading: function ($event) {
                    _vm.uploading = true
                  },
                  finished: function ($event) {
                    _vm.uploading = false
                  },
                },
                model: {
                  value: _vm.files,
                  callback: function ($$v) {
                    _vm.files = $$v
                  },
                  expression: "files",
                },
              }),
              _vm._v(" "),
              _vm.validationError
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.validationError) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _c("input", {
                  staticClass: "button--xs button",
                  attrs: {
                    type: "button",
                    value: "Verstuur",
                    disabled: _vm.uploading,
                  },
                  on: { click: _vm.sendMessage },
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "button--xs button",
                  attrs: {
                    type: "button",
                    value: "Opslaan",
                    disabled: _vm.uploading,
                  },
                  on: { click: _vm.saveMessage },
                }),
              ]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }