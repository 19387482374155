var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "btn btn-xs btn-danger mb-1 mb-sm-0 pull-right",
      attrs: { href: "" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          _vm.deleting = true
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c(
            "svg",
            {
              staticClass: "text-white svg-inline--fa fa-trash-alt fa-w-14",
              attrs: {
                "aria-hidden": "true",
                focusable: "false",
                "data-prefix": "fas",
                "data-icon": "trash-alt",
                role: "img",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 448 512",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d: "M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z",
                },
              }),
            ]
          ),
        ]
      }),
      _vm._v(" "),
      _vm.deleting
        ? _c("modal", [
            _c("p", [_vm._v("Bent u zeker dat u dit wil verwijderen?")]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-xs btn-default",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.confirm.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Bevestigen")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-xs button--neutral",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.cancel.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Annuleren")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }