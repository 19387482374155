<template>
    <div class="message-info-card"
         @click="$emit('open', message)"
         :class="{ unread: message.messageType === 'inbox' && !message.isRead, active:selected }"
    >
        <div class="message-sender">
            <template v-if="message.fromUser">{{ message.fromUser.firstname }} {{ message.fromUser.lastname }}</template>
            <template v-else>&lt;Nog geen ontvanger(s)&gt;</template>
        </div>
        <div v-if="message.subject" class="message-subject">
            {{ message.subject }}
        </div>
        <div v-else>&lt;Nog geen onderwerp&gt;</div>

        <div class="message-date">
            {{ formatDate(message.o_creationDate) }}
        </div>

        <span class="delete-icon">
            <icon icon="trash-alt" @click.stop="deleteMessage"></icon>
        </span>
    </div>
</template>

<script>
    import { format } from 'date-fns';
    import { DELETE_MESSAGE } from "./index";

    const dutchLocale = require('date-fns/locale/nl');

    export default {
        name: 'message-info-card',
        props: {
            message: null,
        },
        computed:{
            selected(){
                return this.$route.name.indexOf('messages-detail') !== -1 &&
                    Number.parseInt(this.$route.params.id) === this.message.o_id;
            }
        },
        methods: {
            deleteMessage() {
                this.$fire(DELETE_MESSAGE, this.message);
            },
            formatDate(timestamp) {
                return format(new Date(timestamp*1000),  'D-M-YYYY HH:mm', { locale: dutchLocale });
            },
        },
    };
</script>
