<template>
    <div class="form-element">

        <div>

            <div v-if="value.length || uploading.length">
                <label for="attachments">Bijlagen: </label>
                <ul id="attachments">
                    <li v-for="file in value">{{ showFilename(file.metadata) }}&nbsp;<icon @click="removeAsset(file)" icon="trash-alt"></icon></li>
                    <li  v-for="file in uploading">⟳ {{ file.name }} is aan het uploaden</li>
                </ul>
            </div>

            <div class="alert alert-danger" v-if="errors.length">
                <ul class="m-0">
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </div>

            <div class="uploader mb-3 mt-3">
                <label for="message-files" class="button button--xs">
                    Bijlagen uploaden
                    <input type="file"
                           style="opacity: 0"
                           ref="input"
                           name="files"
                           id="message-files"
                           v-on:input="uploadAssets"
                           accept="image/*,video/*,.pdf,.txt,.rtf,.doc,.docx,.csv,.csvx,.ppt,.pptx,.xls,.xlsx"
                           multiple
                    >
                </label>
            </div>

        </div>

    </div>

</template>
<script>
    import { uploadAsset, deleteAsset } from "../inbox/api";

    // @flow
    export default {
        name: "fileUploader",
        props: {
            value: {
                required: true,
                type: Array,
            },
        },
        data() {
            return {
                uploading: [],
                errors: [],
            };
        },
        methods: {
            showFilename(data){
                const filename = data.filter(item => item.name === 'filename')[0];

                if(!filename){
                    return 'unknown';
                }

                return filename.data;
            },
            removeAsset(file) {
                return deleteAsset(file.id).then(() => {
                    const files = this.value.filter(entry => entry.id !== file.id);
                    this.$emit('input', files);
                });
            },
            uploadAssets(e) {
                const files = Array.from(e.target.files);

                if (!files.length) {
                    return;
                }

                this.$emit('uploading');
                this.$refs.input.value = null;

                files.forEach(file => {
                    const formData = new FormData();
                    formData.append('file', file, file.name);
                    this.uploading.push(file);

                    uploadAsset(formData).then(({ data }) => {
                        this.uploading = this.uploading.filter((upload) => {
                            return upload.name !== file.name;
                        });

                        this.$emit('input', this.value.concat([data]));
                    }).catch(() => {
                        this.uploading = this.uploading.filter((upload) => {
                            return upload.name !== file.name;
                        });

                        this.errors.push(`Uploaden van ${file.name} is mislukt.`);
                        setTimeout(() => {
                            this.errors.shift();
                        }, 3000);

                        //always callback
                    }).then(() => {
                        if(this.uploading.length === 0) {
                            this.$emit('finished');
                        }
                    });
                });
            }
        }
    }
</script>
