<template>
    <div>
        <div class="mb-3">
            <label>Alle tags</label>
            <div class="pb-3">
                <small>Klik op een tag om hem aan dit bestand toe te voegen</small>
            </div>
            <div class="taglist">
                <group-tag
                        v-for="(tag, key) in allGroupTags"
                        :tag="tag"
                        :key="key"
                        :is-editable="false"
                        :is-removable="false"
                        @click="addTag"
                ></group-tag>
            </div>
        </div>
        <div class="mb-3">
            <label v-if="fileGroupTags.length > 0">Bestandstags</label>
            <div class="taglist">
                <group-tag
                        v-for="(tag, key) in fileGroupTags"
                        :tag="tag"
                        :key="key"
                        :is-editable="false"
                        @click="addTag"
                        @deleteTag="deleteTag"
                ></group-tag>
            </div>
        </div>
        <div>
            <button class="btn btn-xs btn-default mr-1" @click="confirm">Opslaan</button>
            <button class="btn btn-xs button--neutral" @click="cancel">Annuleren</button>
        </div>
    </div>
</template>

<script>
    import { getFileTags, addFileTags } from './api';

    export default {
        name: 'fileTags',
        props: {
            groupId: {
                type: Number,
                required: true,
            },
            fileId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                allGroupTags: [],
                fileGroupTags: [],
            }
        },
        created() {
            this.fetch();
        },
        methods: {
            fetch() {
                getFileTags(this.groupId, this.fileId).then(({ data }) => {
                    this.allGroupTags = data.groupTags;
                    this.fileGroupTags = data.fileTags;
                });
            },
            deleteTag(tag) {
                this.fileGroupTags.splice(this.fileGroupTags.indexOf(tag), 1);
            },
            addTag(tag) {
                const index = this.fileGroupTags.map(fileGroupTag => fileGroupTag.id).indexOf(tag.id);

                if (index === -1) {
                    this.fileGroupTags.push(tag);
                }
            },
            confirm() {
                addFileTags(this.groupId, this.fileId, this.fileGroupTags).then(() => {
                    this.$emit('update', { fileId: this.fileId, tags: this.fileGroupTags });
                    this.$emit('close');
                });
            },
            cancel() {
                this.$emit('close');
            },
        },
    }
</script>
