<template>
    <div>
        <div v-if="errored">
            <span class="error-line">Het bestand kon niet worden geüploaded</span>
        </div>

        <div class="climate-project__main-image-container">
            <div v-if="hasImage" class="climate-project__main-image-label-container">
                <span class="climate-project__main-image-label">Klik op de afbeelding om een andere te kiezen</span>
                <span @click="clear"><icon icon="times"></icon></span>
            </div>

            <div class="climage-project__main-image-functionality">
                <input type="file"
                       class="hidden"
                       :class="classes.input"
                       @change="change"
                       ref="fileupload"
                       accept=".webp, .svg, .png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .gif, .avif, .apng"
                >

                <div class="climate-project__main-image-preview">
                    <img v-if="preview" :src="preview" alt="preview image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'ImageUpload',

        props: {
            inputId: {
                type: String,
                required: true,
            },
            defaultImage: {
                required: false,
                type: Object,
                default: {},
            },
            uploadRoute: {
                required: true,
                type: String,
            },
        },

        data() {
            return {
                file: undefined,
                isChanged: false,
                errored: false,
            };
        },

        created() {
            this.file = this.defaultImage;
        },

        computed: {
            hasImage() {
                if (false === this.isChanged) {
                    return !! this.defaultImage.path;
                }

                return !! this.file;
            },
            preview() {
                if (false === this.isChanged) {
                    return this.defaultImage.path || undefined;
                }

                return this.file ? this.file.path : undefined;
            },
            classes() {
                return {
                    input: 'climate-project__main-image-input' + (this.hasImage ? '--hidden' : ''),
                };
            },
        },

        methods: {
            change(event) {
                if (event.target.files <= 0) {
                    this.clear();
                    return;
                }

                this.upload(event.target.files[0]).then(({data}) => {
                    this.file = data;
                    this.isChanged = true;
                    document.getElementById(this.inputId).setAttribute('value', this.file.id);
                    this.errored = false;
                }).catch(err => {
                    this.clear();
                    this.errored = true;
                });
            },
            async upload(file) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('onlyImages', true);

                return axios.post(Routing.generate(this.uploadRoute), formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            },
            clear() {
                this.file = undefined;
                this.$refs.fileupload.value = null;
                this.isChanged = true;
                document.getElementById(this.inputId).setAttribute('value', '');
            },
        }
    };
</script>
