var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "climate-project-overview__card" }, [
    _c(
      "div",
      {
        staticClass: "climate-project-overview__info",
        on: { click: _vm.clickCard },
      },
      [
        _c("figure", {
          domProps: { innerHTML: _vm._s(_vm.climateProject.mainImage) },
        }),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.climateProject.projectName || "-")),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "subtitle" }, [
            _vm._v("Thema: " + _vm._s(_vm.climateProject.themes || "-")),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("span", { staticClass: "icon fa fa-map" }),
            _vm._v(_vm._s(_vm.climateProject.cities || "-")),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("span", { staticClass: "icon fa fa-map-pin" }),
            _vm._v(_vm._s(_vm.climateProject.address || "-")),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "link" }, [
            _vm.edit
              ? _c(
                  "a",
                  {
                    staticClass: "px-2",
                    attrs: { href: _vm.route },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Wijzigen\n                        "
                    ),
                    _c(
                      "span",
                      { staticClass: "ml-2" },
                      [_c("icon", { attrs: { icon: "pencil-alt" } })],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.edit
              ? _c(
                  "a",
                  {
                    staticClass: "px-2",
                    attrs: { href: _vm.route },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Bekijk meer\n                    "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        !_vm.climateProject.isPublished
          ? _c("span", { staticClass: "climate-project-overview-label" }, [
              _vm._v("Nog niet ingediend"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.climateProject.isPublished && !_vm.climateProject.approved
          ? _c("span", { staticClass: "climate-project-overview-label" }, [
              _vm._v("Nog niet goedgekeurd"),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.isSelectedValue
      ? _c("div", {
          staticClass: "climate-project-overview__teaser",
          domProps: { innerHTML: _vm._s(_vm.climateProject.teaser) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }