var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "badge badge-dark mr-2 mb-1" },
    [
      _vm.isEditMode && _vm.isEditable
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newName,
                expression: "newName",
              },
            ],
            attrs: { id: "tag-edit-" + _vm.tag.id },
            domProps: { value: _vm.newName },
            on: {
              blur: _vm.updateTag,
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.updateTag.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "escape",
                      undefined,
                      $event.key,
                      undefined
                    )
                  )
                    return null
                  return _vm.reset.apply(null, arguments)
                },
              ],
              input: function ($event) {
                if ($event.target.composing) return
                _vm.newName = $event.target.value
              },
            },
          })
        : _c(
            "label",
            {
              staticClass: "px-2 py-1 mb-0 clickable",
              on: {
                click: function ($event) {
                  return _vm.click(_vm.tag)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.tag.name) + " ")]
          ),
      _vm._v(" "),
      _vm.isRemovable
        ? _c("icon", {
            staticClass: "clickable",
            attrs: { icon: "times" },
            on: {
              click: function ($event) {
                return _vm.deleteTag(_vm.tag)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }