var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search container search-results" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "filter-icon", on: { click: _vm.toggleFilters } },
            [_vm._m(0), _vm._v(" "), _c("span", [_vm._v("Filters")])]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-3 col-md-4" }, [
          _c("div", { staticClass: "search__sidebar" }, [
            _c("div", { staticClass: "search__title" }, [
              _vm.searchData.pagination.total_items > 1
                ? _c("strong", [
                    _vm._v(
                      _vm._s(_vm.searchData.pagination.total_items) +
                        " resultaten"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.searchData.pagination.total_items === 1
                ? _c("strong", [
                    _vm._v(
                      _vm._s(_vm.searchData.pagination.total_items) +
                        " resultaat"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.searchData.pagination.total_items === 0
                ? _c("strong", [_vm._v("Geen resultaten")])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { class: { open: _vm.visibleFilters } },
              _vm._l(_vm.searchData.categories, function (category, index) {
                return _c(
                  "checkbox-filter",
                  {
                    key: index,
                    on: { change: _vm.newSearch },
                    model: {
                      value: category.active,
                      callback: function ($$v) {
                        _vm.$set(category, "active", $$v)
                      },
                      expression: "category.active",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "build/images/" + category.icon,
                        alt: "filter-icon",
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(category.name) +
                        "\n                            "
                    ),
                    _c("span", [
                      _vm._v("(" + _vm._s(category.doc_count) + ")"),
                    ]),
                  ]
                )
              }),
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-9 col-md-8" },
          [
            _c("div", { staticClass: "search__content" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  !_vm.visibleSave && _vm.user
                    ? _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              _vm.visibleSave = true
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "build/images/email_downlaod.png",
                              alt: "icon",
                            },
                          }),
                          _c("span", [
                            _vm._v("Blijf op de hoogte van nieuwe resultaten"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.visibleSave
                    ? _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchName,
                                expression: "searchName",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "Naam van zoekresultaat",
                            },
                            domProps: { value: _vm.searchName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.searchName = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button button--xs",
                              attrs: { disabled: _vm.btnDisable },
                              on: { click: _vm.saveSearch },
                            },
                            [_vm._v("Opslaan")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 text-center" }, [
                  _c("div", { staticClass: "search__sort" }, [
                    _c("span", [_vm._v("Sorteren op: ")]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "search__pills" }, [
                      _c(
                        "li",
                        {
                          class: {
                            "is-active": _vm.searchSort === "relevance",
                          },
                          on: {
                            click: function ($event) {
                              _vm.searchSort = "relevance"
                            },
                          },
                        },
                        [_c("a", [_vm._v("relevantie")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          class: { "is-active": _vm.searchSort === "recent" },
                          on: {
                            click: function ($event) {
                              _vm.searchSort = "recent"
                            },
                          },
                        },
                        [_c("a", [_vm._v("recent")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          class: { "is-active": _vm.searchSort === "old" },
                          on: {
                            click: function ($event) {
                              _vm.searchSort = "old"
                            },
                          },
                        },
                        [_c("a", [_vm._v("oude")])]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.advancedSearch && !_vm.forceAdvancedSearch,
                          expression: "!advancedSearch && !forceAdvancedSearch",
                        },
                      ],
                      staticClass: "text-right mt-2",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.advancedSearch = !_vm.advancedSearch
                            },
                          },
                        },
                        [_vm._v("Geavanceerd zoeken")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.advancedSearch || _vm.forceAdvancedSearch,
                          expression: "advancedSearch || forceAdvancedSearch",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "search__sort mt-2" }, [
                        _c("span", [_vm._v("Beleidsdomeinen: ")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _c("v-select", {
                              staticStyle: { "min-width": "250px" },
                              attrs: {
                                multiple: "",
                                options: _vm.searchData.domains,
                                label: "text",
                              },
                              model: {
                                value: _vm.selectedDomains,
                                callback: function ($$v) {
                                  _vm.selectedDomains = $$v
                                },
                                expression: "selectedDomains",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "search__sort mt-2" }, [
                        _c("span", [_vm._v("Startdatum: ")]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { width: "250px" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedFromDate,
                                expression: "selectedFromDate",
                              },
                            ],
                            ref: "startdatefilter",
                            domProps: { value: _vm.selectedFromDate },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.selectedFromDate = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "search__sort mt-2" }, [
                        _c("span", [_vm._v("Einddatum: ")]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { width: "250px" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedToDate,
                                expression: "selectedToDate",
                              },
                            ],
                            ref: "enddatefilter",
                            domProps: { value: _vm.selectedToDate },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.selectedToDate = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "search__sort mt-2" }, [
                        _c("span", { staticClass: "mb-1" }, [
                          _vm._v("Datum van: "),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { width: "250px" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedDateMode,
                                expression: "selectedDateMode",
                              },
                            ],
                            staticClass: "mr-1 mb-1",
                            attrs: {
                              type: "radio",
                              id: "publicationMode",
                              name: "dateMode",
                              value: "publication",
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.selectedDateMode,
                                "publication"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.selectedDateMode = "publication"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "mr-1",
                              attrs: { for: "publicationMode" },
                            },
                            [_vm._v("Publicatie")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedDateMode,
                                expression: "selectedDateMode",
                              },
                            ],
                            staticClass: "mr-1 mb-1",
                            attrs: {
                              type: "radio",
                              id: "revisionMode",
                              name: "dateMode",
                              value: "revision",
                            },
                            domProps: {
                              checked: _vm._q(_vm.selectedDateMode, "revision"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.selectedDateMode = "revision"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "revisionMode" } }, [
                            _vm._v("Revisie"),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "form-wrapper" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchQuery,
                            expression: "searchQuery",
                          },
                        ],
                        attrs: {
                          placeholder: "Zoeken...",
                          id: "search",
                          type: "search",
                          autocomplete: "off",
                        },
                        domProps: { value: _vm.searchQuery },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchQuery = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.searchData.items, function (item, key) {
              return _vm.searchData.items.length
                ? _c("card-search", {
                    key: key,
                    attrs: { data: item.data },
                    on: { navigate: _vm.navigate },
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            _c("div", { staticClass: "search__box" }, [
              _vm.noResultsFound
                ? _c("div", { staticClass: "search__block" }, [
                    _c("span", [_vm._v("Geen resultaten gevonden")]),
                  ])
                : _vm._e(),
            ]),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      !_vm.noResultsFound
        ? _c(
            "div",
            { staticClass: "pagination" },
            [
              _c(
                "ul",
                { staticClass: "pagination b-pagination pagination-md" },
                [
                  _c(
                    "li",
                    {
                      staticClass: "page-item",
                      class: _vm.firstPageClasses,
                      attrs: {
                        role: "none presentation",
                        "aria-hidden": "true",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.changedPage(1)
                            },
                          },
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("«"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.searchData.pagination.total_items,
                  "per-page": _vm.searchData.pagination.items_per_page,
                  "hide-ellipsis": true,
                  "hide-goto-end-buttons": true,
                },
                on: { input: _vm.changedPage },
                model: {
                  value: _vm.searchData.pagination.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchData.pagination, "current_page", $$v)
                  },
                  expression: "searchData.pagination.current_page",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.feedback
        ? _c("flashmessage", {
            attrs: { type: _vm.flashType, message: _vm.feedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("modal-redirect", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showModalRedirect,
            expression: "showModalRedirect",
          },
        ],
        attrs: {
          url: _vm.modalRedirectLink,
          tab: _vm.modalTab,
          remoteContentWarning: _vm.remoteContentWarning,
        },
        on: {
          close: function ($event) {
            _vm.showModalRedirect = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("figure", [
      _c("img", {
        attrs: { src: "build/images/menu_bars.svg", alt: "filter icon" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }