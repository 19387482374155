<template>
    <modal>
        <p>Bent u zeker dat u deze gebruiker uit de groep wil verwijderen?</p>

        <div>
            <button class="btn btn-xs btn-default" @click="confirm">Bevestigen</button>
            <button class="btn btn-xs button--neutral" @click="cancel">Annuleren</button>
        </div>

    </modal>
</template>

<script>
    export default {
        name: 'delete-user',

        props: {
            value: {
                required: true,
                type: Object,
            }
        },

        methods: {
            confirm() {
                this.$emit('confirm');
            },

            cancel() {
                this.$emit('close');
            },
        }
    }
</script>
