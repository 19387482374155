var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal-redirect", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.showModalRedirect,
        expression: "showModalRedirect",
      },
    ],
    attrs: { url: _vm.url, remoteContentWarning: _vm.remoteContentWarning },
    on: {
      close: function ($event) {
        _vm.showModalRedirect = false
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }