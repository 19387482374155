var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-detail" }, [
    _vm.loading
      ? _c("div", { staticClass: "no-messages" }, [
          _vm._v("⟳ Bericht aan het laden"),
        ])
      : _vm.error
      ? _c("div", { staticClass: "no-messages" }, [_vm._v(_vm._s(_vm.error))])
      : _c("div", { staticClass: "message-wrapper" }, [
          _c("div", { staticClass: "message-header" }, [
            _c("p", [
              _vm._v("Van: "),
              _c(
                "a",
                { attrs: { href: _vm.toProfile(_vm.message.fromUser.slug) } },
                [
                  _vm._v(
                    _vm._s(_vm.message.fromUser.firstname) +
                      " " +
                      _vm._s(_vm.message.fromUser.lastname)
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "message-subject" }, [
              _vm._v("Onderwerp: " + _vm._s(_vm.message.subject) + " "),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "Datum: " + _vm._s(_vm.formatDate(_vm.message.o_creationDate))
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              [
                _vm._v(
                  "\n                    Ontvangers:\n                    "
                ),
                _vm._l(_vm.message.toUser, function (receiver) {
                  return _vm.message.toUser
                    ? _c("span", { staticClass: "receiver" }, [
                        _c(
                          "a",
                          { attrs: { href: _vm.toProfile(receiver.slug) } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(receiver.firstname) +
                                " " +
                                _vm._s(receiver.lastname) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ])
                    : _c("span", [_vm._v("Nog geen ontvangers geselecteerd.")])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.message.attachments.length
              ? _c("div", [
                  _c("span", [_vm._v("Bijlagen:")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.message.attachments, function (file) {
                      return _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.download(file)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.showFilename(file)))]
                        ),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "message-body" },
            [
              _c("nl2br", {
                attrs: { tag: "p", text: _vm.message.messageBody },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "button button--xs",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.reply.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Beantwoorden")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "delete-icon-mobile" },
            [
              _c("icon", {
                attrs: { icon: "trash-alt" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.deleteMessage.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }