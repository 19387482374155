var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hide
    ? _c(
        "div",
        { staticClass: "pagination" },
        [
          _vm.showToFirst
            ? _c(
                "ul",
                { staticClass: "pagination b-pagination pagination-md" },
                [
                  _c(
                    "li",
                    {
                      staticClass: "page-item",
                      class: _vm.firstPageClasses,
                      attrs: {
                        role: "none presentation",
                        "aria-hidden": "true",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.changedPage(1)
                            },
                          },
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("«"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.total_items,
              "per-page": _vm.items_per_page,
              "hide-ellipsis": true,
              "current-page": _vm.current_page_number,
              "hide-goto-end-buttons": true,
            },
            on: { input: _vm.changedPage },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }