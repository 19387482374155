<template>
    <modal>
        <div>
            <div>
                <p v-html="remoteContentWarning"></p>
                <input type="checkbox"
                       id="dontShowAgain"
                       :checked="selected"
                       @change="selected = !selected">
                <label :for="id">Deze melding niet meer weergeven</label>
            </div>
            <div>
                <button class="btn btn-xs" @click="cancel">Op deze pagina blijven</button>
                <button class="btn btn-xs btn-default" @click="confirm">Verder</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import { remoteContentWarning } from './api';

    export default {
        name: 'modal-redirect',
        props: {
            url: {
                type: String,
                required: true,
            },
            remoteContentWarning: {
                type: String,
                required: true,
            },
            tab: {
              type: String,
              default: '_self',
            },
        },
        data: () => ({
            selected: false,
            id: 'dontShowAgain',
        }),
        methods: {
            confirm() {
                if (this.selected) {
                    remoteContentWarning(this.selected).then((response) => {
                        window.open(this.url, this.tab);
                    });
                } else {
                    window.open(this.url, this.tab);
                }
            },
            cancel() {
                this.$emit('close');
            },
        }
    }
</script>
