var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    [
      _c("p", [
        _vm._v(
          '\n            Bent u zeker dat u "' +
            _vm._s(_vm.file.name) +
            '" wil verwijderen?\n            Indien ja, vul hieronder de bestandsnaam in als bevestiging.\n        '
        ),
      ]),
      _vm._v(" "),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c("ValidationProvider", {
                  attrs: { rules: { is: _vm.file.name, required: {} } },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.confirmationName,
                                  expression: "confirmationName",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.confirmationName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.confirmationName = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "mb-3 d-block form-errors" },
                              [_vm._v(_vm._s(errors[0]))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-xs btn-default mr-2",
                      attrs: { disabled: invalid },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("Bevestigen")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-xs button--neutral",
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Annuleren")]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }