<template>
  <div>
    <!-- The hocus-pocus wysiwyg which returns html output -->
    <ckeditor :editor="editor"
              v-model="editorDataValue"
              :config="editorConfig"
              @ready="onReady"
    ></ckeditor>

    <!-- The form's content containing field that will be submitted -->
    <textarea class="hidden-input"
              :id="widgetAttributeValues.id"
              :name="widgetAttributeValues.name"
              :required="widgetAttributeValues.required"
    >
      {{ editorDataValue }}
    </textarea>

    <div :id="widgetAttributeValues.id + '__word-count'"></div>
  </div>
</template>

<script>
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default {
  name: 'CustomCkeditor',
  props: {
    editorData: {
      required: false,
      default: '',
    },
    widgetAttributes: {
      required: false,
      default: '',
    }
  },
  created() {
    this.widgetAttributeValues = this.$attrs
    this.editorDataValue = this.editorData;
  },
  data() {
    return {
      editor: Editor,
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|',
            'bold', 'italic', 'underline', '|',
            'link', '|',
            'bulletedList', 'numberedList', '|',
            'undo', 'redo'
          ],
          shouldNotGroupWhenFull: true
        },
        wordCount: {
          displayWords: false,
          displayCharacters: false,
          onUpdate: stats => {
            const maxCharacters = this.widgetAttributeValues['maxlength'] ?? 500;
            const isLimitExceeded = stats.characters > maxCharacters;
            const characterBox = document.getElementById(this.widgetAttributeValues['id']+'__word-count');

            if ( isLimitExceeded ) {
              characterBox.innerHTML = `<span class="small error">${ stats.characters }/${ maxCharacters }</span>`;
            } else {
              characterBox.innerHTML = `<span class="small">${ stats.characters }/${ maxCharacters }</span>`;
            }
          },
        }
      },
      wordCountConfig: {
        showWordCount: true,
        container: document.getElementById('word-count'),
      },
      editorDataValue: '',
      widgetAttributeValues: {},
    };
  },
  methods: {
    onReady(editor) {
      const wordCountPlugin = editor.plugins.get('WordCount');
      const wordCountWrapper = document.getElementById(this.widgetAttributeValues['id']+'__word-count');

      wordCountWrapper.appendChild(wordCountPlugin.wordCountContainer);
    },
  }
}
</script>
