import axios from 'axios';

export function toggleUserPublished(userId, status) {
    return axios.post(Routing.generate('ajax_user_management_published_toggle', {
        userId,
        status,
    }));
}

export function toggleUserManager(userId, status) {
    return axios.post(Routing.generate('ajax_user_management_manager_toggle', {
        userId,
        status,
    }));
}

export function getWhitelistSubscriptions(userId) {
    return axios.get(Routing.generate('ajax_user_management_subscriptions', { userId }));
}

export function editUserSubscriptions(userId, newUserSubscriptions) {
    return axios.post(Routing.generate('ajax_user_management_subscriptions_edit', { userId }), { subscriptions: newUserSubscriptions });
}

export function inviteUser(groupId, user) {
    return axios.post(Routing.generate('ajax_group_invite', { groupId }), { user });
}

export function inviteUsersInBulk(groupId, formData) {
    return axios.post(Routing.generate('ajax_group_bulk_invite', { groupId }), formData);
}
