var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-element" }, [
    _c("div", [
      _vm.value.length || _vm.uploading.length
        ? _c("div", [
            _c("label", { attrs: { for: "attachments" } }, [
              _vm._v("Bijlagen: "),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { attrs: { id: "attachments" } },
              [
                _vm._l(_vm.value, function (file) {
                  return _c(
                    "li",
                    [
                      _vm._v(_vm._s(_vm.showFilename(file.metadata)) + " "),
                      _c("icon", {
                        attrs: { icon: "trash-alt" },
                        on: {
                          click: function ($event) {
                            return _vm.removeAsset(file)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm._l(_vm.uploading, function (file) {
                  return _c("li", [
                    _vm._v("⟳ " + _vm._s(file.name) + " is aan het uploaden"),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errors.length
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _c(
              "ul",
              { staticClass: "m-0" },
              _vm._l(_vm.errors, function (error) {
                return _c("li", [_vm._v(_vm._s(error))])
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uploader mb-3 mt-3" }, [
        _c(
          "label",
          { staticClass: "button button--xs", attrs: { for: "message-files" } },
          [
            _vm._v(
              "\n                    Bijlagen uploaden\n                    "
            ),
            _c("input", {
              ref: "input",
              staticStyle: { opacity: "0" },
              attrs: {
                type: "file",
                name: "files",
                id: "message-files",
                accept:
                  "image/*,video/*,.pdf,.txt,.rtf,.doc,.docx,.csv,.csvx,.ppt,.pptx,.xls,.xlsx",
                multiple: "",
              },
              on: { input: _vm.uploadAssets },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }