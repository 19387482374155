<template>
    <div class="pagination" v-if="hide">
        <ul v-if="showToFirst" class="pagination b-pagination pagination-md">
            <li role="none presentation" aria-hidden="true" class="page-item"
                :class="firstPageClasses">
                <a href="#" class="page-link" @click="changedPage(1)">
                    <span aria-hidden="true">«</span>
                </a>
            </li>
        </ul>

        <b-pagination
                :total-rows="total_items"
                :per-page="items_per_page"
                :hide-ellipsis=true
                :current-page="current_page_number"
                @input="changedPage"
                :hide-goto-end-buttons="true"
        ></b-pagination>
    </div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: {
            current_page_number: {
                type: Number,
                default: 1,
            },
            total_items: {
                type: Number,
                required: true,
            },
            items_per_page: {
                type: Number,
                required: true,
            },
            showToFirst: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            hide() {
                return this.total_items > this.items_per_page;
            },
            firstPageClasses() {
                return {
                    disabled: this.current_page_number == 1
                }
            },
        },
        methods: {
            changedPage(number) {
                if(number) {
                    this.$emit('changedPage', number);
                }
            },
        }
    }
</script>
