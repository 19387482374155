var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.term,
              expression: "term",
            },
          ],
          attrs: {
            name: "query",
            autocomplete: "off",
            type: "text",
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
          },
          domProps: { value: _vm.term },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.term = $event.target.value
              },
              _vm.suggest,
            ],
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                return _vm.onArrowDown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                return _vm.onArrowUp.apply(null, arguments)
              },
            ],
            focus: _vm.open,
          },
        }),
        _vm._v(" "),
        _vm.showSubmit
          ? _c(
              "button",
              {
                attrs: { type: "submit" },
                on: {
                  submit: _vm.onSubmit,
                  mouseenter: function ($event) {
                    _vm.arrowCounter = -1
                  },
                },
              },
              [_vm._v("Zoeken")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "autocomplete-wrapper" }, [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
              ],
              staticClass: "autocomplete-results",
              attrs: { id: "autocomplete-results" },
            },
            [
              _vm.isLoading
                ? _c("li", { staticClass: "autocomplete-result" }, [
                    _vm._v(
                      "\n                        ⟳ Suggesties aan het zoeken...\n                    "
                    ),
                  ])
                : _vm._l(_vm.results, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "autocomplete-result",
                        class: { "is-active": i === _vm.arrowCounter },
                        on: {
                          click: _vm.onSubmit,
                          mouseenter: function ($event) {
                            _vm.arrowCounter = i
                          },
                        },
                      },
                      [
                        _vm._t(
                          "default",
                          function () {
                            return [
                              _c("span", {
                                domProps: { innerHTML: _vm._s(item.title) },
                              }),
                            ]
                          },
                          { item: item }
                        ),
                      ],
                      2
                    )
                  }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }