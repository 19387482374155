var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", [
    _c("h5", [_vm._v("Gebruiker uitnodigen")]),
    _vm._v(" "),
    _c("div", { staticClass: "row my-2" }, [
      _c("div", { staticClass: "col-12 d-flex flex-column mb-3" }, [
        _c("label", { attrs: { for: "userFormEmail" } }, [
          _vm._v("E-mailadres"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.email,
                expression: "user.email",
              },
            ],
            staticClass: "form-control w-100",
            attrs: {
              type: "text",
              id: "userFormEmail",
              placeholder: "E-mailadres",
            },
            domProps: { value: _vm.user.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.user, "email", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 d-flex flex-column mb-3" }, [
        _c("label", { attrs: { for: "userFormFirstname" } }, [
          _vm._v("Voornaam"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.firstname,
                expression: "user.firstname",
              },
            ],
            staticClass: "form-control w-100",
            attrs: {
              type: "text",
              id: "userFormFirstname",
              placeholder: "Voornaam",
            },
            domProps: { value: _vm.user.firstname },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.user, "firstname", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 d-flex flex-column mb-3" }, [
        _c("label", { attrs: { for: "userFormLastname" } }, [
          _vm._v("Familienaam"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.lastname,
                expression: "user.lastname",
              },
            ],
            staticClass: "form-control w-100",
            attrs: {
              type: "text",
              id: "userFormLastname",
              placeholder: "Familienaam",
            },
            domProps: { value: _vm.user.lastname },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.user, "lastname", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          staticClass: "btn btn-xs btn-default mr-1 mb-1",
          on: { click: _vm.confirm },
        },
        [_vm._v("Uitnodigen")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-xs button--neutral",
          on: { click: _vm.cancel },
        },
        [_vm._v("Annuleren")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }