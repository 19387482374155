<template>
    <div>
        <div class="info-card-wrapper" ref="scroller">
            <message-info-card v-for="message in messages"
                               :message="message"
                               @open="showDetails"
                               :key="message.o_id"
            ></message-info-card>
        </div>
        <div v-show="!fetching && messages.length === 0">
            <div>
                <p class="no-messages">Je hebt geen berichten om weer te geven</p>
            </div>
        </div>
        <div v-show="fetching">
            <div>
                <p class="no-messages">⟳ Berichten aan het laden</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {markAsRead, fetchMessages, deleteMessage} from './api';
    import MessageInfoCard from './message-card';
    import MessageDetail from './message-detail.vue';
    import {DRAFT_CREATED, DRAFT_UPDATED, MESSAGE_SENT, PAGE_CHANGED, MESSAGE_DELETED, DELETE_MESSAGE} from "./index";

    export default {
        name: 'messageLoader',
        components:{
            MessageInfoCard,
            MessageDetail
        },
        props: {
            value: {
                type: Object,
                required: true,
            },
            tab: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            messages: [],
            nextPage: 1,
            totalPages: false,
            fetching: false,
        }),
        mounted(){
            this.$refs.scroller.addEventListener('scroll', this.scroll);
        },
        created() {
            this.fetch();

            // educational comment and explanation of bad design:
            // both of these events seem like a clean solution,
            // but they are in fact a consequence of "bad design" (can't refactor due to time pressure)
            // sending a message, saving a new draft or deleting a message all imply a
            // needed change in this component, as they alter the "items on a certain page"
            // if we used the vuex store, we would not need this custom logic
            // and everything would be SOLID into the store itself
            // this component would auto-update without potential bugs
            // but now we need to do it manually and fire events in the proper places
            // this "communication between the loader and the detail/create" is wrong
            // if you need to communicate between components -> you need the store!
            // every piece of code of "this.$when" and "this.$fire" would be solved in a better way
            this.$when(DRAFT_CREATED, (message) => {
                this.messages.unshift(message);
                
                if (this.messages.length >= 14) {
                    this.messages.pop();
                }
            });

            this.$when(DRAFT_UPDATED, (message) => {
                const index = this.messages.findIndex(msg => msg.o_id === message.o_id);
                this.messages.splice(index, 1, message);
            });

            this.$when(MESSAGE_SENT, ({id, lastMessage }) => {
                this.messages = this.messages.filter(item => item.o_id !== id);

                if (lastMessage && !this.messages.find(item => item.o_id === lastMessage.o_id)) {
                    this.messages.push(lastMessage);
                }
            });

            this.$when(MESSAGE_DELETED, ({id, lastMessage, type }) => {
                let foundMessage = this.messages.find(item => item.o_id === lastMessage.o_id);
                if (lastMessage && type === this.type && !foundMessage) {
                    this.messages.push(lastMessage);
                }

                this.messages = this.messages.filter(item => item.o_id !== id);

                const overview = this.$route.name.replace('messages-detail-', 'messages-');
                this.$router.push({
                    name: overview,
                });
            });

            this.$when(DELETE_MESSAGE, (message) => {
                deleteMessage({
                    id: message.o_id,
                    lastPage: this.currentPage,
                    type: message.messageType,
                }).then(({ data }) => {
                    this.$fire(MESSAGE_DELETED, {
                        id: message.o_id,
                        lastMessage: data,
                        type: message.messageType,
                    });
                });
            });
        },
        computed: {
            type() {
                return this.tab.type;
            },
            currentPage() {
                return this.nextPage - 1;
            }
        },
        methods: {
            scroll() {
                if(this.shouldLoadNextPage()) {
                    this.fetch();
                }
            },
            shouldLoadNextPage() {
                const dom = this.$refs.scroller;
                if (dom.childNodes.length === 0) {
                    return false;
                }

                const itemHeight = dom.childNodes[0].clientHeight;
                return dom.clientHeight + dom.scrollTop >= dom.scrollHeight - itemHeight;
            },
            fetch(force = false) {
                const requestedTab = this.type;
                if((force || !this.fetching) && (this.totalPages === false || this.nextPage <= this.totalPages )) {
                    this.fetching = true;
                    fetchMessages({
                        type: this.type,
                        page: this.nextPage,
                    }).then(({ data }) => {
                        // only perform the callback when the requested tab is still the current tab
                        // because if we change tabs really quickly, our second load
                        // might be triggered before the first load was done
                        // and we would end up with an array of messages containing:
                        // - messages for load one (previous tab)
                        // - messages for load two (last clicked tab)
                        if(requestedTab === this.type){
                            this.messages = this.messages.concat(data.messages);
                            this.$emit('input', data.stats);
                            this.fetching = false;
                            this.$fire(PAGE_CHANGED, this.nextPage);
                            this.nextPage += 1;
                            this.totalPages = data.totalPages;
                            // wait for updated dom so dimensions are updated
                            // basically if there is no scrollbar, we will load items
                            // until a scrollbar appears.
                            // this will happen when the window is high enough and the
                            // amount of items per page does not fill the scroll container
                            this.$nextTick(() => {
                                if(this.$refs.scroller.scrollHeight === this.$refs.scroller.clientHeight) {
                                    this.fetch();
                                }
                            });
                        }
                    });
                }
            },
            showDetails(message) {
                if (this.type === 'inbox' && (message.isRead === false || message.isRead === null)) {
                    markAsRead({
                        id: message.o_id
                    }).then(({ data }) => {
                        message.isRead = true;
                        this.$emit('input', data);
                    });
                }

                this.$router.push({
                    name: `messages-detail-${this.tab.type}`,
                    params:{
                        id: message.o_id,
                    }
                });
            },
        },
        watch: {
            type() {
                this.nextPage = 1;
                this.totalPages = false;
                this.messages = [];
                this.$refs.scroller.scrollTop = 0;
                // whenever we change tabs we want to make sure we load that content
                this.fetch(true);
            },
        }
    }

</script>
