<template>
    <div class="vue-modal-positioner">
        <div class="vue-modal-overlay"></div>

        <div class="vue-modal-inner">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'modal',
    }
</script>
