<template>
    <modal-redirect
            v-show="showModalRedirect"
            :url="url"
            @close="showModalRedirect = false"
            :remoteContentWarning="remoteContentWarning"
    ></modal-redirect>
</template>

<script>
    import { remoteContentWarning } from './api';

    export default {
        name: 'modal-knowledge-item-redirect',
        props: {
            url: {
                type: String,
                required: true,
            },
            remoteContentWarning: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            selected: false,
            showModalRedirect: true,
        }),
        methods: {
            confirm() {
                if (this.selected) {
                    remoteContentWarning(this.selected).then((response) => {
                        window.location.href = this.url;
                    });
                } else {
                    window.location.href = this.url;
                }
            },
            cancel() {
                this.showModalRedirect = false;
            },
        }
    }
</script>
