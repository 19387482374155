var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("autocomplete", {
    attrs: {
      request: _vm.request,
      "show-submit": false,
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
    },
    on: { submit: _vm.submit },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ item }) {
          return [
            _c("img", { attrs: { src: item.avatar, alt: "" } }),
            _vm._v(" "),
            _c("div", [
              _c("span", { domProps: { innerHTML: _vm._s(item.title) } }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("i", { domProps: { innerHTML: _vm._s(item.function) } }),
              _vm._v(" "),
              _c("i", { domProps: { innerHTML: _vm._s(item.company) } }),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }