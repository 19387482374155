<template>
    <span class="badge badge-dark mr-2 mb-1">
        <input :id="'tag-edit-' + tag.id" v-if="isEditMode && isEditable" v-model="newName"
               @blur="updateTag"
               @keyup.enter="updateTag"
               @keyup.escape="reset">
        <label @click="click(tag)" class="px-2 py-1 mb-0 clickable" v-else> {{ tag.name }} </label>
        <icon v-if="isRemovable" class="clickable" icon="times" @click="deleteTag(tag)"></icon>
    </span>
</template>

<script>
    export default {
        name: 'GroupTag',
        props: {
            tag: {
                type: Object,
                required: true,
            },
            isEditable: {
                type: Boolean,
                required: true,
            },
            isRemovable: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                isEditMode: false,
                newName: this.tag.name
            }
        },
        methods: {
            deleteTag(tag) {
                this.$emit('deleteTag', tag);
            },
            updateTag() {
                this.isEditMode = false;
                if (this.newName !== this.tag.name) {
                    this.tag.name = this.newName;
                    this.$emit('updateTag', this.tag);
                }
            },
            reset() {
                this.isEditMode = false;
                this.newName = this.tag.name;
            },
            click(tag) {
                if (this.isEditable) {
                    this.isEditMode = true;
                    // this is needed because the DOM would not be able to find the input field since the DOM would not be up to date yet.
                    this.$nextTick(function () {
                        const idToSelect = 'tag-edit-' + this.tag.id;
                        document.getElementById(idToSelect).focus();
                    });
                }

                this.$emit('click', tag);
            }
        }
    }
</script>
