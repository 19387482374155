import axios from 'axios';

export function getGroupMeeting(id) {
    return axios.get(Routing.generate('ajax_group_meeting', { meetingId: id }));
}

export function destroyMeeting(meetingId) {
    return axios.delete(Routing.generate('ajax_group_meeting_destroy', { meetingId }));
}

export function getGroup(groupId, currentPage, itemsPerPage) {
    return axios.get(Routing.generate('ajax_group', { groupId, currentPage, itemsPerPage }));
}

export function removeMember(groupId, memberId) {
    return axios.delete(Routing.generate('ajax_group_member_delete', { groupId, memberId }));
}

export function downloadGroupFile(groupId, id) {
    return Routing.generate('group_download_asset', { groupId, id });
}

export function destroyConversationFile(groupId, postId, attachmentId) {
    return axios.delete(Routing.generate('ajax_group_conversation_file_destroy', { groupId, postId, attachmentId }));
}

export function getGroupTags(groupId) {
    return axios.get(Routing.generate('ajax_group_tag', { groupId }));
}

export function createNewTag(payload) {
    return axios.post(Routing.generate('ajax_group_tag_create', payload));
}

export function deleteTag(payload) {
    return axios.post(Routing.generate('ajax_group_tag_delete', payload));
}

export function updateTag(payload) {
    return axios.post(Routing.generate('ajax_group_tag_edit', payload));
}

export function toggleGroupUserModerator(groupId, userId, isModerator) {
    return axios.post(Routing.generate('ajax_group_toggle_moderator', { groupId, userId }), { isModerator });
}
