import ClimateProjectList from './climate-project-list.vue';
import ClimateProjectCard from './climate-project-card.vue';
import ClimateProjectFilters from './climate-project-filters.vue';
import ClimateProjectMap from './climate-project-map.vue';

export default function (Vue) {
    Vue.component('ClimateProjectList', ClimateProjectList);
    Vue.component('ClimateProjectCard', ClimateProjectCard);
    Vue.component('ClimateProjectFilters', ClimateProjectFilters);
    Vue.component('ClimateProjectMap', ClimateProjectMap);
}
