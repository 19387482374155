<template>
    <div>
        <div class="message-tabs">
            <div class="row no-gutters">
                <div v-for="tab in tabs" class="col">
                    <div class="message-tab">
                        <router-link v-if="!tab.route" :to="route(tab)" :active-class="'active'">
                            <icon :icon="tab.icon"></icon><span class="d-none d-md-inline">{{ tab.name }}</span><span v-if="tab.count">({{ tab.count }})</span>
                        </router-link>
                        <a v-else @click="$router.push(route(tab))">
                            <icon :icon="tab.icon"></icon><span class="d-none d-md-inline">{{ tab.name }}</span><span v-if="tab.count">({{ tab.count }})</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'tabs',
        props: {
            tabs: {
                required: true,
                type: Array,
            },
        },
        methods: {
            route(tab){
                if(tab.route){
                    return tab.route;
                }

                return {
                    name: `messages-${tab.type}`
                };
            },
        }
    }
</script>
