var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "gmap-map",
        { attrs: { center: _vm.center, zoom: _vm.zoom } },
        [
          _vm._l(_vm.markers, function (marker, index) {
            return _c("gmap-marker", {
              key: index,
              attrs: {
                position: marker.position,
                clickable: true,
                draggable: false,
              },
              on: {
                click: function ($event) {
                  return _vm.markerClicked(marker)
                },
                mouseover: function ($event) {
                  return _vm.markerHover(marker)
                },
                mouseout: _vm.markerLeave,
              },
            })
          }),
          _vm._v(" "),
          _c(
            "gmap-info-window",
            {
              attrs: {
                options: _vm.infoOptions,
                position: _vm.infoWindowPos,
                opened: _vm.infoWinOpen,
              },
              on: {
                closeclick: function ($event) {
                  _vm.infoWinOpen = false
                },
              },
            },
            [_c("div", { domProps: { innerHTML: _vm._s(_vm.infoContent) } })]
          ),
          _vm._v(" "),
          _c(
            "gmap-info-window",
            {
              attrs: {
                options: _vm.infoOptions,
                position: _vm.hoverWindowPos,
                opened: _vm.hoverWinOpen && _vm.infoWinOpen === false,
              },
              on: {
                closeclick: function ($event) {
                  _vm.hoverWinOpen = false
                },
              },
            },
            [_c("div", { domProps: { innerHTML: _vm._s(_vm.hoverContent) } })]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }