var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c("div", { staticClass: "checkbox" }, [
      _c("div", [
        _c("input", {
          staticClass: "search-checkbox",
          attrs: { type: "checkbox", id: _vm.id },
          domProps: { checked: _vm.checked },
          on: {
            change: function ($event) {
              return _vm.$emit("change", $event.target.checked)
            },
          },
        }),
        _vm._v(" "),
        _c("i"),
      ]),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "search-category", attrs: { for: _vm.id } },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }