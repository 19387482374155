<template>
    <div class="flash__message" :class="flashStatus">
        {{ message }}
    </div>
</template>

<script>
    export default {
        name: 'flashmessage',
        props: {
            type: {
                type: String,
            },
            message: {
                type: String,
                default: '',
            },
        },
        mounted() {
            $('.flash__message').animate({
                top: 0,
            }, 500).delay(2000).animate({
                top: -300,
            }, 500);
        },
        computed: {
            flashStatus() {
                const types = ['success', 'error', 'warning', 'info'];

                if (types.includes(this.type)) {
                    return `flash--${this.type}`;
                }

                return ''
            }
        }
    }
</script>