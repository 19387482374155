var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.feedback
        ? _c("flashmessage", {
            attrs: { type: _vm.flashType, message: _vm.feedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-lg-3" },
        [
          _c("climate-project-filters", {
            attrs: { value: _vm.filters },
            on: { input: _vm.filterChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-9" }, [
        _c(
          "div",
          { staticClass: "w-100 text-center font-weight-bold mb-5" },
          [
            _c("climate-project-map", {
              attrs: {
                "climate-projects": _vm.climateProjects,
                "selected-climate-project": _vm.selectedClimateProject,
              },
              on: {
                select: function ($event) {
                  _vm.selectedClimateProject = $event
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "p",
              { staticClass: "text-right" },
              [
                _vm.climateProjects.length === 1
                  ? [
                      _vm._v(
                        _vm._s(_vm.climateProjects.length) + " project gevonden"
                      ),
                    ]
                  : [
                      _vm._v(
                        _vm._s(_vm.climateProjects.length) +
                          " projecten gevonden"
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _vm._l(_vm.climateProjects, function (climateProject, index) {
              return !_vm.loading
                ? _c(
                    "div",
                    { ref: index, refInFor: true, staticClass: "mb-4" },
                    [
                      _c("climate-project-card", {
                        attrs: {
                          "climate-project": climateProject,
                          edit: false,
                          "is-selected":
                            climateProject.id === _vm.selectedClimateProject.id,
                        },
                        on: { click: _vm.cardClick },
                      }),
                    ],
                    1
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-center" },
                  [_c("spinner")],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }