import Messages from './messages.vue';
import MessageDetail from './message-detail.vue';
import MessageCreate from './message-create.vue';

export const MESSAGE_SENT = 'message_sent';
export const MESSAGE_DELETED = 'message_deleted';
export const DRAFT_CREATED = 'draft_created';
export const DRAFT_UPDATED = 'draft_updated';
export const PAGE_CHANGED = 'page_changed';
export const DELETE_MESSAGE = 'delete_message';

export default function (Vue, { routes }) {
    routes.push({
        name: 'messages-inbox',
        path: '/profiel/berichten/inbox',
        component: Messages,
        children: [
            {
                name: 'messages-detail-inbox',
                path: ':id',
                component: MessageDetail,
                props: true,
            },
        ],
        props: true,
    });

    routes.push({
        name: 'messages-sent',
        path: '/profiel/berichten/verzonden',
        component: Messages,
        children: [
            {
                name: 'messages-detail-sent',
                path: ':id',
                component: MessageDetail,
                props: true,
            },
        ],
        props: true,
    });

    routes.push({
        name: 'messages-draft',
        path: '/profiel/berichten/concepten',
        component: Messages,
        children: [
            {
                name: 'messages-detail-draft',
                path: ':id',
                component: MessageCreate,
                props: true,
            },
        ],
        props: true,
    });

    routes.push({
        name: 'messages-deleted',
        path: '/profiel/berichten/verwijderd',
        component: Messages,
        children: [
            {
                name: 'messages-detail-deleted',
                path: ':id',
                component: MessageDetail,
                props: true,
            },
        ],
        props: true,
    });
}
