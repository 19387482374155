import autocomplete from './autocomplete.vue';
import Modal from './modal.vue';
import DeleteModal from './delete-modal.vue';
import FlashMessage from './flashmessage.vue';
import MultiSelect from './multiselect.vue';
import Pagination from './pagination.vue';
import FileManagement from './file-management.vue';
import FileTags from './file-tags.vue';
import ImageUploader from './image-uploader.vue';
import FileUploadList from './file-upload-list.vue';
import Spinner from './spinner.vue';
import CustomCkeditor from './CustomCkeditor.vue';

export default function (Vue) {
    Vue.component('autocomplete', autocomplete);
    Vue.component('modal', Modal);
    Vue.component('flashmessage', FlashMessage);
    Vue.component('multiselect', MultiSelect);
    Vue.component('pagination', Pagination);
    Vue.component('FileManagement', FileManagement);
    Vue.component('FileTags', FileTags);
    Vue.component('DeleteModal', DeleteModal);
    Vue.component('ImageUploader', ImageUploader);
    Vue.component('FileUploadList', FileUploadList);
    Vue.component('Spinner', Spinner);
    Vue.component('CustomCkeditor', CustomCkeditor);
}
