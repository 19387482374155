var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.errored
      ? _c("div", [
          _c("span", { staticClass: "error-line" }, [
            _vm._v("Het bestand kon niet worden geüploaded"),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "climate-project__main-image-container" }, [
      _vm.hasImage
        ? _c(
            "div",
            { staticClass: "climate-project__main-image-label-container" },
            [
              _c("span", { staticClass: "climate-project__main-image-label" }, [
                _vm._v("Klik op de afbeelding om een andere te kiezen"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { on: { click: _vm.clear } },
                [_c("icon", { attrs: { icon: "times" } })],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "climage-project__main-image-functionality" }, [
        _c("input", {
          ref: "fileupload",
          staticClass: "hidden",
          class: _vm.classes.input,
          attrs: {
            type: "file",
            accept:
              ".webp, .svg, .png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .gif, .avif, .apng",
          },
          on: { change: _vm.change },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "climate-project__main-image-preview" }, [
          _vm.preview
            ? _c("img", { attrs: { src: _vm.preview, alt: "preview image" } })
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }