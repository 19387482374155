var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    { ref: "el", staticClass: "form-control mb-3", attrs: { multiple: "" } },
    _vm._l(_vm.options, function (option) {
      return _c(
        "option",
        { domProps: { value: option.id, selected: _vm.isSelected(option) } },
        [_vm._v(_vm._s(option.text))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }