import search from './search.vue';
import autocomplete from './search-suggest.vue';
import searchUsers from './search-users.vue';
import modalRedirect from './modal-redirect.vue';
import modalKnowledgeItemRedirect from './modal-knowledge-item-redirect.vue';

export default function (Vue) {
    Vue.component('search', search);
    Vue.component('search-suggest', autocomplete);
    Vue.component('search-users', searchUsers);
    Vue.component('modal-redirect', modalRedirect);
    Vue.component('modal-knowledge-item-redirect', modalKnowledgeItemRedirect);
}
