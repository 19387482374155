var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "control-label" }, [
        _vm._v("Toegewezen abonnementen"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("multiselect", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          staticClass: "btn btn-xs btn-default mr-2",
          on: { click: _vm.confirm },
        },
        [_vm._v("Bevestigen")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-xs button--neutral",
          on: { click: _vm.cancel },
        },
        [_vm._v("Annuleren")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }