<template>
    <div class="climate-project-overview__card">
        <div @click="clickCard" class="climate-project-overview__info">
            <figure v-html="climateProject.mainImage"></figure>

            <div>
                <span class="title">{{ climateProject.projectName || '-' }}</span>
                <span class="subtitle">Thema: {{ climateProject.themes || '-' }}</span>
                <span><span class="icon fa fa-map"></span>{{ climateProject.cities || '-' }}</span>
                <span><span class="icon fa fa-map-pin"></span>{{ climateProject.address || '-' }}</span>
                <div class="link">
                    <a v-if="edit" :href="route" @click.stop class="px-2">
                        Wijzigen
                        <span class="ml-2"><icon icon="pencil-alt"></icon></span>
                    </a>
                    <a v-if="!edit" :href="route" @click.stop class="px-2">
                        Bekijk meer
                    </a>
                </div>
            </div>

            <span v-if="!climateProject.isPublished" class="climate-project-overview-label">Nog niet ingediend</span>
            <span v-if="climateProject.isPublished && !climateProject.approved" class="climate-project-overview-label">Nog niet goedgekeurd</span>
        </div>

        <div v-if="isSelectedValue" v-html="climateProject.teaser" class="climate-project-overview__teaser"></div>
    </div>
</template>

<script>
export default {
    props: {
        climateProject: {
            type: Object,
            required: true,
        },
        edit: {
            type: Boolean,
            default: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            isSelectedValue: this.isSelected,
        }
    },

    watch: {
        isSelected() {
            this.isSelectedValue = this.isSelected;
        },
    },

    computed: {
        route() {
            if (true === this.edit) {
                return Routing.generate('climate_project_management_edit', { id: this.climateProject.id });
            }

            return Routing.generate('climate_project_show', { id: this.climateProject.id });
        },
    },

    methods: {
        clickCard() {
            this.isSelectedValue = !this.isSelectedValue;
            this.$emit('click', this.isSelectedValue ? this.climateProject : {});
        },
    }
}
</script>
