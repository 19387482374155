var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui container group-member-list" },
    [
      _vm.feedback
        ? _c("flashmessage", {
            attrs: { type: _vm.flashType, message: _vm.feedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c("div", { staticClass: "row mt-5 mb-4" }, [
            _c("h2", { staticClass: "col-12 col-md-3" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("vuetable", {
        ref: "vuetable",
        attrs: {
          "api-url": _vm.dataUrl,
          fields: _vm.fields,
          "query-params": _vm.makeQueryParams,
          "append-params": _vm.moreParams,
          "show-sort-icons": true,
          css: _vm.css.table,
          "pagination-path": "",
          "per-page": 10,
        },
        on: {
          "vuetable:pagination-data": _vm.onPaginationData,
          "vuetable:cell-clicked": _vm.onCellClick,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("vuetable-pagination", {
            ref: "pagination",
            attrs: { css: _vm.css.pagination, "on-each-side": 2 },
            on: { "vuetable-pagination:change-page": _vm.onChangePage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }