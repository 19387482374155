import MeetingMembers from './meeting-members.vue';
import MeetingModification from './meeting-modification.vue';
import MeetingDetails from './meeting-details.vue';
import GroupMembers from './group-members.vue';
import GroupFiles from './group-files.vue';
import ConversationAttachments from './conversation-attachments.vue';
import GroupTags from './group-tags.vue';
import GroupTag from './group-tag.vue';
import GroupMemberList from './group-member-list.vue';

export default function (Vue, { routes }) {
    Vue.component('MeetingMembers', MeetingMembers);
    Vue.component('MeetingModification', MeetingModification);
    Vue.component('MeetingDetails', MeetingDetails);
    Vue.component('GroupMembers', GroupMembers);
    Vue.component('GroupFiles', GroupFiles);
    Vue.component('ConversationAttachments', ConversationAttachments);
    Vue.component('GroupTags', GroupTags);
    Vue.component('GroupTag', GroupTag);
    Vue.component('GroupMemberList', GroupMemberList);

    routes.push({
        name: 'group-files',
        path: '/groepen/:group/bestandsbeheer/:folder?',
        component: GroupFiles,
        props: true,
    });
}
