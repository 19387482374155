<template>
    <div class="filter-bar ui basic segment grid mb-4">
        <div class="ui form">
            <div class="input-group">
                <input type="text" class="form-control" v-model="filterText" @keyup.enter="filter" placeholder="Zoeken naar ...">
                <div class="input-group-append">
                    <button class="ui primary button button--xs" type="button" @click="filter">Zoek</button>
                    <button class="ui button button--xs" type="button" @click="reset">Reset</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'user-management-filter',
        data () {
            return {
                filterText: '',
                sortOrder: {
                    field: 'name',
                    direction: 'asc',
                },
            }
        },
        methods: {
            filter () {
                this.$fire('filter-set', this.filterText);
            },
            reset () {
                this.filterText = '';
                this.$fire('filter-reset');
            }
        }
    }
</script>
