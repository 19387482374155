<template>
    <autocomplete
            :request="request"
            @submit="submit"
            :show-submit="false"
            :placeholder="placeholder"
            :disabled="disabled"
    >
        <template slot-scope="{ item }">
            <img :src="item.avatar" alt="">
            <div>
                <span v-html="item.title"></span>
            </div>
            <div>
                <i v-html="item.function"></i>
                <i v-html="item.company"></i>
            </div>
        </template>
    </autocomplete>
</template>

<script>
    import { suggestUser } from './api';

    export default {
        name: 'SearchUsers',
        props: {
            excludedIds: {
                type: Array,
                required: false,
                default() {
                    return [];
                }
            },
            placeholder: {
                type: String,
                required: false,
                default: '',
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                members: [],
            };
        },
        methods: {
            request(term) {
                return suggestUser({
                    term,
                    ids: this.excludedIds
                });
            },
            submit({ item }) {
                if (item) {
                    this.$emit('input', item);
                }
            },
        }
    }
</script>
