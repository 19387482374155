var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui container" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6" },
          [_c("user-management-filter")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("vuetable", {
        ref: "vuetable",
        attrs: {
          "api-url": _vm.whitelistMembersUrl,
          fields: _vm.fields,
          "query-params": _vm.makeQueryParams,
          "append-params": _vm.moreParams,
          "show-sort-icons": true,
          css: _vm.css.table,
          "pagination-path": "",
          "per-page": 10,
        },
        on: { "vuetable:pagination-data": _vm.onPaginationData },
        scopedSlots: _vm._u([
          {
            key: "verified",
            fn: function ({ rowData }) {
              return [
                rowData.is_verified
                  ? _c("icon", { attrs: { icon: "check" } })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "manager",
            fn: function ({ rowData }) {
              return [
                rowData.isModerator
                  ? _c("icon", { attrs: { icon: "check" } })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "action",
            fn: function ({ rowData }) {
              return [
                !rowData.isModerator || _vm.authenticatedUserId === rowData.id
                  ? [
                      rowData.published
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-xs btn-danger mr-1 mb-1 mb-xl-0",
                              on: {
                                click: function ($event) {
                                  return _vm.startActivationModal(rowData)
                                },
                              },
                            },
                            [_c("icon", { attrs: { icon: "eye" } })],
                            1
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-xs btn-danger mr-1 mb-1 mb-xl-0",
                              on: {
                                click: function ($event) {
                                  return _vm.startActivationModal(rowData)
                                },
                              },
                            },
                            [_c("icon", { attrs: { icon: "eye-slash" } })],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-xs mr-1 mb-1 mb-xl-0",
                          on: {
                            click: function ($event) {
                              return _vm.startSubscriptionModal(rowData)
                            },
                          },
                        },
                        [_c("icon", { attrs: { icon: "edit" } })],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-xs btn-primary mr-1 mb-1 mb-xl-0",
                    on: {
                      click: function ($event) {
                        return _vm.startManagingModal(rowData)
                      },
                    },
                  },
                  [_c("icon", { attrs: { icon: "crown" } })],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.toggling
        ? _c("toggle-user", {
            on: {
              close: function ($event) {
                _vm.toggling = false
              },
            },
            model: {
              value: _vm.activeUser,
              callback: function ($$v) {
                _vm.activeUser = $$v
              },
              expression: "activeUser",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.editing
        ? _c("edit-subscriptions", {
            on: {
              close: function ($event) {
                _vm.editing = false
              },
            },
            model: {
              value: _vm.activeUser,
              callback: function ($$v) {
                _vm.activeUser = $$v
              },
              expression: "activeUser",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.managing
        ? _c("manage-user", {
            on: {
              confirm: _vm.makeUserManager,
              close: function ($event) {
                _vm.managing = false
              },
            },
            model: {
              value: _vm.activeUser,
              callback: function ($$v) {
                _vm.activeUser = $$v
              },
              expression: "activeUser",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("vuetable-pagination", {
        ref: "pagination",
        attrs: { css: _vm.css.pagination, "on-each-side": 2 },
        on: { "vuetable-pagination:change-page": _vm.onChangePage },
      }),
      _vm._v(" "),
      _vm.error
        ? _c("flashmessage", { attrs: { type: "error", message: _vm.error } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }