export default {
    table: {
        tableClass: 'table table-striped table-bordered table-hovered',
        sortableIcon: 'fas fa-sort',
        ascendingIcon: 'fas fa-caret-up',
        descendingIcon: 'fas fa-caret-down',
    },

    pagination: {
        wrapperClass: 'pagination vuetable-pagination float-md-right',
        activeClass: 'active',
        disabledClass: 'disabled',
        pageClass: 'btn btn-xs mx-sm-1',
        linkClass: 'btn btn-xs px-2 px-sm-3 mx-sm-1',
        icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
        },
    },
};
