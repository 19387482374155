var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group__tagList" },
    [
      _vm.feedback
        ? _c("flashmessage", {
            attrs: { type: "error", message: _vm.feedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-6 mb-3 mt-3" }, [
          _c("strong", [_vm._v("Alle tags")]),
          _vm._v(" "),
          _c("p", [_vm._v("Klik op een tag om hem aan te passen")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "taglist" },
            _vm._l(_vm.allGroupTags, function (tag, key) {
              return _c("group-tag", {
                key: key,
                attrs: {
                  tag: tag,
                  "is-editable": true,
                  "edit-permission": "true",
                },
                on: { deleteTag: _vm.startDelete, updateTag: _vm.update },
              })
            }),
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6" }, [
          _c("strong", [_vm._v("Tag toevoegen")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-content-start flex-nowrap mb-2" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newTagName,
                    expression: "newTagName",
                  },
                ],
                staticClass: "form-control group__tag-list__input",
                attrs: {
                  type: "text",
                  placeholder: "Vul de naam van de nieuwe tag in",
                  "aria-label": "Tagname",
                  "aria-describedby": "basic-addon2",
                },
                domProps: { value: _vm.newTagName },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.createNewTag.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.newTagName = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button button--xs group__tag-list__button",
                  attrs: { type: "button" },
                  on: { click: _vm.createNewTag },
                },
                [_vm._v("Toevoegen")]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.showDelete
        ? _c("modal", [
            _c("p", [
              _vm._v(
                'Weet u zeker dat u de tag "' +
                  _vm._s(_vm.currentTag.name) +
                  '" '
              ),
              _c("strong", [_vm._v("permanent wilt verwijderen")]),
              _vm._v(
                "? Deze zal van alle bestanden in de groep verwijderd worden."
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-xs btn-danger text-white mr-1",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.destroy(_vm.currentTag)
                  },
                },
              },
              [_vm._v("Verwijder deze tag")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "button button--xs",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showDelete = false
                  },
                },
              },
              [_vm._v("Annuleren")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }