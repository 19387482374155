var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "climate-project-overview__filters" }, [
    _c("div", { staticClass: "form-wrapper" }, [
      _c("div", { staticClass: "search" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value.searchTerm,
              expression: "value.searchTerm",
            },
          ],
          attrs: { type: "text", placeholder: "Typ op te zoeken" },
          domProps: { value: _vm.value.searchTerm },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.value, "searchTerm", $event.target.value)
              },
              _vm.change,
            ],
          },
        }),
        _vm._v(" "),
        _c("span", [_c("icon", { attrs: { icon: "search" } })], 1),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "climate-project-overview__filters-button mt-3 mb-3 d-md-none",
        on: {
          click: function ($event) {
            _vm.showFilters = !_vm.showFilters
          },
        },
      },
      [
        _vm.showFilters ? _c("span", [_vm._v("Verberg filters")]) : _vm._e(),
        _vm._v(" "),
        !_vm.showFilters ? _c("span", [_vm._v("Toon filters")]) : _vm._e(),
        _vm._v(" "),
        _c("icon", { attrs: { icon: "sliders-h" } }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-md-block",
        class: _vm.showFilters ? "d-block" : "d-none",
      },
      [
        _c("hr"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-wrapper mt-3 d-md-block" },
          [
            _c("label", [_vm._v("Initiatiefnemer")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                options: _vm.value.initiators,
                placeholder: "Kies een initiatiefnemer",
                label: "text",
              },
              on: {
                input: function ($event) {
                  return _vm.selectChanged($event, "initiatorValue")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function ({ value, text }) {
                    return [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "white-space": "normal",
                            "line-height": "1",
                          },
                        },
                        [_vm._v(_vm._s(text))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-wrapper mt-3" },
          [
            _c("label", [_vm._v("Status")]),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                options: _vm.value.statuses,
                placeholder: "Kies een status",
                label: "text",
              },
              on: {
                input: function ($event) {
                  return _vm.selectChanged($event, "statusValue")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "form-wrapper mt-3" }, [
          _c(
            "div",
            { staticClass: "inline-checkbox" },
            [
              _c("label", [_vm._v("Type project")]),
              _vm._v(" "),
              _vm._l(_vm.value.themes, function (theme) {
                return _c(
                  "div",
                  { ref: theme.value, refInFor: true, staticClass: "chekbox" },
                  [
                    _c("input", {
                      attrs: { type: "checkbox", id: "theme_" + theme.value },
                      domProps: { checked: theme.checked },
                      on: {
                        input: function ($event) {
                          return _vm.checkboxChanged(theme)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "theme_" + theme.value } }, [
                      _vm._v(_vm._s(theme.label)),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "form-wrapper mt-3" }, [
          _c(
            "div",
            { staticClass: "inline-checkbox" },
            [
              _c("label", [_vm._v("Grootte van de gemeente")]),
              _vm._v(" "),
              _vm._l(_vm.value.citySizes, function (citySize) {
                return _c(
                  "div",
                  {
                    ref: citySize.value,
                    refInFor: true,
                    staticClass: "chekbox",
                  },
                  [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: "citySize_" + citySize.value,
                      },
                      domProps: { checked: citySize.checked },
                      on: {
                        input: function ($event) {
                          return _vm.checkboxChanged(citySize)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { for: "citySize_" + citySize.value } },
                      [_vm._v(_vm._s(citySize.label))]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "form-wrapper mt-3" }, [
          _c(
            "div",
            { staticClass: "inline-checkbox" },
            [
              _c("label", [_vm._v("Budget")]),
              _vm._v(" "),
              _vm._l(_vm.value.budgets, function (budget) {
                return _c(
                  "div",
                  { ref: budget.value, refInFor: true, staticClass: "chekbox" },
                  [
                    _c("input", {
                      attrs: { type: "checkbox", id: "budget_" + budget.value },
                      domProps: { checked: budget.checked },
                      on: {
                        input: function ($event) {
                          return _vm.checkboxChanged(budget)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "budget_" + budget.value } }, [
                      _vm._v(_vm._s(budget.label)),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "d-md-none" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }