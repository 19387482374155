<template>
    <div class="ui container group-member-list">
        <flashmessage
                :type="flashType"
                :message="feedback"
                v-if="feedback"
        ></flashmessage>

        <div v-if="title" class="row mt-5 mb-4">
            <h2 class="col-12 col-md-3">{{ title }}</h2>
        </div>

        <vuetable ref="vuetable"
                  :api-url="dataUrl"
                  :fields="fields"
                  :query-params="makeQueryParams"
                  :append-params="moreParams"
                  :show-sort-icons="true"
                  :css="css.table"
                  pagination-path=""
                  :per-page="10"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:cell-clicked="onCellClick"
        ></vuetable>

        <div class="mb-5">
            <vuetable-pagination ref="pagination"
                                 :css="css.pagination"
                                 :on-each-side="2"
                                 @vuetable-pagination:change-page="onChangePage"
            ></vuetable-pagination>
        </div>
    </div>
</template>

<script>
    import { Vuetable, VuetablePagination } from 'vuetable-2';
    import css from '../datatable/styling';

    export default {
        name: 'MeetingMembers',
        components: {
            Vuetable,
            VuetablePagination,
        },
        data() {
            return {
                feedback: false,
                dataUrl: Routing.generate('ajax_group_meeting_attending_status', { meetingId: this.meetingId }),
                fields: [
                    {
                        name: 'firstname',
                        sortField: 'firstname',
                        title: 'Voornaam'
                    },
                    {
                        name: 'lastname',
                        sortField: 'lastname',
                        title: 'Familienaam'
                    },
                    {
                        name: 'email',
                        sortField: 'email',
                        title: 'E-mail',
                    },
                ],
                css: css,
            };
        },
        props: {
            meetingId: {
                type: Number,
                required: true,
            },
            status: {
                type: String,
                required: true,
            },
            title: {
                type: String,
            },
        },
        computed: {
            moreParams() {
                return {
                    status: this.status,
                };
            },
        },
        methods: {
            makeQueryParams (sortOrder, currentPage, perPage) {
                return {
                    sort: sortOrder[0] ? sortOrder[0].sortField : '',
                    order: sortOrder[0] ? sortOrder[0].direction : '',
                    page: currentPage,
                    pageSize: perPage,
                }
            },
            onPaginationData (paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
            },
            onChangePage (page) {
                this.$refs.vuetable.changePage(page);
            },
            onCellClick(user) {
                window.location.href = Routing.generate('profile_show', { slug: user.slug });
            },
        },
        watch: {
            status: function () {
                this.$nextTick(function () {
                    this.$refs.vuetable.refresh();
                });
            },
        },
    }
</script>
