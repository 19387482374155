<template>
    <div class="message-detail">
        <div v-if="loading" class="no-messages">⟳ Bericht aan het laden</div>
        <div v-else-if="sent" class="no-messages">
            <p>Bericht verzonden.</p>
            <a class="button--xs button" href="#" @click.prevent="clearState()">
                <icon icon="plus"></icon>&nbsp;Nog een bericht versturen
            </a>
        </div>
        <div v-else-if="error" class="no-messages alert alert-danger">
            {{ error }}
        </div>
        <div v-else class="form-wrapper">
            <form v-on:submit.prevent>
                <user-select v-model="receivers" :disabled="receivers.length === recipientLimit"></user-select>

                <div class="form-element">
                    <label>Onderwerp *:</label>
                    <input type="text" v-model="subject">
                </div>

                <div class="form-element">
                    <label>Bericht *:</label>
                    <textarea name="" id="" cols="30" rows="10" v-model="message"></textarea>
                </div>

                <file-uploader v-model="files"
                               @uploading="uploading = true"
                               @finished="uploading = false"
                ></file-uploader>

                <div v-if="validationError" class="alert alert-danger">
                    {{ validationError }}
                </div>
                <p>
                    <input type="button" class="button--xs button" value="Verstuur" @click="sendMessage" :disabled="uploading">
                    <input type="button" class="button--xs button" value="Opslaan" @click="saveMessage" :disabled="uploading">
                </p>
            </form>

        </div>
    </div>
</template>
<script>
    import { sendMessage, saveMessage, fetchMessage } from './api';
    import UserSelect from './user-select.vue';
    import fileUploader from '../file-uploader/file-uploader.vue'
    import {DRAFT_CREATED, MESSAGE_SENT, PAGE_CHANGED, DRAFT_UPDATED} from "./index";
    import { format } from 'date-fns';
    const dutchLocale = require('date-fns/locale/nl');

    export default {
        name: 'message-create',
        components: {
            UserSelect,
            fileUploader,
        },
        props: {
            id: {
                required: true,
            }
        },
        data: () => ({
            // message related
            currentId: false,
            receivers: [],
            subject: '',
            message: '',
            files: [],
            // state related
            loading: false,
            error: false,
            validationError: false,
            sent: false,
            uploading: false,
            // hacky solution
            lastPage: 1,
            recipientLimit: null,
            attachmentLimit: null,
        }),
        mounted() {
            this.recipientLimit = parseInt(this.$parent.$el.attributes['data-recipients-limit'].value);
            this.attachmentLimit = parseInt(this.$parent.$el.attributes['data-attachments-limit'].value);
        },
        methods: {
            async fetch() {
                this.clearState();
                if(!Number.isNaN(Number.parseInt(this.currentId))) {
                    this.loading = true;
                    await fetchMessage({id: this.currentId }).then(({ data }) => {
                        this.setMessage(data);
                        this.loading = false;
                    }).catch((error) => {
                        this.loading = false;
                        this.error = error.response.data.message;
                    });
                } else {
                    this.clearMessage();
                }
            },
            saveMessage(){
                saveMessage({
                    id: this.currentId,
                    receivers: this.receivers,
                    subject: this.subject,
                    message: this.message,
                    assets: this.files.map(file => file.id),
                }).then(({data}) => {
                    if(this.currentId === 'nieuw') {
                        this.$fire(DRAFT_CREATED, {
                            o_id: data.id,
                            subject: this.subject,
                            fromUser: data.user,
                            o_creationDate: new Date().getTime() / 1000,
                        });
                    } else {
                        this.$fire(DRAFT_UPDATED, {
                            o_id: data.id,
                            subject: this.subject,
                            fromUser: data.user,
                            o_creationDate: new Date().getTime() / 1000,
                        })
                    }
                    this.currentId = data.id;
                });
            },
            sendMessage() {
                this.clearState();
                sendMessage({
                    id: this.currentId,
                    receivers: this.receivers,
                    subject: this.subject,
                    message: this.message,
                    assets: this.files.map(file => file.id),
                    lastPage: this.lastPage
                }).then(({ data }) => {
                    this.sent = true;

                    if (this.currentId !== 'nieuw') {
                        this.$fire(MESSAGE_SENT, {
                            id: this.currentId,
                            lastMessage: data,
                        });
                    }

                    this.clearMessage();
                }).catch((error) => {
                    if(error.response.status === 400){
                        this.validationError = 'Niet alle velden zijn goed ingevuld.';
                    } else {
                        this.error = 'Er liep iets fout.'
                    }
                });
            },
            clearMessage(){
                this.subject = '';
                this.message = '';
                this.receivers = [];
                this.files = [];
                this.currentId = this.id;
            },
            setMessage(data){
                this.subject = data.subject;
                this.message = data.messageBody;

                if(data.toUser){
                    this.receivers = data.toUser.map(receiver => ({
                        id: receiver.o_id,
                        title: `${receiver.firstname} ${receiver.lastname}`.trim(),
                    }));
                } else {
                    this.receivers = [];
                }

                if(data.attachments){
                    this.files = data.attachments
                } else {
                    this.files = [];
                }
            },
            clearState(){
                this.sent = false;
                this.loading = false;
                this.error = false;
                this.validationError = false;
            },
            getQueryVariable(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split('&');
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split('=');
                    if (decodeURIComponent(pair[0]) == variable) {
                        return decodeURIComponent(pair[1]);
                    }
                }
                return undefined;
            },
            formatDate(timestamp) {
                return format(new Date(timestamp*1000), 'dddd D MMMM YYYY, HH:mm', { locale: dutchLocale });
            },
        },
        created() {
            this.$when(PAGE_CHANGED, (page) => {
               this.lastPage = page;
            });

            this.currentId = this.id;
            this.fetch().then(() => {
                const receiver = this.getQueryVariable('receiver');

                if(receiver){
                    this.receivers.push(JSON.parse(atob(receiver)));
                }

                const { subject, body, to, date } = this.$route.params;
                const formattedDate = this.formatDate(date);
                if(subject && body && to) {
                    this.receivers.push({
                        id: to.o_id,
                        title: `${to.firstname} ${to.lastname}`.trim()
                    });
                    this.subject = `RE: ${subject}`;
                    this.message = `
-----------------------------------------------------------
Van: ${to.firstname} ${to.lastname}
Datum: ${formattedDate}
Bericht:
${body}`;
                }
            });
        },
        watch: {
            id() {
                this.currentId = this.id;
                this.fetch()
            }
        }
    }
</script>
