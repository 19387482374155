<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="float-none float-md-right mb-3">
                    <a class="button button--xs mr-2" :href="link">
                        <icon icon="edit" class="mr-2"></icon>
                        Aanpassen
                    </a>
                    <span class="btn btn-xs btn-danger" @click="remove">
                        <icon icon="trash-alt" class="mr-2"></icon>
                        Verwijderen
                    </span>
                </div>
            </div>
        </div>
        <modal v-if="visible">
            <div class="form-group">
                <p class="control-label">Bent u zeker dat u deze vergadering wilt verwijderen? Door op 'Bevestigen' te klikken, worden ook alle bestanden van deze vergadering verwijderd.</p>
            </div>

            <div>
                <button class="btn btn-xs btn-default mr-2" @click="confirm">Bevestigen</button>
                <button class="btn btn-xs button--neutral" @click="cancel">Annuleren</button>
            </div>
        </modal>
    </div>
</template>

<script>
    import { destroyMeeting } from "./api";

    export default {
        name: 'MeetingModification',
        data() {
            return {
                visible: false,
            }
        },
        props: {
            link: {
                type: String,
                required: true,
                default: '',
            },
            id: {
                type: Number,
                required: true,
            },
            slug: {
                type: String,
                required: true,
            },
            groupSlug: {
                type: String,
                required: true,
            }
        },
        methods: {
            confirm () {
                destroyMeeting(this.id).then(() => {
                    window.location.href = Routing.generate('group_show', { groupSlug: this.groupSlug });
                });
            },
            cancel() {
                this.visible = false;
            },
            remove() {
                this.visible = true;
            },
        }
    }
</script>
