var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", [
    _c("p", [
      _vm._v("Bent u zeker dat u deze gebruiker uit de groep wil verwijderen?"),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        { staticClass: "btn btn-xs btn-default", on: { click: _vm.confirm } },
        [_vm._v("Bevestigen")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-xs button--neutral",
          on: { click: _vm.cancel },
        },
        [_vm._v("Annuleren")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }