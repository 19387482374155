var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row no-gutters mt-5 mb-3" },
        [
          _c("file-management", {
            attrs: {
              id: _vm.id,
              "group-id": _vm.groupId,
              hasUpdateRights: _vm.isMeetingModerator,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "meeting-tabs row no-gutters mb-3" },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "div",
            {
              staticClass: "col-4",
              class: { active: _vm.activeTab === tab.name },
              on: {
                click: function ($event) {
                  _vm.activeTab = tab.name
                },
              },
            },
            [
              _c(
                "span",
                [
                  _c("icon", {
                    staticClass: "mr-2",
                    attrs: { icon: tab.icon },
                  }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(tab.name) +
                      "\n                "
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("meeting-members", {
        attrs: {
          "meeting-id": _vm.id,
          status: _vm.attendingStatus,
          title: _vm.activeTab,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }