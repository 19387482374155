// @flow
<template>
    <div class="message container">
        <tabs :tabs="tabs"></tabs>
        <div class="tab-content">
            <div class="row">
                <div class="col-md-3 d-md-block" :class="{'d-none' : isDetail }">
                    <messages-loader :tab="currentTab" v-model="stats"></messages-loader>
                </div>
                <div class="d-md-block col-md-9">
                    <router-view></router-view>
                </div>
                <div v-if="isDetail" class="col-md-12 d-md-none">
                    <router-link class="back" :to="back">Terug</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessagesLoader from './messages-loader.vue';
import Tabs from './tabs.vue';
import {DRAFT_CREATED} from "./index";

export default {
    name: 'messages',
    components: {
        Tabs,
        MessagesLoader,
    },
    created(){
        this.$when(DRAFT_CREATED, () => {
            this.stats.draft += 1;
        });
    },
    data: () => ({
        stats: {
            inbox: null,
            draft: null,
        }
    }),
    computed: {
        tab() {
            const name = this.$route.name;

            if(name.indexOf('messages-detail-') !== -1) {
                return name.replace('messages-detail-', '');
            }

            return name.replace('messages-', '');
        },
        isOverview(){
            return this.$route.name.indexOf('messages-detail-') === -1;
        },
        isDetail(){
            return !this.isOverview;
        },
        currentTab() {
            return this.tabs.filter( tab => tab.type === this.tab)[0];
        },
        back(){
            return {
                name: `messages-${this.currentTab.type}`,
            }
        },
        tabs() {
            return [
                {
                    type: 'inbox',
                    name: 'inbox',
                    count: this.stats['inbox'],
                    icon: 'inbox',
                },
                {
                    type: 'sent',
                    name: 'verzonden',
                    count: null,
                    icon: 'paper-plane',
                },
                {
                    type: 'draft',
                    name: 'concepten',
                    count: this.stats['draft'],
                    icon: 'file-alt',
                },
                {
                    type: 'deleted',
                    name: 'verwijderd',
                    count: null,
                    icon: 'trash-alt'
                },
                {
                    type: 'new',
                    name: 'nieuw',
                    count: null,
                    icon: 'plus',
                    route: {
                        name: 'messages-detail-draft',
                        params: {
                            id: 'nieuw',
                        }
                    }
                }
            ]
        },
    },
}

</script>
