var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "message-info-card",
      class: {
        unread: _vm.message.messageType === "inbox" && !_vm.message.isRead,
        active: _vm.selected,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("open", _vm.message)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "message-sender" },
        [
          _vm.message.fromUser
            ? [
                _vm._v(
                  _vm._s(_vm.message.fromUser.firstname) +
                    " " +
                    _vm._s(_vm.message.fromUser.lastname)
                ),
              ]
            : [_vm._v("<Nog geen ontvanger(s)>")],
        ],
        2
      ),
      _vm._v(" "),
      _vm.message.subject
        ? _c("div", { staticClass: "message-subject" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.message.subject) + "\n        "
            ),
          ])
        : _c("div", [_vm._v("<Nog geen onderwerp>")]),
      _vm._v(" "),
      _c("div", { staticClass: "message-date" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.formatDate(_vm.message.o_creationDate)) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "delete-icon" },
        [
          _c("icon", {
            attrs: { icon: "trash-alt" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.deleteMessage.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }