<template>
    <modal>
        <p v-if="!value.isModerator">
            Bent u zeker dat u deze gebruiker een beheerder wil maken?
        </p>

        <p v-else>
            Bent u zeker dat u de beheerder-rol voor deze gebruiker wil verwijderen?
        </p>

        <div>
            <button class="btn btn-xs btn-default" @click="confirm">Bevestigen</button>
            <button class="btn btn-xs button--neutral" @click="cancel">Annuleren</button>
        </div>

    </modal>
</template>

<script>
    export default {
        name: "manage-user",

        props: {
            value: {
                required: true,
                type: Object,
            }
        },

        methods: {
            confirm() {
                this.$emit('confirm');
            },

            cancel() {
                this.$emit('close');
            },
        }
    }
</script>
