// @flow
import axios from 'axios';

export function search(payload) {
    return axios.get(Routing.generate('ajax_search', payload));
}

export function saveSearch(payload) {
    return axios.post(Routing.generate('ajax_save_search', payload));
}

export function suggest(term) {
    return axios.get(Routing.generate('ajax_suggest', { term }));
}

export function suggestUser(payload) {
    return axios.get(Routing.generate('ajax_suggest_user', payload));
}

export function remoteContentWarning(hasDisabledRemoteContentWarning) {
    return axios.post(Routing.generate('ajax_remote_content_warning', { hasDisabledRemoteContentWarning }));
}
