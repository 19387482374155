<template>
    <modal>
        <p v-if="!value.published">
            Bent u zeker dat u deze gebruiker wilt activeren?
        </p>

        <p v-else>
            Bent u zeker dat u deze gebruiker wilt deactiveren?
        </p>

        <div>
            <button class="btn btn-xs btn-default" @click="confirm">Bevestigen</button>
            <button class="btn btn-xs button--neutral" @click="cancel">Annuleren</button>
        </div>

    </modal>
</template>

<script>
    import {toggleUserPublished} from "./api";

    export default {
        name: "toggle-user",

        props: {
            value: {
                required: true,
                type: Object,
            }
        },

        methods: {
            confirm() {
                toggleUserPublished(this.value.id, !this.value.published).then(({data}) => {
                    Object.keys(data).forEach((key) => {
                        this.value[key] = data[key];
                    });

                    this.$emit('input', this.value);
                    this.$emit('close');
                });
            },

            cancel() {
                this.$emit('close');
            },
        }
    }
</script>
