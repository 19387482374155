<template>
    <div>
        <!-- Note: it's necessary to set a height on .vue-map -->
        <gmap-map
            :center="center"
            :zoom="zoom"
        >
            <gmap-marker
                :key="index"
                v-for="(marker, index) in markers"
                :position="marker.position"
                :clickable="true"
                :draggable="false"
                @click="markerClicked(marker)"
                @mouseover="markerHover(marker)"
                @mouseout="markerLeave"
            ></gmap-marker>

            <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen=false"
            >
                <div v-html="infoContent"></div>
            </gmap-info-window>

            <gmap-info-window
                :options="infoOptions"
                :position="hoverWindowPos"
                :opened="hoverWinOpen && infoWinOpen === false"
                @closeclick="hoverWinOpen=false"
            >
                <div v-html="hoverContent"></div>
            </gmap-info-window>
        </gmap-map>
    </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';

export default {

    props: {
        climateProjects: {
            type: Array,
            required: true,
        },
        selectedClimateProject: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            markers: [],
            zoom: 8,
            defaultCenter: {lat: 50.996464364869006, lng: 4.274583251659164},
            center: {lat: 50.996464364869006, lng: 4.274583251659164},
            // Marker popup
            currentMidx: null,
            infoWinOpen: false,
            infoContent: '',
            infoWindowPos: {
                lat: 0,
                lng: 0
            },
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            hoverWindowPos: null,
            hoverContent: null,
            hoverWinOpen: null,
        }
    },

    computed: {
        google: gmapApi,
    },

    mounted() {
        this.$gmapApiPromiseLazy().then(() => {
            if (this.google) {
                this.centerMap({}, 8);
                this.climateProjects.forEach(cp => this.addMarker(cp));
            }
        });
    },

    watch: {
        climateProjects() {
            this.reset();
        },

        selectedClimateProject(newValue, oldValue) {
            if (JSON.stringify(newValue) === JSON.stringify({})) {
                this.clearView();
            }

            let selectedMarker = this.markers.filter(marker => marker.climateProject.id === newValue.id);
            if (selectedMarker.length <= 0) {
                return;
            }

            this.climateProjectSelected(selectedMarker[0]);
            this.centerMap(newValue, 12);
        },
    },

    methods: {
        reset() {
            this.markers = [];
            this.climateProjects.forEach(cp => this.addMarker(cp));

            this.clearView();
        },

        clearView() {
            this.zoom = 8;
            this.center = this.defaultCenter;
            this.infoWinOpen = false;
            this.infoContent = '';
            this.infoWindowPos = { lat: 0, lng: 0 };
        },

        centerMap(climateProject, zoom) {
            this.zoom = JSON.stringify(this.selectedClimateProject) === JSON.stringify({}) ? 8 : zoom;

            if (this.selectedClimateProject.lat !== undefined && this.selectedClimateProject.lng !== undefined) {
                this.center = {
                    lat: this.selectedClimateProject.lat,
                    lng: this.selectedClimateProject.lng
                };
            } else {
                this.center = this.defaultCenter;
            }
        },

        addMarker(climateProject) {
            this.markers.push({
                climateProject: climateProject,
                position: {
                    lat: climateProject.lat,
                    lng: climateProject.lng
                },
            });
        },

        markerClicked(marker) {
            if (this.selectedClimateProject && marker.climateProject && this.selectedClimateProject.id !== marker.climateProject.id) {
                // Notify upper component the selected climateProject has changed
                this.$emit('select', marker.climateProject);
            } else {
                // The same climateProject was clicked
                this.clearView();
                this.$emit('select', {});
            }
        },

        markerHover(marker) {
            this.hoverWindowPos = marker.position;
            this.hoverContent = this.getHoverContent( marker );
            this.hoverWinOpen = JSON.stringify(marker.climateProject) !== JSON.stringify({});
        },

        getHoverContent: function (marker) {
            return `
                <div class="popup-bubble">
                    <p class="m-0 mr-2">${marker.climateProject.projectName}</p>
                </div>
            `;
        },

        markerLeave() {
            this.hoverWindowPos = null;
            this.hoverContent = null;
            this.hoverWinOpen = null;
        },

        climateProjectSelected(marker) {
            // A climateProject was selected, we need to center the map & add the popup
            this.center = marker.position;
            this.infoWindowPos = marker.position;
            this.infoContent = this.getPopupContent(marker);
            this.infoWinOpen = JSON.stringify(marker.climateProject) !== JSON.stringify({});
        },

        getPopupContent: function (marker) {
            let address = marker.climateProject.address ? marker.climateProject.address.trim() : '';
            let addressHtml = '';
            let url = Routing.generate('climate_project_show', {id: marker.climateProject.id});
            if (!address) {
                address = marker.climateProject.cities.split(',')[0];
            }

            if (address) {
                addressHtml = '<i class="fa fa-location-arrow mr-2" aria-hidden="true"></i>' + address;
            }

            return `
                <div id="companyPopup" class="popup-bubble">
                    <div>
                        <span class="list-company__name">
                            <a href="${url}">
                                ${marker.climateProject.projectName}
                            </a>
                        </span>
                        <br>
                        <span class="list-company__address">${addressHtml}</span>
                        <br>
                    </div>
                </div>
            `;
        },
    }
}
</script>
