var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("autocomplete", {
    attrs: { request: _vm.suggest },
    on: { submit: _vm.navigate },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({
          item: { title, category, hasSubscriptions, provider },
        }) {
          return [
            _c("div", [
              hasSubscriptions
                ? _c(
                    "span",
                    { staticClass: "subscription__label" },
                    [_c("icon", { attrs: { icon: "plus-square" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { domProps: { innerHTML: _vm._s(title) } }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              _vm._l(category, function (name, key) {
                return _c("i", {
                  key: key,
                  domProps: { innerHTML: _vm._s(name) },
                })
              }),
              0
            ),
            _vm._v(" "),
            _vm.logoLink(provider)
              ? _c(
                  "div",
                  { staticClass: "float-right provider-image__container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "h-100 d-flex flex-column justify-content-center",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.logoLink(provider),
                            alt: "Provider image",
                            itemprop: "image",
                            itemtype: "https://schema.org/ImageObject",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }