var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "message-tabs" }, [
      _c(
        "div",
        { staticClass: "row no-gutters" },
        _vm._l(_vm.tabs, function (tab) {
          return _c("div", { staticClass: "col" }, [
            _c(
              "div",
              { staticClass: "message-tab" },
              [
                !tab.route
                  ? _c(
                      "router-link",
                      {
                        attrs: { to: _vm.route(tab), "active-class": "active" },
                      },
                      [
                        _c("icon", { attrs: { icon: tab.icon } }),
                        _c("span", { staticClass: "d-none d-md-inline" }, [
                          _vm._v(_vm._s(tab.name)),
                        ]),
                        tab.count
                          ? _c("span", [_vm._v("(" + _vm._s(tab.count) + ")")])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            _vm.$router.push(_vm.route(tab))
                          },
                        },
                      },
                      [
                        _c("icon", { attrs: { icon: tab.icon } }),
                        _c("span", { staticClass: "d-none d-md-inline" }, [
                          _vm._v(_vm._s(tab.name)),
                        ]),
                        tab.count
                          ? _c("span", [_vm._v("(" + _vm._s(tab.count) + ")")])
                          : _vm._e(),
                      ],
                      1
                    ),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }