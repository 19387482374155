<template>
    <modal>
        <div class="form-group">
            <label class="control-label">Toegewezen abonnementen</label>
            <div>
                <multiselect v-model="selected" :options="options"></multiselect>
            </div>
        </div>

        <div>
            <button class="btn btn-xs btn-default mr-2" @click="confirm">Bevestigen</button>
            <button class="btn btn-xs button--neutral" @click="cancel">Annuleren</button>
        </div>
    </modal>
</template>

<script>
    import { getWhitelistSubscriptions, editUserSubscriptions } from "./api";

    export default {
        name: "edit-subscriptions",
        props: {
            value: {
                required: true,
                type: Object,
            },
        },
        data() {
            return {
                options: [],
                selected: [],
            };
        },
        created() {
            this.fetch();
        },
        methods: {
            fetch () {
                getWhitelistSubscriptions(this.value.id).then((response) => {
                    if (response.data) {
                        this.options = response.data.whitelist_subscriptions || [];
                        this.selected = response.data.user_subscriptions || [];
                    }
                });
            },
            confirm () {
                editUserSubscriptions(this.value.id, this.selected).then(({ data }) => {
                    Object.keys(data).forEach((key) => {
                        this.value[key] = data[key];
                    });

                    this.$emit('input', this.value);
                    this.$emit('close');
                });
            },
            cancel() {
                this.$emit('close');
            },
        }
    }
</script>
