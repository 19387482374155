<template>
    <div class="message-detail">
        <div v-if="loading" class="no-messages">⟳ Bericht aan het laden</div>
        <div v-else-if="error" class="no-messages">{{ error }}</div>
        <div v-else class="message-wrapper">
            <div class="message-header">
                <p>Van: <a :href="toProfile(message.fromUser.slug)">{{ message.fromUser.firstname }} {{ message.fromUser.lastname }}</a></p>
                <p class="message-subject">Onderwerp: {{ message.subject }} </p>
                <span>Datum: {{ formatDate(message.o_creationDate) }}</span>
                <p>
                    Ontvangers:
                    <span v-if="message.toUser" class="receiver" v-for="receiver in message.toUser">
                        <a :href="toProfile(receiver.slug)">
                            {{ receiver.firstname }} {{ receiver.lastname }}
                        </a>
                    </span>
                    <span v-else>Nog geen ontvangers geselecteerd.</span>
                </p>
                <div v-if="message.attachments.length">
                    <span>Bijlagen:</span>
                    <ul>
                        <li v-for="file in message.attachments"><a href="#" @click.prevent="download(file)">{{ showFilename(file) }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="message-body">
                <nl2br tag="p" :text="message.messageBody" />
            </div>
            <div>
                <ul>
                    <li><a class="button button--xs" href="#" @click.stop="reply">Beantwoorden</a></li>
                </ul>
            </div>
            <span class="delete-icon-mobile">
                <icon icon="trash-alt" @click.stop="deleteMessage"></icon>
            </span>
        </div>
    </div>
</template>

<script>
    import { format } from 'date-fns';
    import { fetchMessage, downloadLink } from "./api";
    import { DELETE_MESSAGE } from './index';
    const dutchLocale = require('date-fns/locale/nl');

    export default {
        name: 'message-detail',
        props: ['id'],
        data: () => ({
            message: null,
            loading: false,
            error: false,
        }),
        created(){
            this.fetch();
        },
        watch:{
            id(){
                this.message = false;
                this.fetch();
            }
        },
        methods: {
            download(file) {
                window.location.href = downloadLink(file.id);
            },
            showFilename(file) {
                const property = file.metadata.filter(data => data.name === 'filename')[0];

                if(!property){
                    return 'unknown';
                }

                return property.data
            },
            formatDate(timestamp) {
                return format(new Date(timestamp*1000), 'dddd D MMMM YYYY, HH:mm', { locale: dutchLocale });
            },
            fetch() {
                this.error = false;
                this.loading = true;
                fetchMessage({id : this.id}).then(({ data }) => {
                    this.message = data;
                    this.loading = false;
                }).catch((error) => {
                    this.error = error.response.data.message;
                    this.loading = false;
                })
            },
            deleteMessage() {
                this.$fire(DELETE_MESSAGE, this.message);
            },
            reply() {
                this.$router.push({
                    name: 'messages-detail-draft',
                    params: {
                        id: 'nieuw',
                        subject: this.message.subject,
                        body: this.message.messageBody,
                        to: this.message.fromUser,
                        date: this.message.o_creationDate
                    }
                });
            },
            toProfile(slug) {
                return '/profiel/' + slug;
            }
        }
    };
</script>
