import UserManagementFilter from './user-management-filter.vue';
import UserManagementTable from './user-management-table.vue';
import ToggleUser from './toggle-user.vue';
import ManageUser from './manage-user.vue';
import InviteUser from './invite-user.vue';
import DeleteUser from './delete-user.vue';
import EditSubscriptions from './edit-subscriptions.vue';

export default function (Vue) {
    Vue.component('user-management-filter', UserManagementFilter);
    Vue.component('user-management-table', UserManagementTable);
    Vue.component('toggle-user', ToggleUser);
    Vue.component('manage-user', ManageUser);
    Vue.component('delete-user', DeleteUser);
    Vue.component('invite-user', InviteUser);
    Vue.component('edit-subscriptions', EditSubscriptions);
}
