<template>
    <autocomplete
            :request="suggest"
            @submit="navigate"
    >
        <template slot-scope="{ item: {title, category, hasSubscriptions, provider} }">
            <div>
                <span v-if="hasSubscriptions" class="subscription__label">
                    <icon icon="plus-square"></icon>
                </span>
                <span v-html="title"></span>
            </div>
            <div>
                <i v-for="(name, key) in category" :key="key" v-html="name"></i>
            </div>
            <div v-if="logoLink(provider)" class="float-right provider-image__container">
                <div class="h-100 d-flex flex-column justify-content-center">
                    <img :src="logoLink(provider)" alt="Provider image" itemprop="image" itemtype="https://schema.org/ImageObject">
                </div>
            </div>
        </template>
    </autocomplete>
</template>

<script>
    import { suggest } from './api';

    export default {
        name: 'SearchSuggest',

        methods: {
            suggest,
            advancedSearch(term){
                window.location = Routing.generate('search', {
                    query: term
                });
            },

            navigate({item, term}) {
                if(!item || !item.route || item.route.length === 0){
                    this.advancedSearch(term);
                } else{
                    window.location = item.route;
                }
            },
            logoLink(provider = undefined) {
                return provider ? provider.logoLink : undefined;
            }
        }
    }
</script>
