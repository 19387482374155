<template>
    <div>
        <div v-if="errored">
            <span class="error-line">Een bestand kon niet worden geüploaded</span>
        </div>

        <div class="file-upload-list">
            <div v-if="files.length > 0" class="file-upload-list__label-container">
                <span @click="clearAll">
                    <icon icon="trash-alt" class="mr-2"></icon>
                    Verwijder alles
                </span>
            </div>

            <div class="file-upload-list__file-container">
                <template v-for="(file, index) in files" :ref="index">
                    <!-- File item -->
                    <div class="file-upload-list__file">
                        <span @click="clear(file)" class="btn-remove"><icon icon="trash-alt" class="icon"></icon></span>
                        <div class="file-upload-list__file-preview">
                            <img v-if="fileType(file) === 'image'" :src="file.path" alt="preview image">
                            <icon v-if="fileType(file) === 'video'" icon="file-video" class="file-upload__upload-icon"></icon>
                            <icon v-if="fileType(file) === 'file'" icon="file-alt" class="file-upload__upload-icon"></icon>
                        </div>
                        <div class="file-upload-list__file-label">
                            <span>{{ file.filename }}</span>
                        </div>
                    </div>
                </template>

                <div class="file-upload-list__file">
                    <!-- Input item -->
                    <icon icon="plus-square" class="file-upload__upload-icon"></icon>
                    <input type="file"
                           :name="name"
                           multiple="multiple"
                           @change="change"
                           class="file-upload__upload-item"
                           :ref="'inputField'"
                           accept=".mp3, .ogg, .wav, .7z, .rar, .tar, .zip, .jpeg, .jpg, .png, .psd, .svg, .ai, .ppt, .pptx, .xls, .xlsx, .doc, .docx, .pdf, .txt, .rtf, .gif"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'FileUploadList',

        props: {
            id: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            required: {
                required: false,
                type: Boolean,
                default: false,
            },
            uploadRoute: {
                required: true,
                type: String,
            },
            idContainer: {
                required: true,
                type: String,
            },
            originalFiles: {
                type: Array,
                default: [],
            },
        },

        data() {
            return {
                files: [],
                errored: false,
            };
        },

        mounted() {
            this.files = this.originalFiles;
            document.getElementById(this.idContainer).setAttribute('value', this.files.map(f => f.id).join(';'));
        },

        methods: {
            change(event) {
                for (let i = 0; i < event.target.files.length; i++) {
                    this.upload(event.target.files[i]).then(({data}) => {
                        this.files.push(data);
                        document.getElementById(this.idContainer).value = this.files.map(f => f.id).join(';');
                        this.errored = false;
                    }).catch(err => {
                        console.error(err);
                        this.errored = true;
                    });
                }
            },
            async upload(file) {
                const formData = new FormData();
                formData.append('file', file);

                return axios.post(Routing.generate(this.uploadRoute), formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            },
            clear(file) {
                this.files = this.files.filter(f => {
                    return file.id !== f.id;
                });
                document.getElementById(this.idContainer).value = this.files.map(f => f.id).join(';');
            },
            clearAll() {
                this.files = [];
                document.getElementById(this.idContainer).value = '';
            },
            fileType(file) {
                if (file.mimetype === undefined) {
                    return 'file';
                }

                if (file.mimetype.includes('image/')) {
                    return 'image';
                }

                if (file.mimetype.includes('video/')) {
                    return 'video';
                }

                if (file.mimetype.includes('upload/')) {
                    return 'upload';
                }

                return 'file';
            },
        }
    };
</script>
