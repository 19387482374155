import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faInbox,
    faTrashAlt,
    faFileAlt,
    faPaperPlane,
    faPlus,
    faTimes,
    faChevronUp,
    faCheck,
    faEye,
    faEyeSlash,
    faEdit,
    faCrown,
    faCaretUp,
    faCaretDown,
    faSort,
    faCircleNotch,
    faUserCheck,
    faFile,
    faFileUpload,
    faFileDownload,
    faCopy,
    faTimesCircle,
    faFolder,
    faFolderOpen,
    faHome,
    faTags,
    faClock,
    faUserSlash,
    faPlusSquare,
    faPaperclip,
    faLocationArrow,
    faEnvelope,
    faLink,
    faFileVideo,
    faMapPin,
    faMap,
    faPencilAlt,
    faSearch,
    faSlidersH,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

export default function (Vue) {
    library.add(faInbox);
    library.add(faTrashAlt);
    library.add(faFileAlt);
    library.add(faPaperPlane);
    library.add(faPlus);
    library.add(faTimes);
    library.add(faChevronUp);
    library.add(faCheck);
    library.add(faEye);
    library.add(faEyeSlash);
    library.add(faEdit);
    library.add(faCrown);
    library.add(faCaretUp);
    library.add(faCaretDown);
    library.add(faSort);
    library.add(faCircleNotch);
    library.add(faUserCheck);
    library.add(faFile);
    library.add(faFileUpload);
    library.add(faFileDownload);
    library.add(faCopy);
    library.add(faTimesCircle);
    library.add(faFolder);
    library.add(faFolderOpen);
    library.add(faHome);
    library.add(faTags);
    library.add(faClock);
    library.add(faUserSlash);
    library.add(faPlusSquare);
    library.add(faPaperclip);
    library.add(faLocationArrow);
    library.add(faEnvelope);
    library.add(faLink);
    library.add(faFileVideo);
    library.add(faMapPin);
    library.add(faMap);
    library.add(faPencilAlt);
    library.add(faSearch);
    library.add(faSlidersH);
    library.add(faInfoCircle);

    Vue.component('icon', FontAwesomeIcon);
    dom.watch();
}
