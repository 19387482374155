var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ckeditor", {
        attrs: { editor: _vm.editor, config: _vm.editorConfig },
        on: { ready: _vm.onReady },
        model: {
          value: _vm.editorDataValue,
          callback: function ($$v) {
            _vm.editorDataValue = $$v
          },
          expression: "editorDataValue",
        },
      }),
      _vm._v(" "),
      _c(
        "textarea",
        {
          staticClass: "hidden-input",
          attrs: {
            id: _vm.widgetAttributeValues.id,
            name: _vm.widgetAttributeValues.name,
            required: _vm.widgetAttributeValues.required,
          },
        },
        [_vm._v("      " + _vm._s(_vm.editorDataValue) + "\n    ")]
      ),
      _vm._v(" "),
      _c("div", {
        attrs: { id: _vm.widgetAttributeValues.id + "__word-count" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }