var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message container" },
    [
      _c("tabs", { attrs: { tabs: _vm.tabs } }),
      _vm._v(" "),
      _c("div", { staticClass: "tab-content" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-md-3 d-md-block",
              class: { "d-none": _vm.isDetail },
            },
            [
              _c("messages-loader", {
                attrs: { tab: _vm.currentTab },
                model: {
                  value: _vm.stats,
                  callback: function ($$v) {
                    _vm.stats = $$v
                  },
                  expression: "stats",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-md-block col-md-9" },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _vm.isDetail
            ? _c(
                "div",
                { staticClass: "col-md-12 d-md-none" },
                [
                  _c(
                    "router-link",
                    { staticClass: "back", attrs: { to: _vm.back } },
                    [_vm._v("Terug")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }