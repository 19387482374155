// @flow
import axios from 'axios';

export function markAsRead(payload) {
    return axios.post(Routing.generate('ajax_read_message', payload));
}

export function fetchMessages(payload) {
    return axios.get(Routing.generate('ajax_fetch_messages', payload));
}

export function fetchMessage(payload) {
    return axios.get(Routing.generate('ajax_fetch_message', payload));
}

export function sendMessage(payload) {
    return axios.post(Routing.generate('ajax_send_message', payload));
}

export function saveMessage(payload) {
    return axios.post(Routing.generate('ajax_save_message', payload));
}

export function deleteMessage(payload) {
    return axios.delete(Routing.generate('ajax_delete_message', payload));
}

// Asset needs to be in the body from the post and not the routing.generate
// Because the routing.generate does not know how to work with the formData
export function uploadAsset(asset) {
    return axios.post(Routing.generate('ajax_upload_asset'), asset);
}

export function deleteAsset(id) {
    return axios.delete(Routing.generate('ajax_delete_asset', { id }));
}

export function downloadLink(id) {
    return Routing.generate('ajax_download_asset', { id });
}
