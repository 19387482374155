/* eslint-disable no-param-reassign */
export default function (Vue) {
    const bus = new Vue();

    function when(event, callback) {
        bus.$on(event, callback);

        this.$once('hook:beforeDestroy', () => {
            // make sure to automatically remove the listener when the component get's destroyed
            bus.$off(event, callback);
        });
    }

    function fire(event, args) {
        bus.$emit(event, args);
    }

    Vue.prototype.$fire = fire;
    Vue.prototype.$when = when;
}
