<template>
    <modal>
        <h5>Gebruiker uitnodigen</h5>

        <div class="row my-2">
            <div class="col-12 d-flex flex-column mb-3">
                <label for="userFormEmail">E-mailadres</label>
                <div>
                    <input type="text" id="userFormEmail" class="form-control w-100" placeholder="E-mailadres" v-model="user.email">
                </div>
            </div>
            <div class="col-6 d-flex flex-column mb-3">
                <label for="userFormFirstname">Voornaam</label>
                <div>
                    <input type="text" id="userFormFirstname" class="form-control w-100" placeholder="Voornaam" v-model="user.firstname">
                </div>
            </div>
            <div class="col-6 d-flex flex-column mb-3">
                <label for="userFormLastname">Familienaam</label>
                <div>
                    <input type="text" id="userFormLastname" class="form-control w-100" placeholder="Familienaam" v-model="user.lastname">
                </div>
            </div>
        </div>

        <div>
            <button class="btn btn-xs btn-default mr-1 mb-1" @click="confirm">Uitnodigen</button>
            <button class="btn btn-xs button--neutral" @click="cancel">Annuleren</button>
        </div>

    </modal>
</template>

<script>
    import { inviteUser } from './api';

    export default {
        name: 'invite-user',
        data() {
            return {
                user: {},
            };
        },
        props: {
            groupId: {
                type: Number,
                required: true,
            },
        },
        methods: {
            confirm() {
                if (!this.user.firstname || !this.user.lastname || !this.user.email) {
                    this.$emit('error', 'Niet alle velden zijn ingevuld', false);
                    return;
                }

                inviteUser(this.groupId, this.user).then(({ data }) => {
                    if (data.message) {
                        this.$emit('invited', data.message, data.refresh);
                        this.$emit('close');
                        return;
                    }

                    if (data.error) {
                        this.$emit('error', data.error, false);
                        this.$emit('close');
                    }
                });
            },
            cancel() {
                this.$emit('close');
            },
        }
    }
</script>
