<template>
    <div class="search__block">
        <div class="row">
            <div :class="leftColumnSize">
                <a :href="data.route" @click.prevent="click($event)" :class="'search__label search__' + data.type">
                    {{ data.label }} - {{ formatDate(data.date_for_notifications) }}
                </a>

                <div>
                    <a v-if="data.route" :href="data.route" @click.prevent="click($event)" class="search-link">
                        <h2>
                            <span v-if="data.hasSubscriptions" class="subscription__label">
                                <icon icon="plus-square"></icon>
                            </span>
                            <span v-html="data.title"></span>
                        </h2>
                    </a>
                    <h2 v-else>
                        <span v-if="data.hasSubscriptions" class="subscription__label">
                            <icon icon="plus-square"></icon>
                        </span>
                        <span v-html="data.title"></span>
                    </h2>
                </div>

                <p v-if="data.tags.length > 0" class="pb-0">
                    <span v-for="tag in data.tags" class="badge badge-dark mr-2">{{ tag.name }}</span>
                </p>

                <p v-html="data.description"></p>

                <p v-if="data.fileLocation !== null && data.groupName !== null && data.groupSlug !== null" class="p-0 search__group-link">
                    <a :href="data.groupSlug">Bestand uit groep "{{ data.groupName }}"</a><br>
                    <a :href="data.route">Locatie van het bestand: "{{ data.fileLocation }}"</a>
                </p>

                <cite v-if="data.knowledgeDomains">{{ printDomains(data.knowledgeDomains) }}</cite>
            </div>
            <div :class="rightColumnSize" v-if="data.image||data.provider">
                <figure v-if="showProvider" class="d-inline-block w-100">
                    <img class="provider-image" :src="data.provider.logoLink" :alt="data.provider.name" itemprop="image" itemtype="https://schema.org/ImageObject">
                </figure>
                <figure v-if="data.image" class="d-inline-block w-100">
                    <img :src="data.image" alt="author image">
                </figure>
            </div>
        </div>
    </div>
</template>

<script>
    import { format } from 'date-fns';

    export default {
        name: 'cardSearch',
        props: {
            data: {
                type: Object,
            },
        },
        computed: {
            leftColumnSize() {
                return this.data.image || this.data.provider ? 'col-8' : 'col-10';
            },
            rightColumnSize() {
                return this.data.image || this.data.provider ? 'col-4' : 'col-2';
            },
            hasSubscriptions() {
                return !!this.data.hasSubscriptions;
            },
            showProvider() {
                return this.data.type !== 'user';
            },
        },
        methods: {
            formatDate(timestamp) {
                return format(new Date(timestamp*1000), 'D-M-YYYY');
            },
            printDomains(domains) {
                if (domains.length === 0) {
                    return '';
                }

                return '# ' + domains.map(domain => domain.title).join(', ');
            },
            click(e) {
              this.$emit('navigate', this.data, e.ctrlKey || e.metaKey);
            },
        },
    }
</script>
