<template>
    <div>
        <flashmessage
                :type="'success'"
                :message="feedback"
                v-if="feedback"
        ></flashmessage>
        <modal v-if="showModal">
            <div class="form-group">
                <p class="control-label">Weet u zeker dat u deze gebruiker uit de groep wilt verwijderen?</p>
            </div>

            <div>
                <button class="btn btn-xs btn-default mr-2 mt-2" @click="confirm">Ja, ik ben zeker</button>
                <button class="btn btn-xs button--neutral mt-2" @click="cancel">Annuleren</button>
            </div>
        </modal>
        <div class="row group__memberlist mt-4">
            <div v-for="member in members" class="col-12 col-md-4 col-xl-3">
                <div class="member__profile-link testimonial__block">
                    <div v-if="isModerator" class="member__profile-icon--remove" @click="remove(member)">
                        <icon icon="times-circle"></icon>
                    </div>
                    <div class="d-flex d-md-block flex-row align-items-center member__profile-data p-2 py-md-0" @click="show(member)">
                        <figure class="img-circle--memberlist mt-4">
                            <img :src="member.avatar_url" :alt="getName(member)" />
                        </figure>

                        <aside>
                            <strong>
                                {{ getName(member) }}
                            </strong>
                            <span>{{ member.function_name }}</span>
                            <p>{{ member.knowledge_domain }}</p>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
        <pagination
                :current_page_number="searchData.current_page_number"
                :total_items="searchData.total_items"
                :items_per_page="searchData.items_per_page"
                @changedPage="changedPage"
        ></pagination>
    </div>
</template>

<script>
    import { getGroup, removeMember } from "./api";

    export default {
        name: 'MeetingMembers',
        props: {
            id: {
                type: Number,
                required: true,
            },
            isModerator: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                feedback: false,
                members: [],
                showModal: false,
                memberToDelete: undefined,
                searchData: {
                    current_page_number: 1,
                    total_items: 1,
                    items_per_page: 10,
                }
            };
        },
        created() {
            this.fetch();
        },
        methods: {
            fetch() {
                getGroup(this.id, this.searchData.current_page_number, this.searchData.items_per_page).then(({ data }) => {
                    this.members = data.members;
                    this.searchData = data.searchdata;
                });
            },
            getName(member) {
                return member.firstname + ' ' + member.lastname;
            },
            show(member) {
                window.location.href = Routing.generate('profile_show', { slug: member.slug });
            },
            remove(member) {
                this.showModal = true;
                this.memberToDelete = member;
            },
            confirm() {
                removeMember(this.id, this.memberToDelete.id).then(() => {
                    this.feedback = this.getName(this.memberToDelete) + ' werd uit de groep verwijderd';
                    this.showModal = false;
                    this.memberToDelete = undefined;
                    setTimeout(() => { this.feedback = false }, 3000);

                    return this.fetch();
                });
            },
            cancel() {
                this.showModal = false;
                this.memberToDelete = undefined;
            },
            changedPage(newPageNumber) {
                this.searchData.current_page_number = newPageNumber;
                this.fetch();
            },
        }
    }
</script>
