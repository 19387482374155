<template>
    <div>
        <flashmessage
                :type="'success'"
                :message="feedback"
                v-if="feedback"
        ></flashmessage>

        <u v-if="attachments.length > 0">Bijlages:</u>
        <div class="mb-2 mt-4 d-flex justify-content-start flex-wrap">
            <div v-for="attachment in attachments" class="conversation__attachment">
                <div v-if="hasDeletePermission" @click="remove(attachment)" class="conversation__attachment__remove justify-content-center align-items-center">
                    <icon icon="times" class="conversation__attachment__remove-icon"></icon>
                </div>
                <div @click="download(attachment)" class="d-flex flex-column flex-nowrap align-items-center text-center">
                    <icon icon="file" class="conversation__attachment__icon mb-2"></icon>
                    <icon icon="file-download" class="conversation__attachment__icon--hover mb-2"></icon>
                    {{ attachment.filename }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { downloadGroupFile, destroyConversationFile } from './api';

    export default {
        name: 'ConversationAttachments',
        data() {
            return {
                feedback: false,
                attachments: this.initialAttachments,
            }
        },
        props: {
            id: {
                type: Number,
                required: true,
            },
            groupId: {
                type: Number,
                required: true,
            },
            initialAttachments: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            hasDeletePermission: {
                type: Boolean,
                required: true,
            }
        },
        methods: {
            download(attachment) {
                window.location.href = downloadGroupFile(this.groupId, attachment.id);
            },
            remove(attachment) {
                destroyConversationFile(this.groupId, this.id, attachment.id).then(({ status, data }) => {
                    if (status === 200) {
                        this.attachments = data.attachments;
                        this.feedback = 'De bijlage werd verwijderd';

                        setTimeout(() => {
                            this.feedback = false;
                        }, 3000); //this matches the flashmessage timer
                    }
                });
            },
        }
    }
</script>
