<template>
    <div class="ui container group-member-list">
        <flashmessage
                :type="flashType"
                :message="feedback"
                v-if="feedback"
        ></flashmessage>

        <div class="row mb-5 my-4">
            <div class="col-12 col-md-6">
                <h2 class="mb-0">Ledenbeheer</h2>
            </div>
            <div class="col-12 col-md-6">
                <a :href="membershipRequestUrl" class="button button--xs float-right">Aangevraagde lidmaatschappen</a>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <user-management-filter></user-management-filter>
            </div>

            <div class="col-12 col-md-6 d-flex justify-content-end mb-3">
                <label for="fileSelect" class="button button--neutral button--xs mr-2" data-toggle="tooltip" title="Let op: de bulk import functie voor gebruikers ondersteunt enkel .xlsx bestanden. Sla uw bestand op in de juiste indeling! Structureer de gegevens respectievelijk in kolommen: Naam, Voornaam en E-mailadres (zonder kolomtitelrij)." ref="fileSelect">
                    <span id="loader" class="loader" v-if="showSpinner"></span>
                    <span v-else>Meerdere uitnodigen</span>
                </label>
                <div class="button button--xs" @click="inviteModal = true">Iemand uitnodigen</div>
                <input
                    id="fileSelect"
                    ref="file"
                    class="d-none"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="uploadBulk"
                />
            </div>
        </div>

        <vuetable ref="vuetable"
                  :api-url="groupMemberListUrl"
                  :fields="fields"
                  :query-params="makeQueryParams"
                  :append-params="moreParams"
                  :show-sort-icons="true"
                  :css="css.table"
                  pagination-path=""
                  :per-page="10"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:cell-clicked="onCellClick"
        >
            <template slot="moderator" slot-scope="{ rowData }">
                <icon v-if="rowData.isModerator" icon="check"></icon>
            </template>

            <template slot="action" slot-scope="{ rowData }">
                <button class="btn btn-xs btn-danger mr-1 mb-1 mb-xl-0" @click="startTrashModal(rowData)">
                    <icon icon="trash-alt"></icon>
                </button>
                <button class="btn btn-xs btn-primary mr-1 mb-1 mb-xl-0" @click="startManagingModal(rowData)">
                    <icon icon="crown"></icon>
                </button>
            </template>
        </vuetable>

        <manage-user v-if="managingModal"
                     v-model="activeUser"
                     @confirm="makeUserModerator"
                     @close="managingModal = false"
        ></manage-user>

        <delete-user
                v-if="deleteModal"
                v-model="activeUser"
                @confirm="remove"
                @close="deleteModal = false"
        ></delete-user>

        <invite-user
                v-if="inviteModal"
                :group-id="groupId"
                @close="inviteModal = false"
                @invited="invited"
                @error="showFeedback"
        ></invite-user>

        <vuetable-pagination ref="pagination"
                             :css="css.pagination"
                             :on-each-side="2"
                             @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
    </div>
</template>
<script>
    import { Vuetable, VuetablePagination } from 'vuetable-2';
    import { toggleGroupUserModerator, removeMember } from './api';
    import { inviteUsersInBulk } from '../datatable/api';
    import css from '../datatable/styling';

    export default {
        name: 'GroupMemberList',
        components: {
            Vuetable,
            VuetablePagination
        },
        props: {
            groupId: {
                type: Number,
                required: true
            },
            groupSlug: {
                type: String,
                required: true
            }
        },
        mounted() {
            this.$when('filter-set', eventData => this.onFilterSet(eventData));
            this.$when('filter-reset', e => this.onFilterReset());

            $(this.$refs.fileSelect).tooltip();
        },
        data() {
            return {
                groupMemberListUrl: Routing.generate('ajax_group_member_list', { groupId: this.groupId }),
                membershipRequestUrl: Routing.generate('group_membership_requests', { groupSlug: this.groupSlug }),
                managingModal: false,
                deleteModal: false,
                inviteModal: false,
                activeUser: {},
                moreParams: {},
                error: false,
                fields: [
                    {
                        name: 'firstName',
                        sortField: 'firstname',
                        title: 'Voornaam'
                    },
                    {
                        name: 'lastName',
                        sortField: 'lastname',
                        title: 'Familienaam'
                    },
                    {
                        name: 'email',
                        sortField: 'email',
                        title: 'E-mail',
                    },
                    {
                        name: '__slot:moderator',
                        title: 'Beheerder',
                        dataClass: 'text-center',
                    },
                    {
                        name: '__slot:action',
                        dataClass: 'text-right',
                    }
                ],
                css: css,
                feedback: '',
                flashType: 'success',
                showSpinner: false,
            };
        },
        methods: {
            uploadBulk() {
                this.showSpinner = true;
                const file = this.$refs.file.files[0];

                if (file === null || file === undefined) {
                    return;
                }

                const formData = new FormData();
                formData.append('file', file);

                inviteUsersInBulk(this.groupId, formData)
                    .then(result => {
                        this.showFeedback(result.data.message, result.data.success);
                        this.$refs.vuetable.refresh();
                    }).catch(() => {
                        this.showFeedback('Er liep iets fout bij het opladen van de leden', false);
                        this.$refs.vuetable.refresh();
                    })
                    .finally(() => {
                        this.showSpinner = false;
                        document.getElementById('fileSelect').value = '';
                    });
            },
            startManagingModal(user){
                this.managingModal = true;
                this.activeUser = user;
            },
            startTrashModal(user) {
                this.deleteModal = true;
                this.activeUser = user;
            },
            makeQueryParams (sortOrder, currentPage, perPage) {
                return {
                    sort: sortOrder[0] ? sortOrder[0].sortField : '',
                    order: sortOrder[0] ? sortOrder[0].direction : '',
                    page: currentPage,
                    pageSize: perPage,
                }
            },
            onFilterSet (filterText) {
                this.moreParams = {
                    'filter': filterText
                };
                this.$nextTick(() => this.$refs.vuetable.refresh());
            },
            onFilterReset () {
                this.moreParams = {};
                this.$nextTick(() => this.$refs.vuetable.refresh());
            },
            onPaginationData (paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
            },
            onChangePage (page) {
                this.$refs.vuetable.changePage(page);
            },
            onCellClick(user) {
                window.location.href = Routing.generate('profile_show', { slug: user.slug });
            },
            revoked(){
                window.location.href = Routing.generate('group_show', { groupSlug: this.groupSlug });
            },
            showFeedback(message, isSuccess) {
                this.flashType = isSuccess ? 'success' : 'error';
                this.feedback = message;
                setTimeout(() => {
                    this.feedback = false;
                }, 3000);
            },
            makeUserModerator(){
                toggleGroupUserModerator(this.groupId, this.activeUser.id, !this.activeUser.isModerator).then(({data}) => {
                    const {user, stillModerating, error} = data;

                    Object.keys(user).forEach((key) => {
                        this.activeUser[key] = user[key];
                    });

                    if(error){
                        this.showFeedback(error, false);
                    }

                    if(!stillModerating) {
                        this.revoked();
                    }

                    this.managingModal = false;
                }).catch();
            },
            remove() {
                this.deleteModal = false;
                removeMember(this.groupId, this.activeUser.id).then(({ data, status }) => {
                    // user deleted himself
                    if(status === 205) {
                        window.location.href = Routing.generate('profile_show');
                    } else {
                        if (!data.error) {
                            this.showFeedback('De gebruiker werd uit de groep verwijderd', true);
                            this.$nextTick(() => this.$refs.vuetable.refresh());
                        } else {
                            this.showFeedback(data.error, false);
                        }
                    }
                });
            },
            invited(message, refresh) {
                this.showFeedback(message, true);

                if (refresh == true) {
                    this.$nextTick(() => this.$refs.vuetable.refresh());
                }
            },
        }
    }
</script>
