<template>
    <select ref="el" class="form-control mb-3" multiple>
        <option v-for="option in options" :value="option.id" :selected="isSelected(option)">{{ option.text }}</option>
    </select>
</template>

<script>
    export default {
        name: 'select2',
        props: {
            value: {
                required: true,
            },
            options: {
                required: true,
                type: Array,
            },
        },

        mounted () {
            window.$(this.$el)
                .select2({ tags: false, multiple: true, width: '640px' })
                .val(this.value.map(item => item.id))
                .on('change.select2', () => {
                    this.$emit('input', $(this.$el).select2('data'));
                });
        },

        // When the parent data is updated, a watcher on the `value` variable should be added

        destroyed () {
            window.$(this.$el).off('change.select2').select2('destroy');
        },
        methods: {
            isSelected(option) {
                return this.value.map(item => parseInt(item.id, 10)).indexOf(parseInt(option.id, 10)) !== -1;
            },
        }
    }
</script>
