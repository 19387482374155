var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-to" },
    [
      _c("label", [_vm._v("Ontvanger(s) *:")]),
      _vm._v(" "),
      !_vm.value.length
        ? _c("div", { staticClass: "message-to-empty" }, [
            _vm._v("Nog geen ontvangers geselecteerd."),
          ])
        : _c(
            "div",
            { staticClass: "message-to-selected" },
            _vm._l(_vm.value, function (item) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.remove(item)
                    },
                  },
                },
                [
                  _c("i", { domProps: { innerHTML: _vm._s(item.title) } }),
                  _vm._v(" "),
                  _c("icon", { attrs: { icon: "times" } }),
                ],
                1
              )
            }),
            0
          ),
      _vm._v(" "),
      _c("search-users", {
        attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
        on: { input: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }