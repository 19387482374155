<template>
    <div class="ui container">
        <div class="row">
            <div class="col-12 col-md-6">
                <user-management-filter></user-management-filter>
            </div>
        </div>
        <vuetable ref="vuetable"
                  :api-url="whitelistMembersUrl"
                  :fields="fields"
                  :query-params="makeQueryParams"
                  :append-params="moreParams"
                  :show-sort-icons="true"
                  :css="css.table"
                  pagination-path=""
                  :per-page="10"
                  @vuetable:pagination-data="onPaginationData"
        >
            <template slot="verified" slot-scope="{ rowData }">
                <icon v-if="rowData.is_verified" icon="check"></icon>
            </template>

            <template slot="manager" slot-scope="{ rowData }">
                <icon v-if="rowData.isModerator" icon="check"></icon>
            </template>

            <template slot="action" slot-scope="{ rowData }">
                <template v-if="!rowData.isModerator || authenticatedUserId === rowData.id ">
                    <button v-if="rowData.published" class="btn btn-xs btn-danger mr-1 mb-1 mb-xl-0" @click="startActivationModal(rowData)">
                        <icon icon="eye"></icon>
                    </button>
                    <button v-else class="btn btn-xs btn-danger mr-1 mb-1 mb-xl-0" @click="startActivationModal(rowData)">
                        <icon icon="eye-slash"></icon>
                    </button>
                    <button class="btn btn-xs mr-1 mb-1 mb-xl-0" @click="startSubscriptionModal(rowData)">
                        <icon icon="edit"></icon>
                    </button>
                </template>

                <button class="btn btn-xs btn-primary mr-1 mb-1 mb-xl-0" @click="startManagingModal(rowData)">
                    <icon icon="crown"></icon>
                </button>
            </template>
        </vuetable>

        <toggle-user v-if="toggling" @close="toggling = false" v-model="activeUser"></toggle-user>
        <edit-subscriptions v-if="editing" @close="editing = false" v-model="activeUser"></edit-subscriptions>
        <manage-user v-if="managing"
                     v-model="activeUser"
                     @confirm="makeUserManager"
                     @close="managing = false"
        ></manage-user>
        <vuetable-pagination ref="pagination"
                             :css="css.pagination"
                             :on-each-side="2"
                             @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>

        <flashmessage v-if="error" type="error" :message="error"></flashmessage>
    </div>
</template>

<script>
    import { Vuetable, VuetablePagination } from 'vuetable-2';
    import { toggleUserManager } from "./api";
    import css from './styling';

    export default {
        name: 'user-management-table',
        components: {
            Vuetable,
            VuetablePagination
        },
        mounted() {
            this.$when('filter-set', eventData => this.onFilterSet(eventData));
            this.$when('filter-reset', e => this.onFilterReset());
        },
        data () {
            return {
                whitelistMembersUrl: Routing.generate('ajax_user_management_whitelist_users'),
                toggling: false,
                editing: false,
                managing: false,
                activeUser: {},
                moreParams: {},
                error: false,
                fields: [
                    {
                        name: 'name',
                        sortField: 'firstname',
                        title: 'Naam'
                    },
                    {
                        name: 'email',
                        sortField: 'email',
                        title: 'E-mail',
                    },
                    {
                        name: '__slot:verified',
                        sortField: 'isVerified',
                        title: 'Geverifieerd',
                        dataClass: 'text-center',
                    },
                    {
                        name: 'subscriptions',
                        title: 'Abonnementen',
                    },
                    {
                        name: '__slot:manager',
                        title: 'Beheerder',
                        dataClass: 'text-center',
                    },
                    {
                        name: '__slot:action',
                        dataClass: 'text-right',
                    }
                ],
                css: css,
            }
        },
        methods: {
            startManagingModal(user){
                this.managing = true;
                this.activeUser = user;
            },
            startActivationModal(user) {
                this.toggling = true;
                this.activeUser = user;
            },
            startSubscriptionModal (user) {
                this.editing = true;
                this.activeUser = user;
            },
            makeQueryParams (sortOrder, currentPage, perPage) {
                return {
                    sort: sortOrder[0] ? sortOrder[0].sortField : '',
                    order: sortOrder[0] ? sortOrder[0].direction : '',
                    page: currentPage,
                    pageSize: perPage,
                }
            },
            onFilterSet (filterText) {
                this.moreParams = {
                    'filter': filterText
                };
                this.$nextTick(() => this.$refs.vuetable.refresh());
            },
            onFilterReset () {
                this.moreParams = {};
                this.$nextTick(() => this.$refs.vuetable.refresh());
            },
            onPaginationData (paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
            },
            onChangePage (page) {
                this.$refs.vuetable.changePage(page);
            },
            errored(error){
                this.error = error;
                setTimeout(()=> {
                    this.error = false;
                }, 3000); //this matches the flashmessage timer
            },
            revoked(){
                window.location.href = Routing.generate('profile_show');
            },
            makeUserManager(){
                toggleUserManager(this.activeUser.id, !this.activeUser.isModerator).then(({data}) => {
                    const {user, still_managing, error} = data;

                    Object.keys(user).forEach((key) => {
                        this.activeUser[key] = user[key];
                    });

                    if(error){
                        this.errored(error);
                    }

                    if(!still_managing) {
                        this.revoked();
                    }

                    this.managing = false;
                }).catch();
            }
        },
        computed: {
            authenticatedUserId() {
                return window.authenticated_user_id;
            }
        }
    }
</script>
