var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.feedback
        ? _c("flashmessage", {
            attrs: { type: "success", message: _vm.feedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.attachments.length > 0 ? _c("u", [_vm._v("Bijlages:")]) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2 mt-4 d-flex justify-content-start flex-wrap" },
        _vm._l(_vm.attachments, function (attachment) {
          return _c("div", { staticClass: "conversation__attachment" }, [
            _vm.hasDeletePermission
              ? _c(
                  "div",
                  {
                    staticClass:
                      "conversation__attachment__remove justify-content-center align-items-center",
                    on: {
                      click: function ($event) {
                        return _vm.remove(attachment)
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "conversation__attachment__remove-icon",
                      attrs: { icon: "times" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column flex-nowrap align-items-center text-center",
                on: {
                  click: function ($event) {
                    return _vm.download(attachment)
                  },
                },
              },
              [
                _c("icon", {
                  staticClass: "conversation__attachment__icon mb-2",
                  attrs: { icon: "file" },
                }),
                _vm._v(" "),
                _c("icon", {
                  staticClass: "conversation__attachment__icon--hover mb-2",
                  attrs: { icon: "file-download" },
                }),
                _vm._v(
                  "\n                    " +
                    _vm._s(attachment.filename) +
                    "\n                "
                ),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }