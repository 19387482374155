var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { ref: "scroller", staticClass: "info-card-wrapper" },
      _vm._l(_vm.messages, function (message) {
        return _c("message-info-card", {
          key: message.o_id,
          attrs: { message: message },
          on: { open: _vm.showDetails },
        })
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.fetching && _vm.messages.length === 0,
            expression: "!fetching && messages.length === 0",
          },
        ],
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.fetching,
            expression: "fetching",
          },
        ],
      },
      [_vm._m(1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "no-messages" }, [
        _vm._v("Je hebt geen berichten om weer te geven"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "no-messages" }, [
        _vm._v("⟳ Berichten aan het laden"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }