<template>
    <div class="col-12 form-wrapper mb-4">
        <flashmessage
                :type="flashType"
                :message="feedback"
                v-if="feedback"
        ></flashmessage>

        <div class="row mb-2">
            <h2 class="col-12 col-md-3 mb-2">Bestanden</h2>
        </div>

        <div class="row mb-2">
            <div class="col-12 col-md-4">
                <p class="mt-3 mb-0 meeting-files-breadcrumbs" v-if="!isSearching">
                    U bevindt zich hier:
                    <span class="d-inline meeting-files-breadcrumbs__item" v-for="(breadcrumbItem, index) in breadcrumbs">
                        <span v-if="index === 0" @click="changeFolder(breadcrumbItem.id)"><icon icon="home"></icon></span>
                        <span v-else @click="changeFolder(breadcrumbItem.id)">{{ breadcrumbItem.name }}</span>
                    </span>
                </p>
            </div>
            <div class="col-12 col-md-4">
                <input type="text" v-model="search" placeholder="Bestand zoeken binnen deze groep">
            </div>
            <div class="col-12 col-md-4 text-md-right justify-content-end" v-if="canCreateAssets">
                <span v-if="counter > 1">{{ counter }} bestanden aan het opladen</span>
                <span v-else-if="counter == 1">1 bestand aan het opladen</span>
                <button type="button" class="button button--xs" @click="selectFile">
                    <icon icon="file-upload" class="mr-2"></icon>
                    Nieuw bestand uploaden
                </button>
                <input type="file" @change="startUpload" multiple class="d-none" ref="select">
                <button type="button" class="button button--xs" @click="createFolder" v-if="canCreateFolders">
                    <icon icon="folder" class="mr-2"></icon>
                    Map aanmaken
                </button>
            </div>
        </div>

        <table class="table table-striped meeting-files">
            <thead>
                <tr class="w-100 meeting-files__header">
                    <th scope="col" class="col-3" @click="sortFiles('name')">
                        Bestandsnaam
                        <icon :icon="showIcon('name')" class="ml-2"></icon>
                    </th>
                    <th scope="col" class="col-2" @click="sortFiles('creationDate')">
                        Aanmaakdatum
                        <icon :icon="showIcon('creationDate')" class="ml-2"></icon>
                    </th>
                    <th scope="col" class="col-2" @click="sortFiles('modificationDate')">
                        Gewijzigd op
                        <icon :icon="showIcon('modificationDate')" class="ml-2"></icon>
                    </th>
                    <th scope="col" class="col-2" @click="sortFiles('owner')">
                        Eigenaar
                        <icon :icon="showIcon('owner')" class="ml-2"></icon>
                    </th>
                    <th scope="col" class="col-3 text-center">Acties</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="creatingFolder" class="meeting-files__row" @focusout="saveNewFolder">
                    <td class="meeting-files__name--folder">
                        <icon class="folder-icon" icon="folder" />
                        <icon class="folder-icon-open" icon="folder" />

                        <ValidationObserver ref="createFolderValidator" tag="div">
                            <ValidationProvider :rules="{ regex: fileNameRegex }" v-slot="{ errors }" tag="div">
                                <input type="text" ref="createFolderInput" @keydown="createFolderEvents" v-model="newFolderName" class="rename-file w-100" />
                                <span class="form-errors">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </ValidationObserver>
                    </td>
                    <td></td>
                    <td></td>
                    <td>{{ currentUser.name }}</td>
                    <td>
                        <button type="button" class="button button--xs" @click="saveNewFolder">
                            Opslaan
                        </button>
                    </td>
                </tr>
                <tr v-if="files.length <= 0 && search === ''">
                    <td colspan="5">Er zijn nog geen bestanden</td>
                </tr>
                <tr v-if="files.length <= 0 && search !==''">
                    <td colspan="5">Er werden geen bestanden gevonden</td>
                </tr>
                <tr v-for="(file, key) in files" class="meeting-files__row" @focusout="rename(file)" :key="key" @contextmenu.prevent="rightMenuClick(file)">
                    <td scope="row" :class="classNames(file).column_name" @click="clickRow(file)">
                        <icon :class="classNames(file).icon" :icon="getIcon(file)"></icon>
                        <icon v-if="file.type === 'folder'" class="folder-icon-open" icon="folder-open"></icon>
                        <ValidationObserver :ref="'renameValidator' + file.id" tag="div" v-if="file.id === renamingId">
                            <ValidationProvider :rules="{ regex: fileNameRegex }" v-slot="{ errors }" tag="div">
                                <input type="text" class="rename-file w-100" @keydown="renameAssetEvents($event, file)" v-model="file.newName" />
                                <span class="form-errors">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </ValidationObserver>
                        <div v-else>
                            <div>
                                <span class="search__filename pr-4" v-html="file.name"></span>
                                <span v-for="tag in file.tags" class="badge badge-dark ml-2">{{ tag.name }}</span>
                                <div class="search__file_content" v-if="file.content" v-html="file.content"></div>
                                <div v-if="isSearching" class="search__file_path">Locatie: <span class="ml-1">{{ file.path }}</span></div>
                            </div>
                        </div>
                    </td>
                    <td>{{ file.creationDate }}</td>
                    <td>{{ file.modificationDate }}</td>
                    <td>{{ file.owner }}</td>
                    <td class="meetings-files__action-cell position-relative d-flex justify-content-end">
                        <div v-if="file.id === renamingId">
                            <button type="button" class="button button--xs" @click="rename(file)">
                                Opslaan
                            </button>
                        </div>
                        <div v-else>
                            <span class="btn btn-xs mb-1" data-toggle="tooltip" data-placement="top" title="Kopiëer link"
                                  @click="copyLink(file)">
                                <icon icon="paperclip"></icon>
                            </span>
                        </div>
                        <div v-if="file.id !== renamingId && file.canEdit">
                            <a v-if="file.type !== 'folder'" @click.prevent="editTags(file)" class="btn btn-xs mb-1" data-toggle="tooltip" data-placement="top" title="Wijzig tags">
                                <icon icon="tags"></icon>
                            </a>
                            <a @click.prevent="startRenaming($event, file)" class="btn btn-xs mb-1" data-toggle="tooltip" data-placement="top" title="Wijzig bestandsnaam">
                                <icon icon="edit"></icon>
                            </a>
                            <a @click.prevent="deletingFile = file" class="btn btn-xs btn-danger mb-1" data-toggle="tooltip" data-placement="top" title="Verwijder bestand">
                                <icon icon="trash-alt" class="text-white"></icon>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div>
            <span v-if="hasFiles" class="button button--neutral button--xs float-right" @click="downloadBulk">Download alle bestanden (.zip)</span>
        </div>

        <pagination :class="pagination.css"
                    :current_page_number="pagination.current_page_number"
                    :total_items="pagination.total_items"
                    :items_per_page="pagination.items_per_page"
                    @changedPage="changedPage"
        ></pagination>

        <delete-asset-confirmation
                @close="deletingFile = null"
                @deleted="showDeletedMessage(deletingFile)"
                :groupId="groupId"
                :file="deletingFile"
                v-if="deletingFile"
        ></delete-asset-confirmation>

        <modal v-if="showTags">
            <file-tags
                    :group-id="parseInt(groupId)"
                    :file-id="parseInt(selectedFile)"
                    @close="showTags = false"
                    @update="updateTags"
            ></file-tags>
        </modal>

        <context-menu id="contextMenu" ref="ctxMenu">
            <li>
                <span @click="copyLink()">
                    Kopieer link
                    <icon icon="paperclip"></icon>
                </span>
            </li>
        </context-menu>
    </div>
</template>

<script>
    import {getFiles, downloadGroupFile, downloadBulkGroupFiles, createFolder, renameAsset, getFileInfo, searchFiles} from './api';
    import { debounce } from 'lodash';
    import { Uppy } from '@uppy/core';
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import { regex } from 'vee-validate/dist/rules';
    import XHRUpload from '@uppy/xhr-upload';
    import DeleteAssetConfirmation from "./delete-asset-confirmation";

    extend('regex', {
        ...regex,
        message: 'Een bestands/foldernaam mag enkel letters, cijfers, spaties, underscores en dashes bevatten. Deze mag tot 255 karakters lang zijn.'
    });

    export default {
        name: 'FileManagement',
        data() {
            let page = 1;
            let folder = undefined;
            let by = 'name';
            let order = 'asc';
            let search = '';
            let searchId = 0;

            if(this.$route.query.search) {
                search = this.$route.query.search;
            }

            if(this.$route.query.id) {
                searchId = parseInt(this.$route.query.id, 10);
            }

            if(this.$route.name === 'group-files') {
                if(this.$route.params.folder) {
                    folder = parseInt(this.$route.params.folder, 10);
                }

                if(this.$route.query.page ) {
                    page = parseInt(this.$route.query.page, 10);
                }

                if(this.$route.query.order) {
                    [by, order] = this.$route.query.order.split('|');
                }
            }

            return {
                search: search,
                searchId: searchId,
                isSearching: false,
                feedback: false,
                flashType: 'error',
                pagination: {
                    css: 'meeting-files__pagination',
                    current_page_number: page,
                    total_items: 1,
                    items_per_page: 10,
                },
                renamingId: 0,
                focusId: 0,
                sort: {
                    by,
                    order,
                },
                breadcrumbs: [],
                files: [],
                currentFolder: folder,
                creatingFolder: false,
                newFolderName: '',
                fileNameRegex: /^[a-zA-Z0-9 \-_.()]{1,255}$/,
                allowUploads: false,
                deletingFile: null,
                counter: 0,
                showTags: false,
                selectedFile: undefined,
                clickedFile: undefined,
            };
        },
        props: {
            id: {
                type: Number,
                required: true,
            },
            groupId: {
                type: Number,
                required: true,
            },
            hasUpdateRights: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            DeleteAssetConfirmation,
            ValidationProvider,
            ValidationObserver
        },
        created() {
            this.uploader = new Uppy({
                autoProceed: true,
                allowMultipleUploads: true,
            });
            this.uploader.use(XHRUpload, {
                endpoint: Routing.generate('ajax_group_upload', { groupId: this.groupId }),
            });
            this.uploader.on('upload-success', (file) => {
                this.counter -= 1;
                this.$toasted.success(`Bestand ${file.meta.name} goed opgeladen`, {
                    position: 'bottom-right',
                    duration: 2000,
                });
                this.fetch();
            });
            this.uploader.on('upload-error', (file) => {
                this.$toasted.error(`Er ging iets fout bij het uploaden van het bestand "${file.name}"`, {
                    position: 'bottom-right',
                    duration: 2000,
                });
                this.counter -= 1;
            });

            this.uploader.on('file-added', (file) => {
                this.counter += 1;
            });
            this.fetch();
        },
        methods: {
            fetch() {
                const payload = {
                    id: this.id,
                    currentPage: this.pagination.current_page_number,
                    itemsPerPage: this.pagination.items_per_page,
                    orderBy: this.sort.by,
                    sortOrder: this.sort.order,
                    search: this.search,
                    fileId: this.searchId,
                };

                if (this.currentFolder) {
                    payload.currentFolder = this.currentFolder;
                }

                if(this.search || this.searchId) {
                    searchFiles(payload).then(this.setSearchResults);
                } else {
                    getFiles(payload).then(this.setContent);
                }
            },
            selectFile() {
                this.$refs.select.click();
            },
            setContent(response) {
                this.renamingId = 0;
                this.breadcrumbs = response.data.breadcrumbs;
                this.files = response.data.items;
                this.pagination.items_per_page = response.data.itemsPerPage;
                this.pagination.current_page_number = response.data.currentPage;
                this.pagination.total_items = response.data.totalItems;
                this.currentFolder = response.data.currentFolderId;
                this.allowUploads = response.data.allowUploads;
                this.isSearching = false;
            },
            setSearchResults({data}) {
                this.files = data.items;
                this.pagination.items_per_page = data.pagination.items_per_page;
                this.pagination.current_page_number = data.pagination.current_page;
                this.pagination.total_items = data.pagination.total_items;
                this.isSearching = true;
            },
            clickRow(item) {
                if (item.id === this.renamingId) {
                    return;
                }

                this.isFolder(item) ? this.changeFolder(item.id) : this.download(item);
            },
            isFolder(item) {
                return item.isFolder.toString() === '1';
            },
            download(file) {
                window.location.href = downloadGroupFile(this.groupId, file.id);
            },
            changeFolder(folderId) {
                this.currentFolder = folderId;
                this.pagination.current_page_number = 1;
                this.updateRoute();
                this.fetch();
            },
            changedPage(newPageNumber) {
                this.pagination.current_page_number = newPageNumber;
                this.updateRoute();
                this.fetch()
            },
            sortFiles(orderBy, direction = false) {
                if(orderBy !== this.sort.by) {
                    this.sort.by = orderBy;
                    this.sort.order = direction ? direction : 'asc';
                } else {
                    if(direction) {
                        this.sort.order = direction;
                    } else {
                        this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc';
                    }
                }

                this.updateRoute();
                this.fetch();
            },
            updateRoute() {
                if(this.$route.name === 'group-files') {
                    this.$router.push({
                        name: 'group-files',
                        params: {
                            group: this.$route.params.group,
                            folder: this.currentFolder,
                        },
                        query: {
                            search: this.search,
                            page: this.pagination.current_page_number,
                            order: `${this.sort.by}|${this.sort.order}`,
                            id: this.searchId,
                        }
                    });
                }
            },
            showIcon(name) {
                if (name === this.sort.by) {
                    return 'asc' === this.sort.order ? 'caret-up' : 'caret-down';
                }

                return 'caret-down';
            },
            startUpload(e) {
                const {target: { files }} = e;
                this.uploader.setMeta({ folderId: this.currentFolder });

                for (let file of files) {
                    this.canUpload(file).then((canUpload) => {
                        if (canUpload) {
                            this.uploader.addFile({
                                name: file.name,
                                type: file.type,
                                data: file,
                            });
                        }
                    });
                }

                this.$refs.select.value = '';
            },
            getUploadUrl() {
                Routing.generate('ajax_group_upload', { groupId: this.groupId, folderId: this.currentFolder })
            },
            classNames(item) {
                const isFolder = this.isFolder(item);
                return {
                    column_name: isFolder ? 'meeting-files__name--folder' : 'meeting-files__name--file',
                    icon: isFolder ? 'folder-icon' : 'file-icon',
                }
            },
            getIcon(item) {
                return this.isFolder(item) ? 'folder' : 'file'
            },
            createFolder() {
                this.creatingFolder = true;
                this.newFolderName = '';

                this.$nextTick(() => {
                    this.$refs.createFolderInput.focus();
                });
            },
            async saveNewFolder() {
                if (!this.creatingFolder) {
                    return;
                }

                const valid = await this.$refs.createFolderValidator.validate();
                this.creatingFolder = false;

                if (!valid || !this.newFolderName) {
                    return;
                }

                createFolder(this.id, this.currentFolder, this.newFolderName).then(() => {
                    this.setFeedback('success', `Folder "${this.newFolderName}" is aangemaakt.`);
                    this.fetch();
                }, (error) => {
                    if (error.response && error.response.data.message) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        this.setFeedback('error', error.response.data.message);
                        return;
                    }

                    // unknown error
                    this.setFeedback('error', `Fout bij het aanmaken van de folder "${this.newFolderName}".`);
                });
            },
            cancelNewFolder() {
                this.creatingFolder = false;
            },
            async createFolderEvents(event) {
                switch (event.keyCode) {
                    case 13: // enter key
                        await this.saveNewFolder();
                        break;
                    case 27: // escape key
                        this.cancelNewFolder();
                        break;
                }
            },
            async renameAssetEvents(event, asset) {
                switch (event.keyCode) {
                    case 13: // enter key
                        await this.rename(asset);
                        break;
                    case 27: // escape key
                        this.renamingId = 0;
                        break;
                }
            },
            startRenaming(event, asset) {
                asset.newName = asset.name;
                this.renamingId = asset.id;
                this.focusId = asset.id;

                const row = $(event.target).closest('.meeting-files__row');
                this.$nextTick(() => {
                    row.find('.rename-file').focus();
                    this.focusId = 0;
                });
            },
            async rename(asset) {
                if (asset.id !== this.renamingId || this.focusId === asset.id) {
                    return;
                }

                const validatorRef = this.$refs['renameValidator' + asset.id][0];
                const valid = await validatorRef.validate();
                this.renamingId = 0;

                if (!valid || asset.newName === asset.name) {
                    return;
                }

                renameAsset(this.groupId, asset.id, asset.newName).then(() => {
                    this.setFeedback('success', `"${asset.name}" is hernoemd naar "${asset.newName}".`);
                    this.fetch();
                }, (error) => {
                    if (error.response && error.response.data.message) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        this.setFeedback('error', error.response.data.message);
                        return;
                    }

                    // unknown error
                    this.setFeedback('error', `Fout bij het hernoemen van "${asset.name}" naar "${asset.newName}".`);
                });
            },
            showDeletedMessage(asset) {
                this.setFeedback('success', `"${asset.name}" is verwijderd.`);
                this.fetch();
            },
            canUpload(file) {
                return new Promise((resolve) => {
                    getFileInfo(this.groupId, this.currentFolder, file.name).then((result) => {
                        const serverFile = result.data;
                        if (!serverFile.name) { // no file
                            return resolve(true);
                        }

                        if (!serverFile.canEdit || this.isFolder(serverFile)) {
                            this.setFeedback('error', `"${file.name}" kon niet geüpload worden, er bestaat al een bestand met deze naam.`);
                            return resolve(false);
                        }

                        const confirmed = confirm(`Wil je "${file.name}" overschrijven?`);
                        return resolve(confirmed);
                    });
                });
            },
            setFeedback(type, message) {
                this.flashType = type;
                this.feedback = message;

                setTimeout(() => {
                    this.feedback = false;
                }, 3000);

                this.fetch();
            },
            editTags(file) {
                this.showTags = true;
                this.selectedFile = file.id;
            },
            updateTags(payload) {
                const currentFile = this.files.find(function (file) {
                    return file.id === payload.fileId.toString();
                });

                currentFile.tags = payload.tags;
                this.setFeedback('success', `De tags werden gewijzigd`)
            },
            copyLink(file) {
                if (typeof file === 'undefined') {
                    file = this.clickedFile;
                }

                if (typeof file === 'undefined') {
                    return;
                }

                if (!this.isFolder(file)) {
                    this.$clipboard(Routing.generate('group_download_asset', { groupId: this.groupId, id: file.id }, true));
                } else {
                    const route = this.$router.resolve({
                        name: 'group-files',
                        params: {
                            group: this.$route.params.group,
                            folder: file.id,
                        },
                    });

                    this.$clipboard(location.origin + route.href);
                }

                this.$clipboard.clipboardCopySuccess = this.clipboardCopySuccess();
            },
            clipboardCopySuccess() {
                this.setFeedback('success', `De link werd naar het klembord gekopieerd`);
            },
            rightMenuClick(file) {
                this.clickedFile = file;
                this.$refs.ctxMenu.open();
            },
            downloadBulk() {
                window.open(downloadBulkGroupFiles(this.groupId, this.currentFolder));
            },
        },
        computed: {
            currentUser() {
                return window.user;
            },
            canCreateAssets() {
                return this.allowUploads;
            },
            canCreateFolders() {
                return this.allowUploads && window.user.isModerator;
            },
            hasFiles() {
                return this.files.filter(item => { return parseInt(item.isFolder) === 0 }).length > 0;
            },
        },
        watch: {
            '$route.params.folder': function(folder){
                if(parseInt(folder, 10) !== parseInt(this.currentFolder, 10)) {
                    this.changeFolder(parseInt(folder, 10));
                }
            },
            '$route.query.page': function(page){
                if(this.pagination.current_page_number !== (parseInt(page, 10))) {
                    this.changedPage(parseInt(page, 10))
                }
            },
            '$route.query.order': function(order) {
                let [by, direction] = order.split('|');

                if(this.sort.order !== direction || this.sort.by !== by) {
                    this.sortFiles(by, direction);
                }
            },
            '$route.query.search': function(search){
                if(this.search !== search) {
                    this.search = search;
                }
            },
            '$route.query.id': function(id){
                if(parseInt(this.searchId, 10) !== parseInt(id, 10)) {
                    this.searchId = parseInt(id, 10);
                }
            },
            'search': debounce(function() {
                if(this.search || !(parseInt(this.searchId, 10))) {
                    this.searchId = 0;
                    this.changedPage(1);
                }
            }, 500),
            'searchId': function() {
                if(this.searchId !== 0) {
                    this.changedPage(1);
                }
            },
        }
    }
</script>
