var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search__block" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { class: _vm.leftColumnSize }, [
        _c(
          "a",
          {
            class: "search__label search__" + _vm.data.type,
            attrs: { href: _vm.data.route },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.click($event)
              },
            },
          },
          [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.data.label) +
                " - " +
                _vm._s(_vm.formatDate(_vm.data.date_for_notifications)) +
                "\n                "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _vm.data.route
            ? _c(
                "a",
                {
                  staticClass: "search-link",
                  attrs: { href: _vm.data.route },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.click($event)
                    },
                  },
                },
                [
                  _c("h2", [
                    _vm.data.hasSubscriptions
                      ? _c(
                          "span",
                          { staticClass: "subscription__label" },
                          [_c("icon", { attrs: { icon: "plus-square" } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.data.title) },
                    }),
                  ]),
                ]
              )
            : _c("h2", [
                _vm.data.hasSubscriptions
                  ? _c(
                      "span",
                      { staticClass: "subscription__label" },
                      [_c("icon", { attrs: { icon: "plus-square" } })],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { domProps: { innerHTML: _vm._s(_vm.data.title) } }),
              ]),
        ]),
        _vm._v(" "),
        _vm.data.tags.length > 0
          ? _c(
              "p",
              { staticClass: "pb-0" },
              _vm._l(_vm.data.tags, function (tag) {
                return _c("span", { staticClass: "badge badge-dark mr-2" }, [
                  _vm._v(_vm._s(tag.name)),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.data.description) } }),
        _vm._v(" "),
        _vm.data.fileLocation !== null &&
        _vm.data.groupName !== null &&
        _vm.data.groupSlug !== null
          ? _c("p", { staticClass: "p-0 search__group-link" }, [
              _c("a", { attrs: { href: _vm.data.groupSlug } }, [
                _vm._v(
                  'Bestand uit groep "' + _vm._s(_vm.data.groupName) + '"'
                ),
              ]),
              _c("br"),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.data.route } }, [
                _vm._v(
                  'Locatie van het bestand: "' +
                    _vm._s(_vm.data.fileLocation) +
                    '"'
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.knowledgeDomains
          ? _c("cite", [
              _vm._v(_vm._s(_vm.printDomains(_vm.data.knowledgeDomains))),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.data.image || _vm.data.provider
        ? _c("div", { class: _vm.rightColumnSize }, [
            _vm.showProvider
              ? _c("figure", { staticClass: "d-inline-block w-100" }, [
                  _c("img", {
                    staticClass: "provider-image",
                    attrs: {
                      src: _vm.data.provider.logoLink,
                      alt: _vm.data.provider.name,
                      itemprop: "image",
                      itemtype: "https://schema.org/ImageObject",
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.image
              ? _c("figure", { staticClass: "d-inline-block w-100" }, [
                  _c("img", {
                    attrs: { src: _vm.data.image, alt: "author image" },
                  }),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }