<template>
    <div class="search container search-results">
        <div class="row">
            <div class="col-12">
                <div class="filter-icon" @click="toggleFilters">
                    <figure>
                        <img src="build/images/menu_bars.svg" alt="filter icon">
                    </figure>
                    <span>Filters</span>
                </div>
            </div>
        </div>
        <div class="row" v-cloak>
            <div class="col-lg-3 col-md-4">
                <div class="search__sidebar">
                    <div class="search__title">
                        <strong v-if="searchData.pagination.total_items > 1">{{ searchData.pagination.total_items }} resultaten</strong>
                        <strong v-if="searchData.pagination.total_items === 1">{{ searchData.pagination.total_items }} resultaat</strong>
                        <strong v-if="searchData.pagination.total_items === 0">Geen resultaten</strong>
                    </div>

                    <ul :class="{ open: visibleFilters }">
                        <checkbox-filter
                                v-for="(category, index) in searchData.categories"
                                v-model="category.active"
                                @change="newSearch"
                                :key=index
                        >
                            <img :src="'build/images/' + category.icon" alt="filter-icon" /> {{ category.name }}
                            <span>({{ category.doc_count }})</span>
                        </checkbox-filter>
                    </ul>
                </div>
            </div>

            <div class="col-lg-9 col-md-8">
                <div class="search__content">
                    <div class="row">
                        <div class="col-lg-6">
                            <a v-if="!visibleSave && user" @click="visibleSave = true">
                                <img src="build/images/email_downlaod.png" alt="icon" /><span>Blijf op de hoogte van nieuwe resultaten</span>
                            </a>
                            <form v-if="visibleSave" v-on:submit.prevent>
                                <input type="text" v-model="searchName" placeholder="Naam van zoekresultaat">
                                <button class="button button--xs" :disabled="btnDisable" @click="saveSearch">Opslaan</button>
                            </form>
                        </div>

                        <div class="col-lg-6 text-center">
                            <div class="search__sort">
                                <span>Sorteren op: </span>
                                <ul class="search__pills">
                                    <li
                                        :class="{ 'is-active': searchSort === 'relevance' }"
                                        @click="searchSort = 'relevance'">
                                        <a>relevantie</a>
                                    </li>
                                    <li
                                        :class="{ 'is-active': searchSort === 'recent' }"
                                        @click="searchSort = 'recent'">
                                        <a>recent</a>
                                    </li>
                                    <li
                                        :class="{ 'is-active': searchSort === 'old' }"
                                        @click="searchSort = 'old'">
                                        <a>oude</a>
                                    </li>
                                </ul>
                            </div>

                            <div v-show="!advancedSearch && !forceAdvancedSearch" class="text-right mt-2">
                                <a href="#" @click.prevent="advancedSearch = !advancedSearch" style="display:inline-block;">Geavanceerd zoeken</a>
                            </div>
                            <div v-show="advancedSearch || forceAdvancedSearch">
                                <div class="search__sort mt-2">
                                    <span>Beleidsdomeinen: </span>
                                    <span>
                                    <v-select style="min-width: 250px;" multiple v-model="selectedDomains" :options="searchData.domains" label="text"></v-select>
                                </span>
                                </div>

                                <div class="search__sort mt-2">
                                    <span>Startdatum: </span>
                                    <span style="width:250px;">
                                        <input ref="startdatefilter" v-model="selectedFromDate" />
                                    </span>
                                </div>

                                <div class="search__sort mt-2">
                                    <span>Einddatum: </span>
                                    <span style="width:250px;">
                                        <input ref="enddatefilter" v-model="selectedToDate" />
                                    </span>
                                </div>

                                <div class="search__sort mt-2">
                                    <span class="mb-1">Datum van: </span>
                                    <span style="width:250px;">
                                        <input type="radio" class="mr-1 mb-1" id="publicationMode" name="dateMode" value="publication" v-model="selectedDateMode">
                                        <label for="publicationMode" class="mr-1">Publicatie</label>
                                        <input type="radio" class="mr-1 mb-1" id="revisionMode" name="dateMode" value="revision" v-model="selectedDateMode">
                                        <label for="revisionMode">Revisie</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-wrapper">
                            <form v-on:submit.prevent>
                                <input placeholder="Zoeken..." v-model="searchQuery" id="search" type="search" autocomplete="off">
                            </form>
                        </div>
                    </div>
                </div>

                <card-search v-if="searchData.items.length" v-for="(item, key) in searchData.items"
                            :key="key"
                            :data="item.data"
                            @navigate="navigate"
                ></card-search>

                <div class="search__box">
                    <div class="search__block" v-if="noResultsFound">
                        <span>Geen resultaten gevonden</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination" v-if="!noResultsFound">
            <ul class="pagination b-pagination pagination-md">
                <li role="none presentation" aria-hidden="true" class="page-item"
                    :class="firstPageClasses">
                    <a href="#" class="page-link" @click="changedPage(1)">
                        <span aria-hidden="true">«</span>
                    </a>
                </li>
            </ul>

            <b-pagination
                    :total-rows="searchData.pagination.total_items"
                    :per-page="searchData.pagination.items_per_page"
                    :hide-ellipsis=true
                    v-model="searchData.pagination.current_page"
                    @input="changedPage"
                    :hide-goto-end-buttons="true"
            ></b-pagination>
        </div>

        <flashmessage
            :type="flashType"
            :message="feedback"
            v-if="feedback"
        ></flashmessage>

        <modal-redirect
                v-show="showModalRedirect"
                :url="modalRedirectLink"
                :tab="modalTab"
                @close="showModalRedirect = false"
                :remoteContentWarning="remoteContentWarning"
        ></modal-redirect>

    </div>
</template>

<script>
    // @flow
    import { saveSearch, search } from './api';
    import { debounce } from 'lodash';

    import CheckboxFilter from './checkbox-filter.vue';
    import cardSearch from './card-search.vue';

    export default {
        name: 'search',
        components: {
            'checkbox-filter': CheckboxFilter,
            cardSearch,
        },
        props: {
            user: {
                type: Object,
                required: false,
            },
            query: {
                type: String,
                default: '',
            },
            sort: {
                type: String,
                default: 'relevance',
            },
            selectedFilters: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            initialDomains: {
                type: Array,
                default: () => {
                    return []
                },
            },
            initialStartDate: {
                type: String,
                default: '',
            },
            initialEndDate: {
                type: String,
                default: '',
            },
            initialDateMode: {
                type: String,
            },
            remoteContentWarning: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            booted: false,
            btnDisable: false,
            feedback: '',
            error: null,
            advancedSearch: false,
            searchName: '',
            searchQuery: '',
            searchSort: 'relevance',
            selectedDomains: [],
            selectedFromDate: null,
            selectedToDate: null,
            selectedDateMode: 'publication',
            selectedDateModes: [
                'publication',
                'revision'
            ],
            searchData: {
                domains: [],
                categories: [],
                items: [],
                pagination: {
                    total_items: 0,
                    items_per_page: 10,
                    current_page: 1,
                },
            },
            visibleSave: false,
            visibleFilters: false,
            flashType: '',
            saving: false,
            feedbackLoop: null,
            showModalRedirect: false,
            modalRedirectLink: '',
            modalTab: '',
            domains: null,
        }),
        mounted() {
            this.initDate(this.$refs.startdatefilter, this.selectedFromDate);
            this.initDate(this.$refs.enddatefilter, this.selectedToDate);
        },
        created() {
            if (this.shouldRebuildFromHistory()) {
                const {filters, page, query, sort, domains = [], startDate = null, endDate = null, dateMode = 'publication'} = window.history.state;

                this.searchData.categories = filters // eslint-disable-line no-undef
                    .map(category => ({
                        key: category,
                        active: true,
                    }));
                this.searchData.pagination.current_page = page;
                this.searchSort = sort;
                this.searchQuery = query;
                this.domains = domains;
                this.selectedFromDate = startDate;
                this.selectedToDate = endDate;
                this.selectedDateMode = dateMode;
            }
            else {
                if (this.selectedFilters.length) { // eslint-disable-line no-undef
                    this.searchData.categories = this.selectedFilters // eslint-disable-line no-undef
                        .map(category => ({
                            key: category,
                            active: true,
                        }));
                }
                this.searchQuery = this.query;
                this.searchSort = this.sort !== '' ? this.sort : 'relevance';
                this.selectedFromDate = this.initialStartDate;
                this.selectedToDate = this.initialEndDate;
                this.selectedDateMode = this.initialDateMode;
                this.domains = this.initialDomains;
            }

            if(this.forceAdvancedSearch) {
                this.advancedSearch = true;
            }

            this.search()
        },
        computed: {
            forceAdvancedSearch() {
                return this.selectedFromDate || this.selectedDomains.length;
            },
            firstPageClasses() {
                return {
                    disabled: this.searchData.pagination.current_page == 1
                }
            },
            noResultsFound() {
                return this.searchData.items.length === 0 && this.searchQuery !== false;
            },
            filters() {
                const activeCategories = [];
                this.searchData.categories.forEach((category) => {
                    if (category.active) {
                        activeCategories.push(category.key);
                    }
                });

                return activeCategories;
            },
        },
        methods: {
            startFeedback(feedback, type) {
                if (this.feedbackLoop) {
                    clearTimeout(this.feedbackLoop);
                }
                this.feedback = feedback;
                this.flashType = type;

                this.feedbackLoop = setTimeout(() => {
                    this.feedback = '';
                    this.flashType = '';
                }, 3000)
            },
            newSearch: debounce(function() {

                this.setQueryParams();

              this.searchData.pagination.current_page = 1;
                // don't scroll when typing in the query box or changing filters
                // if people are typing or changing filtering,
                // we assume they can see whatever is relevant at that point
                // and scrolling in that case doesn't feel right.
                this.search(false);
            }, 500),
            toggleFilters () {
                this.visibleFilters = !this.visibleFilters;
            },
            setData(data) {
                // always set items and pagination info
                this.searchData.pagination = data.pagination;
                this.searchData.items = data.items;

                // only set the full categories payload on initial boot
                // when not initial boot, only update the count to avoid
                // inconsistencies when filtering through categories
                // ex: when clicking filter 1 and then filter 2
                // while first query is still being processed, you could lose
                // state of your second click (depending on the timing of the second click)
                // make sure to also always load the categories based off the data returned from server
                // when the search has not been set as booted yet. If not when running a saved search,
                // you'll end up with a checkbox without a label and an icon.
                if (this.searchData.categories.length === 0 || ! this.booted) {
                    this.searchData.categories = data.categories;
                } else {
                    this.searchData.categories = data.categories.reduce((result, needle) => {
                        let existingCategory = this.searchData.categories.find((category) => {
                            return needle.key === category.key;
                        });

                        if (existingCategory) {
                            existingCategory.doc_count = needle.doc_count;
                        } else {
                            existingCategory = needle;
                        }

                        result.push(existingCategory);

                        return result;
                    }, []);
                }

                this.searchData.domains = data.domains;

                if (!this.booted) {
                    this.setDomainFilters();
                }

                // whenever we executed a search make sure to remember the last search
                // so if the user presses the back button, we can reload their last search
                // or when they restore a window or do a simple page reload.
                window.history.replaceState({
                    query: this.searchQuery,
                    filters: this.filters,
                    sort: this.searchSort,
                    page: this.searchData.pagination.current_page,
                    domains: this.selectedDomains,
                    startDate: this.selectedFromDate,
                    endDate: this.selectedToDate,
                    dateMode: this.selectedDateMode,
                }, document.querySelector('title').textContent);

                this.booted = true;
            },

            changedPage (page = false) {
                if (page) {
                    this.searchData.pagination.current_page = page;
                }

                this.search(true);
            },

            search(scroll = true) {
                search({
                    query: this.searchQuery,
                    filters: this.filters,
                    sort: this.searchSort,
                    page: this.searchData.pagination.current_page,
                    domains: this.selectedDomains.map(domain => domain.id),
                    startDate: this.selectedFromDate,
                    endDate: this.selectedToDate,
                    dateMode: this.selectedDateMode,
                })
                    .then((response) => {
                        this.error = null;
                        this.setData(response.data);
                        if (scroll) {
                            this.scrollTop();
                        }
                    })
                    .catch((error) => {
                        this.error = error;
                    });
            },
            saveSearch() {
                if (!this.saving) {
                    this.saving = true;

                    if (this.searchName === '') {
                        this.startFeedback('Geef een naam aan uw zoekresultaat.', 'error');
                        this.saving = false;
                    } else {
                        saveSearch({
                            query: this.searchQuery,
                            filters: this.filters,
                            sort: this.searchSort,
                            searchName: this.searchName,
                            domains: this.selectedDomains.map(domain => domain.id),
                            startDate: this.selectedFromDate,
                            endDate: this.selectedToDate,
                            dateMode: this.selectedDateMode,
                        }).then((response) => {
                            this.error = null;
                            this.searchName = '';
                            this.startFeedback(response.data.message, 'success');
                            this.btnDisable = false;
                            this.visibleSave = false;
                            this.saving = false;
                        }).catch((error) => {
                            this.btnDisable = false;
                            this.startFeedback(error.message, 'error');
                            this.saving = false;
                        });
                    }
                }
            },
            scrollTop() {
                const wrapper = document.getElementsByClassName('search-results')[0];
                window.scrollTo({
                    top: wrapper.scrollTop,
                    left: 0,
                });
            },

            shouldRebuildFromHistory() {
                if(!window.history.state){
                    return false;
                }

                const { filters, page, query, sort } = window.history.state;

                return filters || page || query || sort;
            },
            navigate(item, newTab) {
                let tab = (newTab) ? '_blank' : '_self';

                // Show a modal to remind the user he will be redirected to an external website
                if (item.showRemoteContent && this.user && this.user.hasDisabledRemoteContentWarning !== true) {
                    this.showModalRedirect = true;
                    this.modalRedirectLink = item.route + '?query=' + this.searchQuery + '&redirect=true';
                    this.modalTab = tab;
                } else {
                  window.open(item.route + '?query=' + this.searchQuery, tab);
                }
            },
            initDate(element, value, minDate, maxDate) {
                flatpickr(element, {
                    mode: 'single',
                    altInput: true,
                    altFormat: "d/m/Y",
                    defaultDate: value,
                    minDate: minDate,
                    maxDate: maxDate,
                });
            },
            setQueryParams() {
                let path = window.location.pathname;
                let filterParams = '';

                filterParams += 'query=' + this.searchQuery

                this.filters.forEach(function(filter) {
                    filterParams += `&filters[]=${filter}`
                });

                this.selectedDomains.forEach(function(domain) {
                    filterParams += `&domains[]=${domain.id}`
                });

                if (this.selectedFromDate) {
                    filterParams += '&startDate=' + this.selectedFromDate;
                }

                if (this.selectedToDate) {
                    filterParams += '&endDate=' + this.selectedToDate;
                }

                filterParams += '&dateMode=' + this.selectedDateMode;
                filterParams += '&sort=' + this.searchSort;

                window.history.replaceState('', '', `${path}?${filterParams}`);
            },
            setDomainFilters() {
                this.selectedDomains = this.searchData.domains.filter(domain => {
                    return this.domains.indexOf(domain.id.toString()) >= 0;
                });
            },
        },

        // only trigger these watchers when already booted, or you'l spawn a huge amount of queries
        // when there was a history.state that had to be loaded
        watch: {
            searchQuery() {
                if (this.booted) {
                    this.newSearch();
                }
            },
            searchSort() {
                this.setQueryParams();
                if (this.booted) {
                    this.search(false);
                }
            },

            selectedDomains(){
                if (this.booted) {
                    this.newSearch();
                }
            },

            selectedDateMode() {
                if (this.booted) {
                    this.newSearch();
                }
            },

            selectedDateRange() {
                if(this.booted) {
                    this.newSearch();
                }
            },

            selectedFromDate() {
                if (this.booted) {
                    this.initDate(this.$refs.enddatefilter, this.selectedToDate, this.selectedFromDate);
                    this.newSearch();
                }
            },

            selectedToDate() {
                if (this.booted) {
                    this.initDate(this.$refs.startdatefilter, this.selectedFromDate, null, this.selectedToDate);
                    this.newSearch();
                }
            }
        },
    };


</script>
