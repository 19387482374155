<template>
    <div class="group__tagList">
        <flashmessage
                :type="'error'"
                :message="feedback"
                v-if="feedback"
        ></flashmessage>
        <div class="row">
            <div class="col-12 col-md-6 mb-3 mt-3">
            <strong>Alle tags</strong>
            <p>Klik op een tag om hem aan te passen</p>

            <div class="taglist">
                <group-tag
                        v-for="(tag, key) in allGroupTags"
                        :tag="tag"
                        :key="key"
                        :is-editable="true"
                        :edit-permission="'true'"
                        @deleteTag="startDelete"
                        @updateTag="update"
                ></group-tag>
            </div>
        </div>
            <div class="col-12 col-md-6">
                <strong>Tag toevoegen</strong>
                <div class="d-flex justify-content-start flex-nowrap mb-2">
                    <input type="text" v-model="newTagName" class="form-control group__tag-list__input" placeholder="Vul de naam van de nieuwe tag in" aria-label="Tagname" aria-describedby="basic-addon2" v-on:keyup.enter="createNewTag">
                    <button class="button button--xs group__tag-list__button" type="button" @click="createNewTag">Toevoegen</button>
                </div>
            </div>
        </div>
        <modal v-if="showDelete">
            <p>Weet u zeker dat u de tag "{{ currentTag.name }}" <strong>permanent wilt verwijderen</strong>? Deze zal van alle bestanden in de groep verwijderd worden.</p>
            <a @click.prevent="destroy(currentTag)" class="btn btn-xs btn-danger text-white mr-1">Verwijder deze tag</a>
            <a @click.prevent="showDelete = false" class="button button--xs">Annuleren</a>
        </modal>
    </div>
</template>

<script>
    import { getGroupTags, createNewTag, deleteTag, updateTag } from "./api";
    import GroupTag from "./group-tag";

    export default {
        name: 'GroupTags',
        components: { GroupTag },
        props: {
            id: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                allGroupTags: [],
                newTagName: '',
                feedback: false,
                currentTag: undefined,
                showDelete: false,
            }
        },
        created() {
            this.fetch();
        },
        methods: {
            resetFeedback() {
                setTimeout(() => {
                    this.feedback = false;
                }, 3000);
            },
            fetch() {
                getGroupTags(this.id).then(({ data }) => {
                    this.allGroupTags = data.groupTags;
                });
            },
            startDelete(tag) {
                this.currentTag = tag;
                this.showDelete = true;
            },
            destroy(tag) {
                deleteTag({
                    groupId: this.id,
                    tagId: tag.id
                }).then(() => {
                    this.allGroupTags = this.allGroupTags.filter(function (currentTag) {
                        return currentTag.id !== tag.id;
                    })
                }).catch(() => {
                    this.feedback = "Kan tag niet verwijderen. Deze is mogelijks al verwijderd";
                });
                this.resetFeedback();
                this.showDelete = false;
            },
            update(tag) {
                if (tag.name === '') {
                    this.feedback = "De nieuwe tag naam mag niet leeg zijn.";
                    this.resetFeedback();
                    return;
                }

                updateTag({
                    groupId: this.id,
                    tagId: tag.id,
                    tagName: tag.name
                }).then(({ data }) => {
                    const index = this.allGroupTags.map(t => t.id).indexOf(tag.id);
                    this.$set(this.allGroupTags, index, data.groupTag);
                }).catch(() => {
                    this.feedback = "Tag kan niet worden aangepast.";
                });
                this.resetFeedback();
            },
            createNewTag() {
                if (this.newTagName === '') {
                    this.feedback = "De tag naam mag niet leeg zijn.";
                    this.resetFeedback();
                    return;
                }

                createNewTag({
                    tagName: this.newTagName,
                    groupId: this.id
                }).then(({ data }) => {
                    this.allGroupTags.push(data.groupTag);
                    this.newTagName = '';
                }).catch(() => {
                    this.feedback = "Tag kan niet worden aangemaakt. Mogelijks bestaat er al een tag met dezelfde ID";
                });
                this.resetFeedback();
            }
        }
    }
</script>
