import { PaginationPlugin } from 'bootstrap-vue';
import VueSelect from 'vue-select';
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';
import Nl2br from 'vue-nl2br';
import Clipboard from 'v-clipboard';
import flatpickr from 'flatpickr';
import contextMenu from 'vue-context-menu';
import * as VueGoogleMaps from 'vue2-google-maps';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Vue from 'vue';
import Event from './event';
import Icons from './icons';
import Ui from './ui';
import Inbox from './inbox';
import Search from './search';
import Datatable from './datatable';
import Groups from './groups';
import ClimateProject from './climate-project';

const dutch = require('flatpickr/dist/l10n/nl').default.nl;

flatpickr.localize(dutch);

Vue.use(VueRouter);
Vue.use(Toasted);

const routes = [];

Vue.use(PaginationPlugin);
Vue.use(Icons);
Vue.use(Event);
Vue.use(Ui);
Vue.use(Inbox, { routes });
Vue.use(Search, { routes });
Vue.use(Datatable);
Vue.use(Groups, { routes });
Vue.use(ClimateProject);
Vue.use(Clipboard);
Vue.component('nl2br', Nl2br);
Vue.component('VSelect', VueSelect);
Vue.component('contextMenu', contextMenu);
Vue.use(CKEditor);
// Vue.use(WordCount);
Vue.use(VueGoogleMaps, {
    installComponents: true,
    load: {
        key: window.GOOGLE_MAPS_API_KEY,
        libraries: 'places',
    },
});

const router = new VueRouter({
    mode: 'history',
    routes,
});

new Vue({ // eslint-disable-line no-new
    router,
    Clipboard,
    CKEditor,
    contextMenu,
}).$mount('#vue-app');

// Needs to be loaded here otherwise it's "kapotskie"
$('#profile_edit_knowledgeDomains').select2();
$('#climate_project_management_city').select2();
$('#climate_project_management_initiatorCity').select2();
$('#climate_project_management_initiatorIntermunicipal').select2();
$('#climate_project_management_initiatorProvince').select2();
$('#profile_edit_interests').select2();
$('#settings_newsletterInterests').select2();
$('#user_invitation_subscriptions').val([]).select2();

$('select').on('select2:close', (e) => {
    const select2SearchField = $(e.currentTarget).parent().find('.select2-search__field');
    setTimeout(() => {
        select2SearchField.focus();
    }, 100);
});
