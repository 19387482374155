<template>
    <div>
        <div class="row no-gutters mb-3">
            <file-management
                    :id="id"
                    :group-id="id"
                    :has-update-rights="hasUpdateRights"
            ></file-management>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GroupFiles',
        props: {
            id: {
                type: Number,
                required: true,
            },
            hasUpdateRights: {
                type: Boolean,
                required: true,
            },
            userId: {
                type: Number,
                required: true,
            }
        },
    }
</script>
