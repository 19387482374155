var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", [
    _c("div", [
      _c("div", [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.remoteContentWarning) } }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "checkbox", id: "dontShowAgain" },
          domProps: { checked: _vm.selected },
          on: {
            change: function ($event) {
              _vm.selected = !_vm.selected
            },
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: _vm.id } }, [
          _vm._v("Deze melding niet meer weergeven"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("button", { staticClass: "btn btn-xs", on: { click: _vm.cancel } }, [
          _vm._v("Op deze pagina blijven"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-xs btn-default", on: { click: _vm.confirm } },
          [_vm._v("Verder")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }