var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.feedback
        ? _c("flashmessage", {
            attrs: { type: "success", message: _vm.feedback },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModal
        ? _c("modal", [
            _c("div", { staticClass: "form-group" }, [
              _c("p", { staticClass: "control-label" }, [
                _vm._v(
                  "Weet u zeker dat u deze gebruiker uit de groep wilt verwijderen?"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-xs btn-default mr-2 mt-2",
                  on: { click: _vm.confirm },
                },
                [_vm._v("Ja, ik ben zeker")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-xs button--neutral mt-2",
                  on: { click: _vm.cancel },
                },
                [_vm._v("Annuleren")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row group__memberlist mt-4" },
        _vm._l(_vm.members, function (member) {
          return _c("div", { staticClass: "col-12 col-md-4 col-xl-3" }, [
            _c(
              "div",
              { staticClass: "member__profile-link testimonial__block" },
              [
                _vm.isModerator
                  ? _c(
                      "div",
                      {
                        staticClass: "member__profile-icon--remove",
                        on: {
                          click: function ($event) {
                            return _vm.remove(member)
                          },
                        },
                      },
                      [_c("icon", { attrs: { icon: "times-circle" } })],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex d-md-block flex-row align-items-center member__profile-data p-2 py-md-0",
                    on: {
                      click: function ($event) {
                        return _vm.show(member)
                      },
                    },
                  },
                  [
                    _c(
                      "figure",
                      { staticClass: "img-circle--memberlist mt-4" },
                      [
                        _c("img", {
                          attrs: {
                            src: member.avatar_url,
                            alt: _vm.getName(member),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("aside", [
                      _c("strong", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.getName(member)) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(member.function_name))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(member.knowledge_domain))]),
                    ]),
                  ]
                ),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          current_page_number: _vm.searchData.current_page_number,
          total_items: _vm.searchData.total_items,
          items_per_page: _vm.searchData.items_per_page,
        },
        on: { changedPage: _vm.changedPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }