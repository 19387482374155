var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.errored
      ? _c("div", [
          _c("span", { staticClass: "error-line" }, [
            _vm._v("Een bestand kon niet worden geüploaded"),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "file-upload-list" }, [
      _vm.files.length > 0
        ? _c("div", { staticClass: "file-upload-list__label-container" }, [
            _c(
              "span",
              { on: { click: _vm.clearAll } },
              [
                _c("icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "trash-alt" },
                }),
                _vm._v(
                  "\n                    Verwijder alles\n                "
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "file-upload-list__file-container" },
        [
          _vm._l(_vm.files, function (file, index) {
            return [
              _c("div", { staticClass: "file-upload-list__file" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn-remove",
                    on: {
                      click: function ($event) {
                        return _vm.clear(file)
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "icon",
                      attrs: { icon: "trash-alt" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "file-upload-list__file-preview" },
                  [
                    _vm.fileType(file) === "image"
                      ? _c("img", {
                          attrs: { src: file.path, alt: "preview image" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fileType(file) === "video"
                      ? _c("icon", {
                          staticClass: "file-upload__upload-icon",
                          attrs: { icon: "file-video" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fileType(file) === "file"
                      ? _c("icon", {
                          staticClass: "file-upload__upload-icon",
                          attrs: { icon: "file-alt" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "file-upload-list__file-label" }, [
                  _c("span", [_vm._v(_vm._s(file.filename))]),
                ]),
              ]),
            ]
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "file-upload-list__file" },
            [
              _c("icon", {
                staticClass: "file-upload__upload-icon",
                attrs: { icon: "plus-square" },
              }),
              _vm._v(" "),
              _c("input", {
                ref: "inputField",
                staticClass: "file-upload__upload-item",
                attrs: {
                  type: "file",
                  name: _vm.name,
                  multiple: "multiple",
                  accept:
                    ".mp3, .ogg, .wav, .7z, .rar, .tar, .zip, .jpeg, .jpg, .png, .psd, .svg, .ai, .ppt, .pptx, .xls, .xlsx, .doc, .docx, .pdf, .txt, .rtf, .gif",
                },
                on: { change: _vm.change },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }