<template>
    <div class="message-to">
        <label>Ontvanger(s) *:</label>
        <div v-if="!value.length" class="message-to-empty">Nog geen ontvangers geselecteerd.</div>
        <div v-else class="message-to-selected">
            <span v-for="(item) in value" @click="remove(item)">
                <i v-html="item.title"></i>
                <icon icon="times"></icon>
            </span>
        </div>

        <search-users @input="submit" :placeholder="placeholder" :disabled="disabled"></search-users>
    </div>
</template>

<script>
    export default {
        name: 'UserSelect',

        computed: {
            placeholder() {
                return this.disabled ? 'Limiet van ontvangers bereikt' : 'Begin met typen om ontvangers toe te voegen';
            }
        },
        props: {
            value: {
                required: true,
                type: Array
            },
            disabled: {
                type: Boolean
            }
        },

        methods: {
            remove(item) {
                this.$emit('input', this.value.filter(entry => entry.id !== item.id));
            },

            submit(item) {
                if (item) {
                    this.value.push(item);
                    this.$emit('input', this.value);
                }
            }
        }
    }
</script>
