<template>
    <div class="row">
        <flashmessage
            :type="flashType"
            :message="feedback"
            v-if="feedback"
        ></flashmessage>
        <div class="col-12 col-lg-3">
            <climate-project-filters
                :value="filters"
                @input="filterChange"
            ></climate-project-filters>
        </div>

        <div class="col-12 col-lg-9">
            <div class="w-100 text-center font-weight-bold mb-5">
                <!--The div element for the map -->
                <climate-project-map
                    :climate-projects="climateProjects"
                    :selected-climate-project="selectedClimateProject"
                    @select="selectedClimateProject = $event"
                ></climate-project-map>
            </div>

            <div>
              <p class="text-right">
                <template v-if="climateProjects.length === 1">{{ climateProjects.length }} project gevonden</template>
                <template v-else>{{ climateProjects.length }} projecten gevonden</template>
              </p>
                <div v-for="(climateProject, index) in climateProjects"
                     v-if="!loading"
                     :ref="index"
                     class="mb-4"
                >
                    <climate-project-card
                        :climate-project="climateProject"
                        :edit="false"
                        :is-selected="climateProject.id === selectedClimateProject.id"
                        @click="cardClick"
                    ></climate-project-card>
                </div>
                <div v-if="loading" class="d-flex justify-content-center">
                    <spinner></spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { search } from './api';

export default {
    props: {
        filters: {
            required: true,
            type: Object,
        }
    },

    data() {
        return {
            loading: false,
            feedback: false,
            flashType: 'error',
            climateProjects: [],
            selectedClimateProject: {},
        };
    },

    created() {
        this.load({});
    },

    methods: {
        load(filters) {
            this.loading = true;
            search(filters).then(({ data }) => {
                this.climateProjects = data;
                this.loading = false;
            }).catch(err => {
                this.setFeedback('error', `Er ging iets mis bij het ophalen van de klimaatprojecten`);
                this.loading = false;
            });
        },
        cardClick(climateProject) {
            this.selectedClimateProject = climateProject;
        },
        filterChange(filters) {
            this.filters = filters;
            this.load(filters);
        },
        setFeedback(type, message) {
            this.flashType = type;
            this.feedback = message;

            setTimeout(() => {
                this.feedback = false;
            }, 3000);
        },
    }
}
</script>
